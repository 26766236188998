import React from 'react';
import { Typography } from '@material-ui/core';
import useFetch from '../../hooks/useFetch';
import routes from '../../api/routes';
import { Card } from '../Cards';
import { AssociationFiltersDto } from '../../dto/association';
import { AssociationStats, calculateStats } from '../../dto/associationUtils';
import { formatNumber } from '../../utils';

const Dashboard1 = ({
	filters,
	hideDetails = false,
}: {
	filters: AssociationFiltersDto;
	hideDetails?: boolean;
}) => {
	const [, dayPartialDashboardStats] = useFetch<AssociationStats>(
		routes.associations.dashboardStats({}, filters),
		[JSON.stringify(filters)],
	);
	let dashboardStats = calculateStats((dayPartialDashboardStats || {}) as any);
	const stopPA =
		dashboardStats.completedDonationsCount === 0
			? 0
			: (100 * dashboardStats.donationsTotalStopPA) /
			  (dashboardStats.completedDonationsCount + dashboardStats.donationsTotalStopPA);

	return (
		<>
			<Card title="heures rue">
				<Typography style={{ color: '#3f51b5', fontSize: 20 }}>
					{formatNumber(dashboardStats.streetHours, 2)}h
				</Typography>
			</Card>
			{!hideDetails && <Card title="bs rue">{formatNumber(dashboardStats.donationsTotalCount, 2)}</Card>}
			<Card title="bs réel">
				<Typography style={{ color: '#61BE7E', fontSize: 20 }}>
					{formatNumber(dashboardStats.completedDonationsCount, 2)}
				</Typography>
			</Card>

			{!hideDetails && <Card title="taux rue">{formatNumber(dashboardStats.streetRate, 2)}</Card>}
			<Card title="taux réel">
				<Typography style={{ color: '#9ACD33', fontSize: 20 }}>
					{formatNumber(dashboardStats.realRate, 2)}
				</Typography>
			</Card>
			<Card title="Taux transformation">{formatNumber(dashboardStats.transformationRate, 2)} %</Card>
			<Card title="Stop PA">
				<Typography style={{ color: '#C05757', fontSize: 20 }}>{formatNumber(stopPA, 2)} %</Typography>
			</Card>

			{!hideDetails && (
				<Card title="montants collectés">{formatNumber(dashboardStats.donationsTotalAmout, 2)} €</Card>
			)}
			<Card title="don moyen">
				<Typography style={{ color: '#ba5ae2', fontSize: 20 }}>
					{formatNumber(dashboardStats.averageDonation, 2)} €
				</Typography>
			</Card>

			<Card title="âge moyen">
				<Typography style={{ color: '#EE814F', fontSize: 20 }}>
					{formatNumber(dashboardStats.averageAge, 2)} ans
				</Typography>
			</Card>

			<Card title="taux 18/30">{dashboardStats._18_30_percent} %</Card>

			{!hideDetails && (
				<>
					<Card title="% homme">{dashboardStats.malePercent} %</Card>
					<Card title="% femme">{dashboardStats.femalePercent} %</Card>
					<Card title="% autre">{dashboardStats.otherGenderCount} %</Card>
				</>
			)}
		</>
	);
};

export default Dashboard1;
