import { UserDto } from './user';

export const MissionPayrollExpenseDisplay: Record<MissionPayrollExpenseType, string> = {
	ADVANCE: 'Avance sur frais',
	DEPOSIT: 'Acompte',
};

export enum MissionPayrollExpenseType {
	ADVANCE = 'ADVANCE',
	DEPOSIT = 'DEPOSIT',
}

export class MissionPayrollExpenseDto {
	id: number;
	missionId: number;
	creatorId: number;
	creator: UserDto;
	type: MissionPayrollExpenseType;
	amount: number;
	text: string;
	date: string;
}
