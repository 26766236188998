import React, { useState } from 'react';
import { Box, CircularProgress, IconButton } from '@material-ui/core';
import UserSelect, { UserSuggestion } from '../user/UserSelect';
import { Add } from '@material-ui/icons';

type AddUserAutocompleteProps = {
	callback: (
		userToAdd: UserSuggestion,
		setUserToAdd: React.Dispatch<React.SetStateAction<UserSuggestion>>,
		setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
	) => void;
	placeholder?: string;
	actifOnly?: boolean;
};

const AddUserAutocomplete = ({ callback, placeholder, actifOnly = false }: AddUserAutocompleteProps) => {
	const [userToAdd, setUserToAdd] = useState<UserSuggestion>(null);
	const [isLoading, setIsLoading] = useState(false);

	return (
		<Box display="flex" flexWrap="wrap" alignItems="center">
			<Box width={300} mr={2}>
				<UserSelect value={userToAdd} onChange={(user) => setUserToAdd(user)} placeholder={placeholder} actifOnly={actifOnly}/>
			</Box>
			<IconButton
				disabled={isLoading || !userToAdd}
				style={{ height: 30 }}
				size="small"
				color="primary"
				onClick={() => callback(userToAdd, setUserToAdd, setIsLoading)}
				children={!isLoading ? <Add /> : <CircularProgress size={20} />}
			/>
		</Box>
	);
};

export default AddUserAutocomplete;
