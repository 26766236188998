import React from 'react';
import { InputAdornment, makeStyles } from '@material-ui/core';
import { TextField, FormControl, FormHelperText } from '@material-ui/core';

export type TextInputProps = {
	value?: string;
	type?: string;
	variant?: 'standard' | 'outlined' | 'filled';
	onChange?: (value: string) => any;
	label?: string;
	fullWidth?: boolean;
	icon?: any;
	iconPosition?: 'end' | 'start';
	error?: string;
	inputRef?: any;
	width?: number | string;
	flexGrow?: number | 'inherit' | 'initial' | '-moz-initial' | 'revert' | 'unset' | undefined;
	onClick?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
	classes?: {
		formControl?: string;
		input?: string;
	};
	margin?: number | string;
	marginBottom?: number | string;
	marginRight?: number | string;
	marginLeft?: number | string;
	rows?: number;
	multiline?: boolean;
	helperText?: string;
	required?: boolean;
	disabled?: boolean;
	pattern?: string;
	size?: 'small' | 'medium';
	style?: React.CSSProperties;
	inputProps?: any;
};

const TextInput = React.memo((props: TextInputProps) => {
	let classes = useStyles({ small: props.size === 'small' });

	return (
		<FormControl
			onClick={props.onClick}
			className={props.classes?.formControl}
			fullWidth={props.fullWidth}
			error={!!props.error}
			size={props.size}
			style={
				props.width
					? {
							width: props.width,
							flexGrow: props.flexGrow,
							margin: props.margin,
							marginBottom: props.margin ? props.margin : props.marginBottom,
							marginLeft: props.margin ? props.margin : props.marginLeft,
					  }
					: undefined
			}
		>
			<TextField
				className={classes.root}
				rows={props.rows}
				value={props.value}
				onChange={
					props.onChange
						? (event: any) => {
								props.onChange?.(event.target.value);
						  }
						: undefined
				}
				multiline={props.multiline}
				fullWidth={props.fullWidth}
				label={props.label}
				type={props.type}
				variant={(props.variant as any) ?? 'outlined'}
				error={!!props.error}
				inputRef={props.inputRef}
				required={props.required}
				disabled={props.disabled}
				size={props.size}
				style={props.style}
				InputProps={
					props.icon
						? {
								[props.iconPosition === 'start' ? 'startAdornment' : 'endAdornment']: (
									<InputAdornment position={props.iconPosition || 'start'}>
										{props.icon}
									</InputAdornment>
								),
						  }
						: undefined
				}
				inputProps={{ ...props.inputProps, pattern: props.pattern }}
			/>
			{(props.error || props.helperText) && (
				<FormHelperText>{props.error || props.helperText}</FormHelperText>
			)}
		</FormControl>
	);
});

const useStyles = makeStyles({
	root: {
		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: (props: { small: boolean }) => (props.small ? '0px' : ''),
		},
	},
});

export default TextInput;
