import { useEffect, useState } from 'react';
import { get } from '../api';
/**
 * returns [isLoading, data, error]
 */
function useFetch<T = any, E = any>(
	url: string,
	watchProps: 'always' | any[] = [],
	callback?: (data: T) => any,
): [boolean, T, E, (data: T) => void] {
	let [{ isLoading, data, error }, setState] = useState({ isLoading: true, data: null, error: null });
	useEffect(
		() => {
			async function fetchData() {
				try {
					if (!isLoading) setState({ isLoading: true, data: null, error: null });
					let data = await get(url);
					callback?.(data);
					setState({ isLoading: false, data, error: null });
				} catch (err) {
					console.error(err);
					setState({ isLoading: false, data: null, error: err });
				}
			}
			fetchData();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		watchProps === 'always' ? undefined : watchProps,
	);
	return [
		isLoading,
		data as any as T,
		error as any as E,
		(data: T) => setState((state) => ({ ...state, data })),
	];
}

export default useFetch;
