import { MissionToUserStatus } from './missionToUser';
import { UserDto } from './user';

export class MissionInterviewDto {
	constructor() {
		this.applicant = new UserDto();
	}
	id: number;
	missionId: number;
	applicantId: number;
	applicant: UserDto;
	interviewDatetime: string;
	mark?: number;
	absent?: boolean;
}

export class MissionElderDto extends UserDto {
	missionToUserStatus: MissionToUserStatus;
}

export interface InterviewsAndEldersDto {
	interviews: MissionInterviewDto[];
	elders: MissionElderDto[];
}
