import {
	Box,
	Chip,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Pagination, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import moment from 'moment';
import React, { useState } from 'react';
import routes from '../../api/routes';
import AssociationSelect from '../../components/associations/AssocationSelect';
import DonationDetails from '../../components/donations/DonationDetails';
import MissionSelect from '../../components/missions/MissionSelect';
import Modal from '../../components/Modal';
import { DonationsListFiltersDto, DonorCallListRowDto, rowsPerPage } from '../../dto/donation';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';

const serialize = (data) => encodeURIComponent(JSON.stringify(data));

const DonorCalls = () => {
	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));

	const [reloadToken, setReloadToken] = useState(0);
	const [page, setPage] = useState(1);
	const [donationsStatus, setDonationsStatus] = useState<'current' | 'past'>('current');

	const form = useForm({
		init: new DonationsListFiltersDto(),
	});

	let filters = form.values;
	filters.page = page;
	filters.status = donationsStatus;

	const [, currentCount] = useFetch<{ count: number }>(
		routes.donations.calls.count({}, { filters: serialize({ ...filters, status: 'current' }) }),
		[filters.associationId, filters.missionIds],
	);
	const [, pastCount] = useFetch<{ count: number }>(
		routes.donations.calls.count({}, { filters: serialize({ ...filters, status: 'past' }) }),
		[filters.associationId, filters.missionIds],
	);
	const [isLoading, donorCalls] = useFetch<DonorCallListRowDto[]>(
		routes.donations.calls.all({}, { filters: serialize(filters) }),
		[page, donationsStatus, filters.associationId, filters.missionIds],
	);

	console.log(donorCalls);

	const [openDonationId, setOpenDonationId] = useState(null);

	let counts = { current: currentCount?.count, past: pastCount?.count };

	const classes = useStyles({ border: false });

	const tabs = [
		{ status: 'current', label: 'En cours' },
		{ status: 'past', label: 'Terminées' },
	] as const;

	return (
		<Box p={3}>
			{openDonationId && (
				<Modal open onClose={() => setOpenDonationId(null)}>
					<DonationDetails
						reload={() => setReloadToken(reloadToken + 1)}
						exit={() => setOpenDonationId(null)}
						expandedSection="calls"
						donationId={openDonationId}
					/>
				</Modal>
			)}
			<Box display="flex" flexWrap="wrap" m={-0.8}>
				<AssociationSelect {...form.field('associationId')} margin={8} />
				<MissionSelect
					associationId={filters.associationId}
					multiple
					{...form.field('missionIds')}
					margin={8}
				/>
			</Box>
			<Box mt={2} className={classes.tabs}>
				<ToggleButtonGroup
					style={{ display: 'flex', flexWrap: 'wrap' }}
					exclusive
					value={donationsStatus}
					onChange={(e, v) => {
						setDonationsStatus(v);
					}}
				>
					{tabs.map(({ status, label }) => (
						<ToggleButton key={status} value={status}>
							<Box mr={1}>{label}</Box>
							<Chip size="small" color="primary" label={counts?.[status] ?? '?'} />
						</ToggleButton>
					))}
				</ToggleButtonGroup>
			</Box>
			<Box
				my={2}
				style={{
					overflow: 'auto',
					maxWidth: isBigScreen ? 'calc(100vw - 346px)' : 'calc(100vw - 20px)',
					border: '1px solid #CCC',
					borderRadius: '3px',
					borderTopLeftRadius: 0,
					marginTop: 0,
				}}
			>
				{isLoading ? (
					<Box display="flex" justifyContent="center" my={8}>
						<CircularProgress />
					</Box>
				) : (
					<Table size="medium" classes={{ root: classes.tableRoot }}>
						<TableHead>
							<TableRow>
								<TableCell>Date</TableCell>
								<TableCell>Heure</TableCell>
								<TableCell>N°Trico</TableCell>
								<TableCell>ONG</TableCell>
								<TableCell>RD</TableCell>
								<TableCell>Nom</TableCell>
								<TableCell>Prénom</TableCell>
								<TableCell>Nb Appels restant</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{donorCalls &&
								donorCalls.length &&
								donorCalls.map((row, i) => (
									<TableRow
										key={row.donationId}
										style={{ cursor: 'pointer' }}
										onClick={() => {
											setOpenDonationId(row.donationId);
										}}
									>
										<TableCell align="left">
											{moment(row.callDatetime).format('DD/MM/YYYY')}
										</TableCell>
										<TableCell align="left">{moment(row.callDatetime).format('HH:mm')}</TableCell>
										<TableCell align="left">{row.tricoCode}</TableCell>
										<TableCell align="left">{row.associationName}</TableCell>
										<TableCell align="left">
											{row.recruiterFirstName} {row.recruiterLastName}
										</TableCell>
										<TableCell align="left">{row.lastName}</TableCell>
										<TableCell align="left">{row.firstName}</TableCell>
										<TableCell align="left">
											{3 - parseInt((row.callCount as any) || '0')}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				)}
				<Box p={1} display="flex" justifyContent="end">
					<Pagination
						page={page}
						onChange={(_, page) => setPage(page)}
						count={Math.ceil((counts[donationsStatus] || 0) / rowsPerPage)}
					/>
				</Box>
			</Box>
		</Box>
	);
};

const useStyles = makeStyles(({ isBigScreen }: { isBigScreen?: boolean }) => ({
	tabs: {
		marginBottom: -1,
		'& button': {
			borderColor: '#CCC',
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
			fontSize: 12,
			padding: '10px 15px',
		},
	},
	tableRoot: {
		whiteSpace: 'nowrap',
		'& thead th': {
			textTransform: 'uppercase',
			color: '#444',
			fontSize: 11.5,
		},
		'& tbody tr.noBottomBorder > td': {
			borderBottom: 'none',
		},
		'& tbody tr:not(.comment):hover': {
			backgroundColor: '#FAFAFA',
			boxShadow: 'inset 3px 0px 0px 0px #0093EE',
		},
	},
}));

export default DonorCalls;
