import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import useForm from '../../hooks/useForm';
import routes from '../../api/routes';
import { MissionInterviewDto } from '../../dto/missionInterviews';
import { ApplicantSourceTypeDisplay, UserDto } from '../../dto/user';
import SubmitButton from '../form/SubmitButton';
import DateTimePicker from '../form/DateTimePicker';
import { Titles, Genders } from '../../dto/user';
import { Select, TextInput, DatePicker } from '../humanResources/UserForm';
import PhoneNumberInput from '../form/PhoneNumberInput';
import Title from '../Title';
import UserSelect, { UserSuggestion } from '../user/UserSelect';
import NumberInput from '../form/NumberInput';
import DefaultTextInput from '../form/TextInput';

type AddInterviewProps = {
	interview: Partial<MissionInterviewDto>;
	reload: () => void;
	exit: () => void;
};

const AddInterview = ({ interview, exit, reload }: AddInterviewProps) => {
	const userForm = useForm({ init: interview.applicant || new UserDto() });
	const interviewForm = useForm({
		init: interview || new MissionInterviewDto(),
		url: interview.id ? routes.missions.interviews.update : routes.missions.interviews.create,
		onSuccess: () => {
			reload();
			exit();
		},
	}).addSubform('applicant', userForm);

	const [sponsor, setSponsor] = useState<UserSuggestion>(
		interview.applicant?.sponsor
			? {
					id: interview.applicant.sponsor.id + '',
					firstName: interview.applicant.sponsor.firstName,
					lastName: interview.applicant.sponsor.lastName,
					path: '',
			  }
			: null,
	);

	return (
		<>
			<Title display="flex" justifyContent="center">
				{interview.id ? 'Modification' : 'Ajout'} d'un entretien
			</Title>
			<form
				noValidate
				onSubmit={interviewForm.submit}
				style={{ maxWidth: 1000, width: '100%', position: 'relative' }}
			>
				<Title small>Information Tricoteur</Title>
				<Box display="flex" flexWrap="wrap" mx={-0.5}>
					<Select values={Titles} {...userForm.field('title')} label="Civilité" required />
					<Select values={Genders} {...userForm.field('gender')} label="Sexe" required />
					<TextInput marginLeft={8} {...userForm.field('lastName')} label="Nom" required />
					<TextInput marginLeft={8} {...userForm.field('firstName')} label="Prénom" required />
					<DatePicker {...userForm.field('birthdate')} label="Date de naissance" />
					<PhoneNumberInput
						{...userForm.field('phoneNumber')}
						label="Téléphone"
						type="tel"
						width={350}
						flexGrow={1}
						margin={8}
						marginLeft={10}
					/>
					<TextInput marginLeft={8} {...userForm.field('email')} label="Email" type="email" required />
				</Box>

				<Box my={2} mx={-0.5} display="flex" justifyContent="flex-start" alignItems="center">
					<Box width={350} marginRight={3}>
						<Select
							values={ApplicantSourceTypeDisplay}
							{...userForm.field('applicantSource')}
							onChange={(applicantSource) => {
								userForm.setMultipleValues({ sponsorId: null, applicantSource });
								setSponsor(null);
							}}
							label="Origine du candidat"
						/>
					</Box>
					{userForm.field('applicantSource').value !== 'OTHER' ? (
						<Box
							width={350}
							style={{
								visibility: ['SPONSORSHIP', 'PRR'].includes(userForm.field('applicantSource').value)
									? 'visible'
									: 'hidden',
							}}
						>
							<UserSelect
								value={sponsor}
								positions={userForm.field('applicantSource').value === 'PRR' ? ['PRR'] : []}
								onChange={(user) => {
									userForm.field('sponsorId').onChange(user?.id ? +user.id : null);
									setSponsor(user);
								}}
								actifOnly
							/>
						</Box>
					) : (
						<Box
							width={350}
							marginTop={-1}
							style={{
								visibility: ['OTHER'].includes(userForm.field('applicantSource').value)
									? 'visible'
									: 'hidden',
							}}
						>
							<TextInput
								marginLeft={8}
								{...userForm.field('applicantSourceOther')}
								label="Précision sur l'origine"
							/>
						</Box>
					)}
				</Box>

				<Title small>Information Entretien</Title>
				<Box mt={1} mb={2} mx={-0.5} display="flex" flexDirection="column" gridGap={8} px={1}>
					<DateTimePicker
						{...interviewForm.field('interviewDatetime')}
						fullWidth
						label="Date et heure"
					/>
					<DefaultTextInput marginLeft={8} label="Commentaire" {...userForm.field('comment')} />
					<NumberInput
						width={250}
						label="Note"
						min={0}
						max={20}
						{...interviewForm.field('mark')}
						isFloat
					/>
				</Box>
				<Box display="flex" justifyContent="space-between">
					<Button onClick={exit}>Annuler</Button>
					<SubmitButton edit submitting={interviewForm.isSubmitting} success={interviewForm.success}>
						{interview.id ? 'Modifier' : 'Ajouter'} l'entretien
					</SubmitButton>
				</Box>
			</form>
		</>
	);
};

export default AddInterview;
