import { FileDto } from './file';
import { AssociationDto } from './association';
import { MissionToUserDto } from './missionToUser';
import { MissionPayrollExpenseDto } from './missionPayrollExpense';
import { UserDto } from './user';
import { ClientDto } from './client';
import { ProgramDto } from './program';

export const Rhythms = {
	lv: 'Du Lundi au Vendredi',
	ms: 'Du Mardi au Samedi',
};
export type Rhythm = keyof typeof Rhythms | '';

export const MissionTypes = {
	paris: 'Paris',
	fixe: 'Fixe',
	it: 'Itinérante',
	cco: 'CCO',
};
export type MissionType = keyof typeof MissionTypes | '';

export class MissionDto {
	id: number;
	startDate: string = '';
	endDate: string = '';
	rhythm: Rhythm = '';
	missionType: MissionType = '';
	tricoCode: string = '';
	associationId: number | null;
	association?: AssociationDto;
	programId: number | null;
	program?: ProgramDto;
	ongCode: string = '';
	mainCity: string = '';
	minimumDonation: number = 0;
	initialTickets: number = 0;
	teamManagerId: number;
	teamManagerName?: string = '';
	teamManager: UserDto;
	tricoteurs?: number = 0;
	programManagerId: number;
	programManager: UserDto | null;
	secondaryCity: string = '';
	spots: string = '';
	secondaryCitySpots: string;
	clientId?: number | null = null;
	client?: ClientDto;
	allowDonationsExportByDefault = false;
	missionPayrollExpenses?: MissionPayrollExpenseDto[];
	maintenanceBonus = false;
	trainingBonus = false;
	midMissionComment?: string;
	endMissionComment?: string;
	townHallAuthorizations?: FileDto[];
	users?: UserDto[];
	files?: FileDto[];
}

export class MissionWithAssociationDto extends MissionDto {
	association: AssociationDto;
	associationName: string;
	associationId: number;

	streetHours?: number;
	paidHours?: number;
	unjustifiedAbsence?: string;
	absence?: string;
	delay: string;
	completedDonationsCount?: string;
	donationsTotalStopPA?: string;
	averageDonation?: number;
	averageAge?: string;
}

export class MissionForPayrollDto extends MissionWithAssociationDto {
	totalFee: number;
	totalFes: number;
	sponsorshipsCount: number;
	total_amount?: number;
	total_amountWithoutTva?: number;
	total_tva?: number;
}

export interface AddUserToMissionDto {
	userFields: Pick<
		UserDto,
		| 'id'
		| 'gradeId'
		| 'contract'
		| 'contractWeeklyHours'
		| 'contractWeeklyHours'
		| 'contractWeekDays'
		| 'contractStartDate'
		| 'contractEndDate'
		| 'clothingSize'
		| 'comment'
	>;
	missionToUserFields: Pick<MissionToUserDto, 'isDriver' | 'perDiem' | 'swile'>;
}

export class KPIFilters {
	userId: string | null;
}
