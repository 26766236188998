import React, { useRef } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import MomentUtils from '@date-io/moment';
import {
	MuiPickersUtilsProvider,
	DatePicker as MUIDatePicker,
	DatePickerProps as MUIDatePickerProps,
} from '@material-ui/pickers';
import { FormControl, FormHelperText } from '@material-ui/core';
moment.locale('fr');

export type DatePickerProps = Omit<MUIDatePickerProps, 'error' | 'margin' | 'value' | 'onChange'> & {
	error?: string;
	width?: number | string;
	flexGrow?: number;
	margin?: number;
	inputRef?: any;
	value?: string;
	onChange?: (value: string) => any;
};

const DatePicker = ({
	value,
	onChange,
	error,
	width,
	flexGrow,
	margin,
	inputRef,
	...props
}: DatePickerProps) => {
	let ref = useRef<HTMLDivElement>();

	inputRef?.({
		value,
		checkValidity: () => !props.required || value,
		validationMessage:
			props.required && !value ? 'Ce champ est obligatoire, veuillez sélectionner une valeur' : '',
		scrollIntoView: (params: any) => (ref.current as any)?.scrollIntoView?.(params),
	});

	return (
		<MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="fr">
			<FormControl
				ref={ref as any}
				fullWidth={props.fullWidth}
				error={!!error}
				style={{ width, flexGrow, margin, boxSizing: 'border-box' }}
			>
				<MUIDatePicker
					value={value ? new Date(value) : null}
					onChange={(date) => onChange?.(date.format('YYYY-MM-DD'))}
					fullWidth={props.fullWidth}
					inputVariant="outlined"
					cancelLabel="Annuler"
					error={!!error}
					{...props}
				/>
				{error && <FormHelperText>{error}</FormHelperText>}
			</FormControl>
		</MuiPickersUtilsProvider>
	);
};

export default DatePicker;
