import React from "react";
import { Switch as MUISwitch, SwitchProps as MUISwitchProps } from "@material-ui/core";

type SwitchProps = Omit<MUISwitchProps, 'onChange' | 'value'> & {
	onChange?: (value: boolean) => any;
	value?: boolean;
	error?: string;
}

const Switch = ({ value, onChange, ...props}: SwitchProps) => {
	return <MUISwitch checked={value} onChange={(event, value) => {onChange?.(value)}} color="primary" {...props} />
}

export default Switch;