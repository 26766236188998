import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import React from 'react';
import routes from '../../api/routes';
import useAuth from '../../hooks/useAuth';
import useForm from '../../hooks/useForm';
import ErrorMessage from '../form/ErrorMessage';
import TextInput from '../form/TextInput';

type LoginFormProps = {
	style?: React.CSSProperties;
};

const LoginForm = ({ style }: LoginFormProps) => {
	let classes = useStyles({ border: false });
	let { login } = useAuth();
	let form = useForm({
		url: routes.auth.login,
		init: { password: '', tricoId: '' },
		onSuccess: (data) => login(data.user, data.token),
	});

	return (
		<form noValidate onSubmit={form.submit} style={style}>
			<Box>
				<Box mb={2} display="flex" justifyContent="center">
					<img src="/images/trico.png" alt="trico" />
				</Box>

				<Box py={4} style={{ width: '100%', maxWidth: 450 }}>
					<Box className={classes.imagesBox} display="flex" justifyContent="center" flexWrap="wrap">
						<img src="/images/amnesty_international.png" alt="Amnesty International" />
						<img src="/images/oxfam.png" alt="oxfam" />
						<img src="/images/Logo-fondation-auteuil.jpg" alt="Apprentis d'Auteuil" />
						<img src="/images/logo_secours_catholique.jpg" alt="Secours Catholique" />
						<img src="/images/FMDM.png" alt="FMDM" />
						<img src="/images/Aide_et_action.png" alt="Aide et Action" />
						<img src="/images/croix_rouge.png" alt="croix rouge" />
					</Box>
				</Box>
			</Box>

			<ErrorMessage>{form.errors?.global || ' '}</ErrorMessage>

			<Box>
				<TextInput
					{...form.field('tricoId')}
					label="Matricule"
					type="text"
					variant="outlined"
					fullWidth
					icon={<AccountCircle />}
				/>
			</Box>
			<Box my={2}>
				<TextInput
					{...form.field('password')}
					label="Mot de passe"
					type="password"
					variant="outlined"
					fullWidth
					icon={<LockIcon />}
				/>
			</Box>
			<Box>
				<Button
					type="submit"
					disabled={!(form.values.tricoId && form.values.password) || form.isSubmitting}
					fullWidth
					size="large"
					variant="contained"
					color="primary"
				>
					Se connecter
				</Button>
			</Box>
		</form>
	);
};

const useStyles = makeStyles({
	imagesBox: {
		'& img': {
			margin: 5,
			height: 52,
		},
	},
	errorMessage: {
		whiteSpace: 'pre',
	},
});

export default LoginForm;
