import React, { useEffect } from 'react';
import LoginPage from './pages/LoginPage';
import useAuth from './hooks/useAuth';
import { events } from './api/index';
import Main from './Main';

function App() {
	let { user, logout } = useAuth();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => events.on('auth_failed', logout) as any, []);
	if (window.location.href.includes('nouveau-mot-de-passe')) {
		return <LoginPage />;
	} else {
		return user ? <Main /> : <LoginPage />;
	}
}

export default App;
