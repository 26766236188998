import React from 'react';
import TextInput, { TextInputProps } from './TextInput';
import { Phone } from '@material-ui/icons';

export type PhoneNumberInputProps = TextInputProps;
const PhoneNumberInput = ({ value, onChange, ...props }: PhoneNumberInputProps) => {
	const formatValue = (nvalue: string) => {
		let rvalue = nvalue.replace(/[^+0-9]/g, '');
		if (rvalue.length > 0 && rvalue.length > (rvalue[0] === '+' ? 12 : 10)) return value;
		return (rvalue.match(/(?:\+[0-9]{1,3})|(?:[0-9]{1,2})/g) || [rvalue]).join(' ');
	};
	return (
		<TextInput
			value={value}
			onChange={(val) => onChange?.(formatValue(val))}
			pattern="^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$"
			{...props}
			icon={<Phone />}
		/>
	);
};

export default PhoneNumberInput;
