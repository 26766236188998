import { Box, Button } from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';
import React, { useState } from 'react';
import routes from '../../api/routes';
import { GradeDto } from '../../dto/grade';
import { Roles, RolesAbbreviations } from '../../dto/role';
import { Categories, ETAMCategoryOptions, ICCategoryOptions } from '../../dto/user';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import DeleteButton from '../form/DeleteButton';
import NumberInput from '../form/NumberInput';
import Select from '../form/Select';
import TextInput from '../form/TextInput';
import Title from '../Title';
import Switch from '../form/Switch';

const GradesForm = () => {
	const [grades, setGrades] = useState<GradeDto[]>([]);
	const [editGrade, setEditGrade] = useState<GradeDto>(null);
	const [isLoading] = useFetch(routes.users.grades.all, [], (loadedGrades) => {
		setGrades([...loadedGrades]);
	});

	const creationForm = useForm({
		init: new GradeDto(),
		onSuccess: (grade) => {
			setGrades([...grades, grade]);
			creationForm.clear();
		},
		url: routes.users.grades.create,
	});

	const editForm = useForm({
		values: editGrade,
		onChange: (gradeDto: GradeDto) => setEditGrade(gradeDto),
		onSuccess: (grade) => {
			setGrades(grades.map((g) => (grade.id === g.id ? grade : g)));
			setEditGrade(null);
		},
		url: routes.users.grades.edit({ id: editGrade?.id }),
	});

	return (
		<Box width={800} maxWidth="90vw">
			<Title big>Gestion des postes</Title>
			<Box display="flex" flexDirection="column">
				<Box py={3}>
					{!isLoading &&
						grades
							.sort((a, b) => a.hourlyRate - b.hourlyRate)
							.map((grade) =>
								editGrade && grade.id === editGrade.id ? (
									<form
										noValidate
										onSubmit={editForm.submit}
										style={{ display: 'flex', flexWrap: 'wrap', width: '100%', lineHeight: 4 }}
									>
										<Box mr={1} flexGrow={1} width={250}>
											<TextInput {...editForm.field('name')} fullWidth label="Nom du poste" />
										</Box>
										<Box mr={1} flexGrow={1} width={250}>
											<TextInput
												{...editForm.field('description')}
												fullWidth
												label="Nom du grade"
											/>
										</Box>
										<Box mr={1} flexGrow={1} width={250}>
											<NumberInput
												{...editForm.field('hourlyRate')}
												fullWidth
												label="Taux horaire associé"
												isFloat
											/>
										</Box>
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<div style={{ height: 30, marginTop: -30, marginBottom: 5 }}>
												<p>Archivé</p>
											</div>
											<div style={{ height: 30 }}>
												<Switch {...editForm.field('archived')} />
											</div>
										</div>
										<Select values={Roles} {...editForm.field('role')} label="Privilèges" />
										<Select values={Categories} {...editForm.field('category')} label="Catégorie" />
										<Select
											values={
												editForm.values.category === 'ETAM'
													? ETAMCategoryOptions
													: editForm.values.category === 'IC'
													? ICCategoryOptions
													: {}
											}
											{...editForm.field('categoryOption')}
											label="Option catégorie"
										/>
										<Button type="submit" variant="contained" color="primary">
											<Edit /> Valider
										</Button>
									</form>
								) : (
									<Box
										my={1}
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
										}}
									>
										<Box pl={2}>
											<b>{grade.name}</b> - {grade.description} - {grade.hourlyRate}€/h -{' '}
											{RolesAbbreviations[grade.role]} - {grade.category} - {grade.categoryOption}
										</Box>
										<Box display="flex">
											<Box mr={1}>
												<Button
													variant="contained"
													color="primary"
													onClick={() => setEditGrade(grade)}
												>
													<Edit />
												</Button>
											</Box>
											<DeleteButton
												url={routes.users.grades.delete({ id: grade.id })}
												confirmationMessage="Êtes vous sur de vouloir supprimer ce grade ?"
												successMessage="Le grade a bien été supprimée."
												returnLabel="OK"
												onSuccess={() => {
													setGrades(grades.filter((g) => g.id !== grade.id));
												}}
											/>
										</Box>
									</Box>
								),
							)}
				</Box>
				<form
					noValidate
					onSubmit={creationForm.submit}
					style={{ display: 'flex', flexWrap: 'wrap', width: '100%', lineHeight: 4 }}
				>
					<Box mr={1} flexGrow={1} width={250}>
						<TextInput {...creationForm.field('name')} fullWidth label="Nom du poste" />
					</Box>
					<Box mr={1} flexGrow={1} width={250}>
						<TextInput {...creationForm.field('description')} fullWidth label="Nom du grade" />
					</Box>
					<Box mr={1} flexGrow={1} width={250}>
						<NumberInput
							{...creationForm.field('hourlyRate')}
							fullWidth
							label="Taux horaire associé"
							isFloat
						/>
					</Box>
					<Select values={Roles} {...creationForm.field('role')} label="Privilèges" />
					<Select values={Categories} {...creationForm.field('category')} label="Catégorie" />
					<Select
						values={
							creationForm.values.category === 'ETAM'
								? ETAMCategoryOptions
								: creationForm.values.category === 'IC'
								? ICCategoryOptions
								: {}
						}
						{...creationForm.field('categoryOption')}
						label="Option catégorie"
					/>
					<Button type="submit" variant="contained" color="primary">
						<Add /> Ajouter
					</Button>
				</form>
			</Box>
		</Box>
	);
};

export default GradesForm;
