import React from 'react';
import { Box, Button } from '@material-ui/core';
import useForm from '../../../hooks/useForm';
import apiRoutes from '../../../api/routes';
import SubmitButton from '../../../components/form/SubmitButton';
import { Select, NumberInput, TextInput } from '../../../components/humanResources/UserForm';

import Title from '../../../components/Title';
import {
	AbsenceDisplay,
	MissionDailyReportOnRecruiterDto,
	RoutesDisplay,
	RoutesTypes,
} from '../../../dto/missionReport';
import { useStyles } from '../../../components/interviews/AddUserToMission';

type EditDailyReportOnRecruiterProps = {
	userReport: Partial<MissionDailyReportOnRecruiterDto>;
	update: () => void;
	exit: () => void;
};

const EditDailyReportOnRecruiter = ({ userReport: ur, exit, update }: EditDailyReportOnRecruiterProps) => {
	const classes = useStyles({ border: false });

	const form = useForm({
		init: {
			id: ur.id,
			absence: ur.absence,
			usedTickets: ur.usedTickets,
			usedPaperTickets: ur.usedPaperTickets,
			paidHours: ur.paidHours,
			routesDuration: ur.routesDuration,
			perDiem: ur.perDiem,
			routes: ur.routes as RoutesTypes | 'NULL',
			commentHR: ur.commentHR,
		},
		url: apiRoutes.missions.reports.recruiterDailyReport.update,
		onSuccess: () => {
			update();
		},
	});

	return (
		<>
			<Title display="flex" justifyContent="center">
				Modifier un rapport
			</Title>
			<form onSubmit={form.submit}>
				<Box className={classes.fields}>
					<Select
						values={AbsenceDisplay}
						{...form.field('absence')}
						label="Absence"
					/>
					<NumberInput {...form.field('usedTickets')} label="Tickets restaurant" />
					<NumberInput {...form.field('usedPaperTickets')} label="Tickets restaurant" />
					<NumberInput {...form.field('paidHours')} label="Heures payées" />
					<NumberInput {...form.field('routesDuration')} label="Temps de trajet (minutes)" />
					<NumberInput {...form.field('perDiem')} label="Per Diem" />
					<Select
						values={{ ...RoutesDisplay, NULL: '' }}
						{...form.field('routes')}
						label="Conducteur"
						onChange={(v) => form.field('routes').onChange(v !== 'NULL' ? v : null)}
					/>
					<TextInput {...form.field('commentHR')} label="Commentaire RH" />
				</Box>
				<Box display="flex" justifyContent="space-between">
					<Button onClick={exit}>Annuler</Button>
					<SubmitButton submitting={form.isSubmitting} success={form.success} edit>
						Sauvegarder
					</SubmitButton>
				</Box>
			</form>
		</>
	);
};

export default EditDailyReportOnRecruiter;
