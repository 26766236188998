import { UserDto } from './user';

export class MissionReportDto {
	id: number;
	comment: string;
	type: 'mid' | 'end';
	missionId: number;
	creatorId: number;
	date: string;
}

export class MissionReportsDto {
	mid: MissionReportDto & { type: 'mid' };
	end: MissionReportDto & { type: 'end' };
}

export class MissionDailyReportDto {
	id: number;
	comment: string;
	missionId: number;
	creatorId: number;
	date: string;
	location: string;
	formation: boolean;
	usedTickets: number;
	startDayHour: string;
}

export class MissionReportOnRecruiterDto {
	id: number;
	comment: string;
	type: 'mid' | 'end';
	missionId: number;
	reportId: number;
	recruiterId: number;
	creatorId: number;
	date: string;
}

export class MissionReportsOnRecruiterDto {
	mid: MissionReportOnRecruiterDto & { type: 'mid' };
	end: MissionReportOnRecruiterDto & { type: 'end' };
}

export class MissionDailyReportOnRecruiterDto {
	id: number;
	comment: string;
	commentHR: string;
	missionId: number;
	reportId: number;
	recruiterId: number;
	recruiter?: UserDto;
	creatorId: number;
	date: string;
	location: string;
	weather: string;
	formation: boolean;
	expose025: boolean;
	expose050: boolean;
	exposeName025: string;
	driver?: boolean;
	driverGoing?: string;
	driverReturn?: string;
	routesDuration: number;
	streetHours: number;
	paidHours: number;
	usedTickets: number;
	usedPaperTickets: number;
	routes?: RoutesTypes;
	perDiem: number;
	delay: number;
	absence: AbsenceTypes;
}

export type RoutesTypes = 'ROUND_TRIP' | 'RETURN' | 'COME';

export const RoutesDisplay: Record<RoutesTypes, string> = {
	COME: 'Aller',
	RETURN: 'Retour',
	ROUND_TRIP: 'Aller-retour',
};

type AbsenceTypes =
	| 'DELAY'
	| 'PROVED_ABSENCE'
	| 'UNJUSTIFIED_ABSENCE'
	| 'SICK_LEAVE'
	| 'ACCIDENT'
	| 'DISCHARGE'
	| 'NONE';

export const AbsenceDisplay: Record<AbsenceTypes, string> = {
	DELAY: 'Retard',
	PROVED_ABSENCE: 'Absence justifiée',
	UNJUSTIFIED_ABSENCE: 'Absence injustifiée',
	SICK_LEAVE: 'Arrêt maladie',
	ACCIDENT: 'Accident',
	DISCHARGE: 'Décharge',
	NONE: '',
};
