import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import SideMenu from './components/SideMenu';
import TopBar from './components/TopBar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import Associations from './pages/associations/index';
import DonorCalls from './pages/donations/DonorCalls';
import Donations from './pages/donations/Donations';
import HumanResources from './pages/humanRessources';
import Interviews from './pages/interviews/Interviews';
import Missions from './pages/missions';
import Logistics from './pages/logistics';
import Accounting from './pages/accounting';
import ScrollTop from './components/ScrollTop';
import useAuth from './hooks/useAuth';
import { Helmet } from 'react-helmet';
import CronLogs from './pages/donations/CronLogs';
import WelcomeCalls from './pages/donations/WelcomeCalls';
import PayrollPages from './pages/humanRessources/payroll';

function Main() {
	const [open, setOpen] = useState(false);
	const { user } = useAuth();

	return (
		<Router>
			<Helmet>
				<title>{user && user.client ? `Back-office ${user.client.name}` : 'Leto'}</title>
			</Helmet>
			<ScrollTop />
			<Box display="flex">
				<SideMenu open={open} close={() => setOpen(false)} />
				<Box width="100%">
					<TopBar showMenu={() => setOpen(true)} />
					<Box width="100%">
						<Switch>
							<Route exact path="/" component={Home} />
							<Route path="/associations" component={Associations} />
							<Route path="/gestion-des-bulletins/rendez-vous" component={DonorCalls} />
							<Route path="/gestion-des-bulletins/bulletins" component={Donations} />
							<Route path="/gestion-des-bulletins/appels-de-bienvenue" component={WelcomeCalls} />
							<Route path="/gestion-des-bulletins/export-logs" component={CronLogs} />
							<Route path="/logistique" component={Logistics} />
							<Route path="/comptabilite" component={Accounting} />
							<Route path="/missions" component={Missions} />
							<Route path="/ressources-humaines" component={HumanResources} />
							{<Route path="/paie" component={PayrollPages} />}
							<Route path="/interviews" component={Interviews} />
						</Switch>
					</Box>
				</Box>
			</Box>
		</Router>
	);
}

export default Main;
