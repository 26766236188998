import * as React from 'react';
import { RefObject } from 'react';

function useClickOutside(
	exclude: RefObject<HTMLElement>[] | RefObject<HTMLElement>,
	callback: (event: MouseEvent) => any,
) {
	const handleClickOutside = (event: MouseEvent) => {
		exclude = Array.isArray(exclude) ? exclude : [exclude];
		if (!(event.target instanceof Node)) return;

		for (let eref of exclude) {
			if (eref.current && eref.current?.contains(event.target)) return;
		}

		callback(event);
	};

	React.useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	});
}

export default useClickOutside;
