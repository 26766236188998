import { Box } from '@material-ui/core';
import React from 'react';
import routes from '../../api/routes';
import TabletForm from '../../components/logistics/TabletForm';
import { TabletDto } from '../../dto/tablets';

const CreateTablet = () => {
	const url = routes.logistics.tablets.create;
	return (
		<Box px={4} py={0} position="relative" display="flex" flexDirection="column" alignItems="center" mb={8}>
			<TabletForm init={new TabletDto()} url={url}>
				{' '}
			</TabletForm>
		</Box>
	);
};

export default CreateTablet;
