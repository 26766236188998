const SEPACountryCodes = [
	'AD',
	'AT',
	'BE',
	'BG',
	'HR',
	'CY',
	'CZ',
	'DK',
	'EE',
	'FI',
	'FR',
	'DE',
	'GI',
	'GR',
	'HU',
	'IS',
	'IE',
	'IT',
	'LV',
	'LI',
	'LT',
	'LU',
	'MT',
	'MC',
	'NL',
	'NO',
	'PL',
	'PT',
	'RO',
	'SM',
	'SK',
	'SI',
	'ES',
	'SE',
	'CH',
	'GB',
	'VA',
];

export const isIBANInSEPA = (iban: string) => SEPACountryCodes.includes(iban.slice(0, 2).toUpperCase());
