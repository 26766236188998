import React, { useState } from 'react';
import { Box, Button, FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useForm from '../../hooks/useForm';
import routes from '../../api/routes';
import SubmitButton from '../form/SubmitButton';
import { ClothingSizes, Contracts, UserDto } from '../../dto/user';
import { AddUserToMissionDto } from '../../dto/mission';
import { GradeSelect, Select, NumberInput, TextInput } from '../humanResources/UserForm';
import Title from '../Title';
import Switch from '../form/Switch';
import Modal from '../Modal';
import DatePicker from '../form/DatePicker';

export type AddUserToMissionProps = {
	missionId: number;
	userToAdd: AddUserToMissionDto['userFields'] & Pick<UserDto, 'firstName' | 'lastName'>;
	missionStartDate: string;
	missionEndDate: string;
	edit: boolean;
	onSuccess: () => void;
	exit: () => void;
};

const AddUserToMission = ({
	missionId,
	userToAdd,
	missionStartDate,
	missionEndDate,
	edit,
	onSuccess,
	exit,
}: AddUserToMissionProps) => {
	const [changeGradeWarning, setChangeGradeWarning] = useState(false);
	const form = useForm<AddUserToMissionDto>({
		url: routes.missions.interviews.addUserToCompoMission({ id: missionId, changeGradeWarning }),
		onSuccess,
	});

	const userForm = useForm({
		init: {
			id: userToAdd.id,
			gradeId: userToAdd.gradeId,
			contract: userToAdd.contract,
			contractWeeklyHours: userToAdd.contractWeeklyHours || 35,
			contractWeekDays: (userToAdd.contractWeeklyHours || 35) < 35 ? userToAdd.contractWeekDays : null,
			contractStartDate: edit ? userToAdd.contractStartDate : missionStartDate,
			contractEndDate: edit ? userToAdd.contractEndDate : missionEndDate,
			clothingSize: userToAdd.clothingSize,
			comment: userToAdd.comment,
		} as AddUserToMissionDto['userFields'],
	});
	const missionToUserForm = useForm({
		init: { isDriver: false, perDiem: false, swile: false } as AddUserToMissionDto['missionToUserFields'],
	});
	form.addSubform('userFields', userForm);
	form.addSubform('missionToUserFields', missionToUserForm);

	const [newGradeToConfirm, setNewGradeToConfirm] = useState('');
	const resetNewGradeToConfirm = () => setNewGradeToConfirm('');

	const isContractWeekDaysRequired = userForm.field('contractWeeklyHours').value < 35;

	return (
		<>
			<Modal open={!!newGradeToConfirm} onClose={() => setNewGradeToConfirm('')}>
				<>
					<p>Êtes-vous sûr de vouloir changer le poste de cet employé ?</p>
					<Box display="flex" justifyContent="center">
						<FormLabel component="legend">
							Alerte sur la Paie: nouveau Poste et tricoteur en mission
						</FormLabel>
						<Switch value={changeGradeWarning} onChange={setChangeGradeWarning} color="primary" />
					</Box>
					<Box display="flex" justifyContent="center" gridGap={10}>
						<Button onClick={() => resetNewGradeToConfirm()}>Non</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								userForm.field('gradeId').onChange(newGradeToConfirm);
								resetNewGradeToConfirm();
							}}
						>
							Oui
						</Button>
					</Box>
				</>
			</Modal>

			<Title display="flex" justifyContent="center">
				{userToAdd.firstName} {userToAdd.lastName}
			</Title>
			<form noValidate onSubmit={form.submit}>
				<Box display="flex" flexDirection="column">
					<GradeSelect
						{...userForm.field('gradeId')}
						label="Poste"
						required
						onChange={(gradeId) => {
							if (!userToAdd.gradeId || userToAdd.gradeId === gradeId) {
								userForm.field('gradeId').onChange(gradeId);
							} else {
								setNewGradeToConfirm(gradeId);
							}
						}}
					/>
					<Select values={Contracts} {...userForm.field('contract')} label="Type de contrat" required />
					<NumberInput
						marginLeft={8}
						label="Durée de travail hebdomadaire"
						{...userForm.field('contractWeeklyHours')}
						onChange={(v) => {
							userForm.setMultipleValues({ contractWeekDays: '', contractWeeklyHours: +v });
						}}
						required
					/>
					<TextInput
						marginLeft={8}
						{...userForm.field('contractWeekDays')}
						label="Jours de la semaine"
						disabled={!isContractWeekDaysRequired}
						required={isContractWeekDaysRequired}
					/>
					<DatePicker
						style={{ margin: '8px 8px 0 8px' }}
						{...userForm.field('contractStartDate')}
						format="DD/MM/YYYY"
						label="Date de début de contrat"
						required
					/>
					<DatePicker
						style={{ margin: '8px 8px 0 8px' }}
						{...userForm.field('contractEndDate')}
						format="DD/MM/YYYY"
						label="Date de fin de contrat"
						required
					/>
					<Select
						values={ClothingSizes}
						{...userForm.field('clothingSize')}
						label="Taille de vêtements"
					/>
					<TextInput marginLeft={8} {...userForm.field('comment')} label="Commentaire" />
					<Box my={2} display="flex" gridGap="30px" paddingLeft={1}>
						<Box>
							<FormLabel component="legend">Conducteur</FormLabel>
							<Switch {...missionToUserForm.field('isDriver')} color="primary" />
						</Box>
						<Box>
							<FormLabel component="legend">Per Diem</FormLabel>
							<Switch {...missionToUserForm.field('perDiem')} color="primary" />
						</Box>
						<Box>
							<FormLabel component="legend">Swile</FormLabel>
							<Switch {...missionToUserForm.field('swile')} color="primary" />
						</Box>
					</Box>
				</Box>
				<Box display="flex" justifyContent="space-between">
					<Button onClick={exit}>Annuler</Button>
					<SubmitButton edit={edit} submitting={form.isSubmitting} success={form.success}>
						Ajouter à la mission
					</SubmitButton>
				</Box>
			</form>
		</>
	);
};

export const useStyles = makeStyles(() => ({
	fields: {
		marginBottom: '20px',
		boxSizing: 'border-box',
		'& > *': {
			display: 'block',
			width: '100%',
			marginTop: '20px',
		},
	},
}));

export default AddUserToMission;
