import { Box, Button } from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Title from '../../components/Title';
import { ProgramDto, ProgramGoalTypes, ProgramTypes } from '../../dto/program';
import useForm from '../../hooks/useForm';
import DatePicker from '../form/DatePicker';
import ErrorMessage from '../form/ErrorMessage';
import { default as DefaultNumberInput, NumberInputProps } from '../form/NumberInput';
import ReturnButton from '../form/ReturnButton';
import { default as DefaultSelect, SelectProps } from '../form/Select';
import SubmitButton from '../form/SubmitButton';
import Modal from '../Modal';

type ProgramFormProps = {
	url: string;
	init: ProgramDto;
	edit?: boolean;
	children?: any;
	associationId: number;
	icon?: any;
};

const precision = (number: number, nbOfDecimals: number) => {
	const factor = 10 ** nbOfDecimals;
	return Math.round(number * factor) / factor;
};

const ProgramForm = ({ edit, init, url, icon, children, associationId }: ProgramFormProps) => {
	let form = useForm({ url, init });

	return (
		<form noValidate onSubmit={form.submit} style={{ maxWidth: 1000, width: '100%', position: 'relative' }}>
			<Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%">
				<ReturnButton to={`/associations/${associationId}`} askConfirmation>
					Retour à l'associations
				</ReturnButton>
				{children}
			</Box>

			{icon}

			<Title big>{edit ? 'Modification' : 'Création'} d'un programme</Title>

			{form.errors && <ErrorMessage>{form?.errors?.global}</ErrorMessage>}
			<Box display="flex" flexWrap="wrap" mx={-0.5} mb={2}>
				<DatePicker
					{...form.field('startDate')}
					label="Date de début"
					format="DD/MM/YYYY"
					flexGrow={1}
					width={350}
					margin={8}
					required
				/>
				<DatePicker
					{...form.field('endDate')}
					label="Date de fin"
					format="DD/MM/YYYY"
					flexGrow={1}
					width={350}
					margin={8}
					required
				/>
				{/* <NumberInput {...form.field("budget")} type="number" label="Budget confié" icon={<EuroIcon />} iconPosition="end" />
				<NumberInput {...form.field('billing')} type="number" label="Facturation" icon={<EuroIcon />} iconPosition="end" /> */}
				<Select values={ProgramGoalTypes} {...form.field('goalType')} label="Type d'objectif" />
				<Select values={ProgramTypes} {...form.field('programType')} label="Type de programme" />
				<NumberInput {...form.field('realRate')} type="number" label="Tx réel" />
				<NumberInput
					{...form.field('hourlyObjective')}
					onChange={(value) => {
						form.setMultipleValues({
							hourlyObjective: value,
							donationCountObjective: precision(value * form.field('realRate').value || 0, 3),
						});
					}}
					type="number"
					label="Objectif en nombre d'heure"
					disabled={form.values.goalType !== 'collect_hours'}
				/>
				<NumberInput
					{...form.field('donationCountObjective')}
					onChange={(value) => {
						form.setMultipleValues({
							hourlyObjective: precision(value / form.field('realRate').value || 0, 3),
							donationCountObjective: value,
						});
					}}
					label="Nombre de bulletins"
					disabled={form.values.goalType !== 'automatic'}
				/>
			</Box>

			<Box display="flex" justifyContent="end">
				<SubmitButton submitting={form.isSubmitting} success={form.success}>
					{edit ? 'Modifier' : 'Créer'} le programme
				</SubmitButton>
			</Box>

			<Modal open={form.success} noCloseButton>
				<p>Le programme a bien été {edit ? 'modifié' : 'créé'} !</p>
				<RouterLink to={`/associations/${associationId}`}>
					<Button variant="contained" color="primary">
						Retour à l'association
					</Button>
				</RouterLink>
			</Modal>
		</form>
	);
};

const NumberInput = (props: NumberInputProps) => {
	return (
		<DefaultNumberInput
			fullWidth
			{...props}
			width={350}
			flexGrow={1}
			margin={8}
			variant="outlined"
			isFloat
		/>
	);
};

const Select = function <T>(props: SelectProps<T>) {
	return <DefaultSelect fullWidth {...props} width={350} flexGrow={1} margin={8} variant="outlined" />;
};

export default ProgramForm;
