import { Box, Button, InputLabel } from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { TabletDto, TabletTypes } from '../../dto/tablets';
import useForm from '../../hooks/useForm';
import ErrorMessage from '../form/ErrorMessage';
import ReturnButton from '../form/ReturnButton';
import Select from '../form/Select';
import SubmitButton from '../form/SubmitButton';
import Switch from '../form/Switch';
import TextInput from '../form/TextInput';
import Modal from '../Modal';
import Title from '../Title';

type TabletFormProps = {
	url: string;
	init: TabletDto;
	edit?: boolean;
	children?: any;
};

const TabletForm = ({ edit, init, url, children }: TabletFormProps) => {
	let form = useForm({ url, init });

	return (
		<form noValidate onSubmit={form.submit} style={{ maxWidth: 1000, width: '100%', position: 'relative' }}>
			<Box display="flex" justifyContent="space-between" flexWrap="wrap">
				<ReturnButton to={`/logistique/tablettes`} askConfirmation>
					Retour aux tablettes
				</ReturnButton>
				{children}
			</Box>

			<Title big>{edit ? 'Modification' : 'Création'} d'une tablette</Title>

			{form.errors && <ErrorMessage>{form?.errors?.global}</ErrorMessage>}
			<Box mb={3}>
				<TextInput fullWidth flexGrow={1} {...form.field('customId')} label="ID" />
			</Box>
			<Box mb={3}>
				<TextInput fullWidth flexGrow={1} {...form.field('serialNumber')} label="Numéro de série" />
			</Box>
			<Box mb={3}>
				<Select
					flexGrow={1}
					fullWidth
					values={TabletTypes}
					{...form.field('type')}
					label="Type de tablettes"
				/>
			</Box>
			<Box>
				<Box flexGrow={1}>
					<InputLabel>Carte SIM</InputLabel>
					<Switch {...form.field('simCard')} />
				</Box>
			</Box>

			<Box display="flex" justifyContent="end">
				<SubmitButton submitting={form.isSubmitting} success={form.success}>
					{edit ? 'Enregistrer' : 'Créer'} la tablette
				</SubmitButton>
			</Box>

			<Modal open={form.success} noCloseButton>
				<p>La tablette a bien été {edit ? 'modifiée' : 'crée'} !</p>
				<RouterLink to={`/logistique/tablettes`}>
					<Button variant="contained" color="primary">
						Retour aux tablettes
					</Button>
				</RouterLink>
			</Modal>
		</form>
	);
};

export default TabletForm;
