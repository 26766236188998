import { Box, Breadcrumbs, Button, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import routes from '../../api/routes';
import AssociationSelect from '../../components/associations/AssocationSelect';
import Title from '../../components/Title';
import { StockEntry } from '../../dto/stock';
import useFetch from '../../hooks/useFetch';
import { DataGrid, GridColDef, GridRowModel, frFR } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertProps } from '@mui/material/Alert';
import { del, post } from '../../api';
import Modal from '../../components/Modal';
import DeleteButton from '../../components/form/DeleteButton';
import TextInput from '../../components/form/TextInput';
import { ClothingSizes } from '../../dto/user';
import useForm from '../../hooks/useForm';
import Select from '../../components/form/Select';
import NumberInput from '../../components/form/NumberInput';
import SubmitButton from '../../components/form/SubmitButton';

const seasonEnumChoice = [
	{ value: '', label: '' },
	{ value: 'summer', label: 'Été' },
	{ value: 'winter', label: 'Hiver' },
];

const seasonChoice = {
	'': '',
	summer: 'Été',
	winter: 'Hiver',
};

const genreEnumChoice = [
	{ value: '', label: '' },
	{ value: 'male', label: 'Homme' },
	{ value: 'female', label: 'Femme' },
];

const genreChoice = {
	'': '',
	male: 'Homme',
	female: 'Femme',
};

const sizeEnumChoice = [
	{ value: '', label: '' },
	{ value: 'xs', label: 'XS' },
	{ value: 's', label: 'S' },
	{ value: 'm', label: 'M' },
	{ value: 'l', label: 'L' },
	{ value: 'xl', label: 'XL' },
	{ value: '2xl', label: '2XL' },
	{ value: '3xl', label: '3XL' },
];

function getGenreLabel(value: string) {
	const genre = genreEnumChoice.find((s) => s.value === value);
	return genre ? genre.label : '';
}

function getSeasonLabel(value: string) {
	const season = seasonEnumChoice.find((s) => s.value === value);
	return season ? season.label : '';
}

function getSizeLabel(value: string) {
	const size = sizeEnumChoice.find((s) => s.value === value);
	return size ? size.label : '';
}

const useFakeMutation = () => {
	return React.useCallback(
		(user: Partial<StockEntry>) =>
			new Promise<Partial<StockEntry>>((resolve, reject) => {
				setTimeout(() => {
					if (user.type?.trim() === '') {
						reject(new Error("Error while saving user: name can't be empty."));
					} else {
						resolve({
							...user,
							available: user.quantity - user.inMission - user.inCleaning - user.outOfService,
						});
					}
				}, 200);
			}),
		[],
	);
};

const Logistics = () => {
	const [reloadToken, setReloadToken] = useState(0);
	const [currentRow, setCurrentRow] = useState<number>(0);
	const [currentRowOpen, setCurrentRowOpen] = useState<boolean>(false);
	const [openNewRow, setOpenNewRow] = useState<boolean>(false);
	const form = useForm({ url: routes.logistics.stocks.create, init: new StockEntry() });
	const triggerReload = () => setReloadToken(reloadToken + 1);
	const [snackbar, setSnackbar] = React.useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);
	const [associationId, setAssociationId] = useState<number>();
	const [, tricoStocks] = useFetch<StockEntry[]>(routes.logistics.stocks.getStocksTable({ associationId }), [
		associationId,
		reloadToken,
	]);
	const mutateRow = useFakeMutation();

	useEffect(() => {
		const resetSuccess = () => {
			form.resetSuccess();
		};
		if (form.success) {
			setTimeout(() => {
				resetSuccess();
			}, 2000);
		}
	}, [form.success, form]);

	const handleCloseSnackbar = () => setSnackbar(null);

	const processRowUpdate = React.useCallback(
		async (newRow: GridRowModel) => {
			const ret = await post(routes.logistics.stocks.editRow, newRow);
			const response = await mutateRow(newRow);
			if (!ret?.id) {
				setSnackbar({ children: 'Erreur', severity: 'error' });
			} else {
				setSnackbar({ children: 'Stock enregistré', severity: 'success' });
			}
			return response;
		},
		[mutateRow],
	);

	const handleProcessRowUpdateError = React.useCallback((error: Error) => {
		setSnackbar({ children: error.message, severity: 'error' });
	}, []);

	// this is just for visual render of the button, the modal is not in UserActions
	const UsersActions = ({ params }: any) => {
		return (
			<div
				onClick={() => {
					setCurrentRow(params.id);
					setCurrentRowOpen(true);
				}}
				style={{ width: '100%', height: '100%', textAlign: 'center', marginTop: '25px' }}
			>
				<DeleteButton mini colorInherit />
			</div>
		);
	};

	const deleteRowAndReload = async () => {
		const ret = await del(routes.logistics.stocks.delete({ id: currentRow }));
		if (ret?.ok) {
			setCurrentRowOpen(false);
			triggerReload();
		}
	};

	const columns: GridColDef[] = [
		// { field: 'id', headerName: 'Id', width: 15, editable: false },
		{ field: 'type', headerName: 'Type', type: 'string', editable: true },
		{
			field: 'gender',
			headerName: 'Genre',
			type: 'singleSelect',
			width: 80,
			editable: true,
			valueOptions: genreEnumChoice,
			valueFormatter: (params) => getGenreLabel(params.value),
		},
		{
			field: 'size',
			headerName: 'Taille',
			type: 'singleSelect',
			editable: true,
			width: 60,
			valueOptions: sizeEnumChoice,
			valueFormatter: (params) => getSizeLabel(params.value),
		},
		{ field: 'quantity', headerName: 'Quantité', type: 'number', editable: true, width: 70 },
		{ field: 'inMission', headerName: 'En Mission', type: 'number', editable: true },
		{ field: 'inCleaning', headerName: 'Lavage', type: 'number', editable: true, width: 70 },
		{ field: 'outOfService', headerName: 'HS', type: 'number', editable: true, width: 70 },
		{ field: 'available', headerName: 'Disponible', type: 'number', editable: false, width: 80 },
		{
			field: 'season',
			headerName: 'Saison',
			type: 'singleSelect',
			editable: true,
			valueOptions: seasonEnumChoice,
			valueFormatter: (params) => getSeasonLabel(params.value),
		},
		{
			field: 'association',
			headerName: 'Association',
			type: 'string',
			editable: false,
			width: 190,
			valueFormatter: (params) => params?.value?.name ?? 'Trico',
		},
		{
			field: '',
			headerName: 'Supprimer',
			type: 'actions',
			editable: false,
			renderCell: (params) => <UsersActions {...{ params }} />,
		},
	];

	return (
		<Box p={3}>
			<Modal
				open={openNewRow}
				onClose={() => {
					setOpenNewRow(false);
					triggerReload();
				}}
			>
				<p>Création d'un nouvel élément</p>
				<form noValidate onSubmit={form.submit}>
					<div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
						<TextInput label="Type" {...form.field('type')} />
						<Select values={genreChoice} label="Genre" {...form.field('gender')} />
						<Select values={ClothingSizes} label="Taille" {...form.field('size')} />
						<NumberInput
							label="Quantité"
							{...form.field('quantity')}
							fullWidth
							width={350}
							variant="outlined"
						/>
						<Select values={seasonChoice} label="Saison" {...form.field('season')} />
						<AssociationSelect {...form.field('associationId')} tricoOption notAll required />
					</div>
					<Box display="flex" justifyContent="center" mt={2}>
						<SubmitButton submitting={form.isSubmitting} success={form.success}>
							Créer l'élément
						</SubmitButton>
					</Box>
				</form>
			</Modal>
			<Modal open={currentRowOpen} onClose={() => setCurrentRowOpen(false)}>
				<p>Êtes vous sur de vouloir effectuer cette suppression ?</p>
				<Box display="flex" justifyContent="space-between">
					<Button onClick={() => setCurrentRowOpen(false)}>Annuler</Button>
					<Button variant="contained" color="primary" onClick={deleteRowAndReload}>
						Confirmer
					</Button>
				</Box>
			</Modal>
			<Box display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center">
				<Breadcrumbs aria-label="breadcrumb">
					<Typography color="textPrimary">Gestion des stocks</Typography>
				</Breadcrumbs>
				<Button size="small" variant="contained" color="primary" onClick={() => setOpenNewRow(true)}>
					<Box mt={0.5}>
						<Add />
					</Box>
					Nouveau
				</Button>
			</Box>

			<Box display="flex" py={2}>
				<AssociationSelect
					value={associationId}
					onChange={(value) => setAssociationId(value)}
					fullWidth
					width="min(400px, 80vw)"
					tricoOption
				/>
			</Box>

			<Title>Liste des stocks</Title>
			<div style={{ height: '150vh', width: '100%', marginBottom: 30 }}>
				<DataGrid
					rows={tricoStocks ?? []}
					columns={columns}
					processRowUpdate={processRowUpdate}
					onProcessRowUpdateError={handleProcessRowUpdateError}
					localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
				/>
				{!!snackbar && (
					<Snackbar
						open
						anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
						onClose={handleCloseSnackbar}
						autoHideDuration={6000}
					>
						<Alert {...snackbar} onClose={handleCloseSnackbar} />
					</Snackbar>
				)}
			</div>
		</Box>
	);
};

export default Logistics;
