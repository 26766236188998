import React from "react";
import { Typography, Box, BoxProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

type TitleProps = {
	children?: any,
	big?: boolean,
	small?: boolean;
	className?: string,
	style?: React.CSSProperties
} & BoxProps;
const Title = ({ children, big, small, style, className, ...props }: TitleProps) => {
	const classes = useStyles({ big: big ?? false, small: small ?? false });
	const classNames = [classes.title];
	if (className) classNames.push(className);
	return <Box {...props}>
		<Typography className={classNames.join(' ')}>
			{children}
		</Typography>
	</Box>
}

const useStyles = makeStyles({
	title: {
		fontVariant: "small-caps",
		color: "#222",
		fontSize: (props: { big: boolean, small: boolean }) => props.small ? 15 : (props.big ? 22 : 18),
		margin: (props: { big: boolean, small: boolean }) => props.big ? "10px 0px" : undefined
	}
});

export default Title;