import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import {
	Box,
	CircularProgress,
	makeStyles,
	useTheme,
	useMediaQuery,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	IconButton,
} from '@material-ui/core';
import TabletAndroidIcon from '@material-ui/icons/TabletAndroid';
import { RemoveCircle, AccountCircle } from '@material-ui/icons';
import { useParams, useHistory } from 'react-router-dom';
import routes from '../../api/routes';
import { post } from '../../api';
import { PositionsInfos } from '../../dto/user';
import { MissionUserDto } from '../../dto/user';
import { formatNumber } from '../../utils';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AddUserAutocomplete from './AddUserAutocomplete';

type MissionRecruitersTableProps = {
	users: MissionUserDto[];
	setUsers: Dispatch<SetStateAction<MissionUserDto[]>>;
	areUsersLoading: boolean;
};

const isUserDisabled = (user) => user?.trialPeriodEndType && new Date(user?.trialPeriodEndDate) <= new Date();

const MissionRecruitersTable = ({ users, setUsers, areUsersLoading }: MissionRecruitersTableProps) => {
	const theme = useTheme();
	const history = useHistory();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));
	const { id = '0' } = useParams<{ id: string }>();

	const [tmpUsers, setTmpUsers] = useState([]);

	useEffect(() => {
		setTmpUsers([
			...users.filter((user) => !isUserDisabled(user)),
			...users.filter((user) => isUserDisabled(user)),
		]);
	}, [users]);

	const [sortTable, setSortTable] = useState({});

	const classes = useStyles({ border: false });

	const sortUserBy = (key: string) => {
		const sort = (nb: number) => {
			setTmpUsers(
				[...users].sort((a, b) => {
					if (a[key] > b[key]) {
						return 1 * nb;
					} else if (a[key] < b[key]) {
						return -1 * nb;
					}
					return 0;
				}),
			);
		};

		if (!sortTable[key]) {
			setSortTable({ [key]: 'ASC' });
			sort(1);
		} else if (sortTable[key] === 'ASC') {
			setSortTable({ [key]: 'DESC' });
			sort(-1);
		} else {
			delete sortTable[key];
			setSortTable({});
			setTmpUsers([...users]);
		}
	};

	return (
		<Box>
			<AddUserAutocomplete
				actifOnly
				callback={(userToAdd, setUserToAdd, setIsLoading) => {
					if (users.some(({ id }) => id === userToAdd.id)) {
						setUserToAdd(null);
					} else {
						setIsLoading(true);
						post(routes.missions.users.add({ id }), { userId: userToAdd.id }).then((response) => {
							if (!response.errors) {
								setUserToAdd(null);
								setUsers([response, ...users]);
								setIsLoading(false);
							}
						});
					}
				}}
			/>

			{/* TABLE */}
			<Box
				my={0.5}
				style={{
					overflow: 'auto',
					maxWidth: isBigScreen ? 'calc(100vw - 380px)' : 'calc(100vw - 20px)',
					border: '1px solid #CCC',
					borderRadius: '3px',
				}}
			>
				<Table size="medium" classes={{ root: classes.tableRoot }}>
					<TableHead>
						<TableRow>
							<TableCell style={{ borderRight: '1px solid #CCC', position: 'relative' }}>
								Nom
								<IconButton size="small" style={{ marginLeft: 10 }}></IconButton>
							</TableCell>
							{[
								{
									name: 'Role',
									value: 'position',
								},
								{
									name: 'Nbr de TR',
									value: 'trCount',
								},
								{
									name: 'Absence',
									value: 'abscence',
								},
								{
									name: 'Hr de Rue',
									value: 'streetHoursTotal',
								},
								{
									name: 'BS Réel',
									value: 'completedDonationsCount',
								},
								{
									name: 'Taux de transformation',
									value: 'transformationRate',
								},
								{
									name: 'Taux réel',
									value: 'realRate',
								},
								{
									name: 'Don Moyen',
									value: 'averageDonation',
								},
								{
									name: '%18/30',
									value: 'between18And30Rate',
								},
								{
									name: 'Age Médian',
									value: 'medianAge',
								},
								{
									name: 'Matricule',
									value: 'tricoId',
								},
								{
									name: 'Poste',
									value: 'grade',
								},
								{
									name: 'Taille de Vêtement',
									value: 'clothingSize',
								},
							].map((cell, i) => {
								return (
									<TableCell
										style={{
											cursor: 'pointer',
										}}
										onClick={() => sortUserBy(cell.value)}
										key={i}
									>
										{cell.name}{' '}
										{sortTable[cell.value] && (
											<ArrowDownwardIcon
												style={{
													fontSize: 14,
													transition: 'transform 0.3s',
													transform:
														sortTable[cell.value] === 'ASC' ? 'rotate(180deg)' : 'rotate(0deg)',
												}}
												fontSize="small"
											/>
										)}
									</TableCell>
								);
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{areUsersLoading ? (
							<Box display="flex" justifyContent="center" my={8} width="100%">
								<CircularProgress />
							</Box>
						) : (
							tmpUsers.map((row, i) => (
								<TableRow
									key={row.id}
									style={{
										cursor: 'pointer',
										backgroundColor: isUserDisabled(row) ? '#c4c4c4' : 'undefined',
									}}
									onClick={() => history.push(`/missions/${id}/recruteur/${row.id}`)}
								>
									<TableCell component="th" scope="row" style={{ borderRight: '1px solid #CCC' }}>
										<Box display="flex" justifyContent="space-between">
											<Box display="flex" alignItems="center">
												<Box mr={1}>
													{row.avatarPath ? (
														<Box
															style={{
																borderRadius: 3,
																height: 25,
																width: 25,
																background: `center / cover no-repeat url(${row.avatarPath})`,
															}}
														/>
													) : (
														<AccountCircle />
													)}
												</Box>
												{row.firstName} {row.lastName}
												{row.tabletId ? (
													''
												) : (
													<Box
														position="relative"
														ml={1}
														style={{ color: '#F34' }}
														title={`${row.firstName} n'a pas de tablette !`}
													>
														<TabletAndroidIcon fontSize="small" />
														<Box
															position="absolute"
															top={2}
															left={9}
															style={{ fontSize: 10, fontWeight: 'bold' }}
														>
															!
														</Box>
													</Box>
												)}
											</Box>
											<Box ml={1} style={{ color: '#F34', cursor: 'pointer' }}>
												<RemoveCircle
													onClick={(e) => {
														e.stopPropagation();
														post(routes.missions.users.remove({ id: +id }), {
															userId: row.id,
														}).then(() => setUsers(users.filter((user) => user.id !== row.id)));
													}}
												/>
											</Box>
										</Box>
									</TableCell>
									<TableCell title={PositionsInfos[row?.position]?.label} style={{ cursor: 'help' }}>
										{row.position}
									</TableCell>
									<TableCell>{formatNumber(row.trCount, 2)}</TableCell>
									{/* <TableCell>{row.usedTrCount}</TableCell> */}
									<TableCell>{formatNumber(row.absence, 2)}</TableCell>
									<TableCell>{formatNumber(row.streetHoursTotal, 2)}</TableCell>
									<TableCell>{formatNumber(row.completedDonationsCount, 2)}</TableCell>
									<TableCell>{formatNumber(row.transformationRate)} %</TableCell>
									<TableCell>{formatNumber(row.realRate, 2)}</TableCell>
									<TableCell>{formatNumber(row.averageDonation, 2)}</TableCell>
									<TableCell>{formatNumber(row.between18And30Rate)} %</TableCell>
									<TableCell>
										{row.medianAge ? `${formatNumber(row.medianAge, 2)} ans` : '-'}
									</TableCell>
									<TableCell>{row.tricoId}</TableCell>
									<TableCell>{row.grade}</TableCell>
									<TableCell>{(row.clothingSize || '').toUpperCase()}</TableCell>
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</Box>
		</Box>
	);
};

const useStyles = makeStyles({
	paper: {
		margin: 5,
		padding: '20px 10px',
		flexGrow: 1,
		display: 'flex',
		alignItems: 'center',
		flexFlow: 'column',
		boxSizing: 'border-box',
	},
	tableRoot: {
		whiteSpace: 'nowrap',
		'& thead th': {
			textTransform: 'uppercase',
			color: '#444',
			fontSize: 11.5,
		},
		'& tbody td': {},
		'& tbody tr:hover': {
			backgroundColor: '#FAFAFA',
			boxShadow: 'inset 3px 0px 0px 0px #0093EE',
		},
	},
});

export default MissionRecruitersTable;
