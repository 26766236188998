import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReturnButton from '../../components/form/ReturnButton';
import { Box } from '@material-ui/core';
import routes from '../../api/routes';
import { MissionWithAssociationDto } from '../../dto/mission';
import { MissionUserDto } from '../../dto/user';
import useFetch from '../../hooks/useFetch';
import { sortUsersMission } from '../missions/MissionDetails';
import moment from 'moment';
import Title from '../../components/Title';
import {
	Page,
	Document,
	Text,
	View,
	StyleSheet,
	PDFDownloadLink,
	BlobProvider,
	Image,
} from '@react-pdf/renderer';
import PrimaryButton from '../../components/PrimaryButton';
import { PictureAsPdf } from '@material-ui/icons';
import LightUsersMissionTable from './LightUsersMissionTable';

const styles = StyleSheet.create({
	page: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		padding: 20,
	},
	badge: {
		width: '31.5%',
		height: '31.5%',
		padding: 2,
		borderWidth: 1, //remove this later
		borderColor: 'black', //remove this later
		margin: 3,
		textAlign: 'center',
		fontSize: 9,
	},
	image: {
		width: '100%',
		height: 80,
		objectFit: 'contain',
		marginBottom: 5,
	},
	logo: {
		width: '100%',
		height: 50,
		objectFit: 'contain',
		marginTop: 5,
		marginBottom: 10,
	},
	boldText: {
		fontWeight: 700,
	},
});

const Badge = ({
	user,
	logo,
	dateRange,
	badgeText,
}: {
	user: MissionUserDto;
	logo: string;
	dateRange: string;
	badgeText: string;
}) => (
	<View style={styles.badge}>
		<Image
			style={styles.logo}
			src={{
				uri: logo,
				method: 'GET',
				headers: { 'Cache-Control': 'no-cache' },
				body: '',
			}}
		/>
		<Image
			style={styles.image}
			src={{
				uri: user?.avatarPath,
				method: 'GET',
				headers: { 'Cache-Control': 'no-cache' },
				body: '',
			}}
		/>
		<Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 10 }}>
			{user?.gender === 'female' ? 'Mme.' : 'M.'} {user?.firstName} {user?.lastName}
		</Text>
		<Text>
			{badgeText} {dateRange}
		</Text>
		<Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 10 }}>NI CHEQUE, NI ESPECE MERCI</Text>
	</View>
);

const BadgesDocument = ({
	users,
	logo,
	dateRange,
	badgeText,
}: {
	users: MissionUserDto[];
	logo: string;
	dateRange: string;
	badgeText: string;
}) => (
	<Document>
		<Page size="A4" style={styles.page}>
			{users.map((user, index) => (
				<Badge key={index} user={user} logo={logo} dateRange={dateRange} badgeText={badgeText} />
			))}
		</Page>
	</Document>
);

const Badges = () => {
	const { id = '0' } = useParams<{ id: string }>();
	let [isLoading, mission] = useFetch<MissionWithAssociationDto>(routes.missions.get({ id: +id }));
	let [isLogoLoading, logo] = useFetch<{ logo: string; badgeText: string }>(
		routes.missions.logo({ id: +id }),
	);
	const [users, setUsers] = useState<MissionUserDto[]>([]);
	const [areUsersLoading] = useFetch<MissionUserDto[]>(routes.missions.users.all({ id }), [], (users) =>
		setUsers(sortUsersMission(users)),
	);
	const [pdfBlobUrl, setPdfBlobUrl] = useState('');
	const [blob, setBlob] = useState(null);

	useEffect(() => {
		if (blob) {
			const url = URL.createObjectURL(blob);
			setPdfBlobUrl(url);
		}
	}, [blob]);

	const dateRange =
		'du ' +
		moment(mission?.startDate).format('DD MMMM YYYY') +
		' au ' +
		moment(mission?.endDate).format('DD MMMM YYYY.');
	if (isLoading || areUsersLoading || isLogoLoading) return <div>Loading...</div>;
	return (
		<Box px={4} pb={4} pt={1}>
			<div style={{ justifyContent: 'space-between', display: 'flex' }}>
				<ReturnButton to={'/logistique/badges'}>Retour aux missions</ReturnButton>
				<PDFDownloadLink
					document={
						users && (
							<BadgesDocument
								users={users}
								logo={logo?.logo}
								dateRange={dateRange}
								badgeText={mission?.association?.badgeText}
							/>
						)
					}
					fileName={'BADGES-' + mission?.tricoCode + '.pdf'}
				>
					<PrimaryButton size="small" style={{ padding: '6px 0px', marginTop: 5 }}>
						<PictureAsPdf />
					</PrimaryButton>
				</PDFDownloadLink>
			</div>
			<Title>
				Mission <b>{mission?.tricoCode}</b> du <b>{moment(mission?.startDate).format('DD/MM/YYYY')}</b> au{' '}
				<b>{moment(mission?.endDate).format('DD/MM/YYYY')}</b>
			</Title>

			<h1>PDF Preview</h1>
			<BlobProvider
				document={
					<BadgesDocument
						users={users}
						logo={logo?.logo}
						dateRange={dateRange}
						badgeText={mission?.association?.badgeText}
					/>
				}
			>
				{({ blob, url, loading, error }) => {
					if (!loading && !error) {
						setBlob(blob);
					}
					return (
						<>
							{loading && <p>Loading PDF...</p>}
							{error && <p>Error generating PDF: {error.message}</p>}
						</>
					);
				}}
			</BlobProvider>
			{pdfBlobUrl && (
				<iframe
					src={pdfBlobUrl}
					title="PDF Preview"
					width="100%"
					height="600px"
					style={{ border: 'none', marginBottom: 25, borderRadius: 4 }}
				/>
			)}
			<LightUsersMissionTable users={users} areUsersLoading={areUsersLoading} />
		</Box>
	);
};
export default Badges;
