import React from 'react';
import { Box } from "@material-ui/core";
import AssociationForm from "../../components/associations/AssociationForm";
import routes from '../../api/routes';
import { AssociationDto } from '../../dto/association';

const CreateAssociation = () => {
	const url = routes.associations.create;
	return <Box px={4} py={0} position="relative" display="flex" flexDirection="column" alignItems="center" mb={8}>
		<AssociationForm init={new AssociationDto()} url={url} />
	</Box>
};

export default CreateAssociation;