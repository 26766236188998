import { Box, Chip, CircularProgress, Paper, styled } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import routes from '../../api/routes';
import { Card, CardContainer } from '../../components/Cards';
import DatePicker from '../../components/form/DatePicker';
import DeleteButton from '../../components/form/DeleteButton';
import ReturnButton from '../../components/form/ReturnButton';
import Select from '../../components/form/Select';
import SubmitButton from '../../components/form/SubmitButton';
import Switch from '../../components/form/Switch';
import TextInput from '../../components/form/TextInput';
import MissionsTable from '../../components/missions/MissionsTable';
import PrimaryButton from '../../components/PrimaryButton';
import Title from '../../components/Title';
import { GradeEvolutionDto } from '../../dto/grade';
import { Nationalities } from '../../dto/nationalities';
import { RolesAbbreviations } from '../../dto/role';
import { TrainingDto } from '../../dto/training';
import { States, UserDto, UserStatsDto } from '../../dto/user';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import { FileDto } from '../../dto/file';
import PDFTable from '../../components/files/PDFTable';

const UserDetails = () => {
	const { id = '0' } = useParams<{ id: string }>();
	const [isLoading, user] = useFetch<UserDto>(routes.users.get({ id }));
	const [areStatsLoading, stats] = useFetch<UserStatsDto>(routes.users.stats({ id }));
	const [hoursStatsYear, setHoursStatsYear] = useState(moment().year());
	const years = {};
	for (let year = moment().year(); year >= 2018; year--) {
		years[year] = year;
	}
	const paidHoursThisYear = stats?.paidHours?.filter(({ year }) => year === hoursStatsYear)?.[0] || {
		totalPaidHours: 0,
		year: hoursStatsYear,
	};
	const [userCopy, setUserCopy] = useState<UserDto>(user);

	useEffect(() => {
		setUserCopy(user);
	}, [user]);

	const hasContract =
		!isLoading &&
		!(
			moment() < moment(user.contractStartDate || '2500-01-01') ||
			moment(user.contractEndDate || "'1980-01-01") < moment()
		);
	const hasContractSince = hasContract ? moment().diff(moment(user.contractStartDate), 'days') : 0;
	const lastContractDuration =
		user?.contractStartDate && user?.contractEndDate && moment(user?.contractEndDate) < moment()
			? moment(user?.contractEndDate).diff(moment(user?.contractStartDate), 'days')
			: 0;

	const [, gradeEvolutions] = useFetch<GradeEvolutionDto[]>(
		routes.users.grades.evolutions({ userId: id }),
		[],
	);

	const [trainings, setTrainings] = useState<TrainingDto[]>([]);
	useFetch<TrainingDto[]>(routes.users.training.all({ id }), [], (trainings) => setTrainings(trainings));

	const [refresh, setRefresh] = useState(0);
	const refreshData = () => setRefresh(refresh + 1);
	let [isFilesLoading, files] = useFetch<FileDto[]>(
		routes.files.all(
			{},
			{
				userId: +id,
			},
		),
		[refresh],
	);

	const trainingForm = useForm({
		init: new TrainingDto(id),
		url: routes.users.training.create({ id }),
		onSuccess: (training, form) => {
			setTrainings([training, ...trainings]);
			form.clear();
		},
	});

	const stateForm = useForm({
		values: userCopy ?? user,
		url: routes.users.edit({ id: user?.id.toString() }),
		onChange: (values) => setUserCopy(values),
	});

	return (
		<Box p={2}>
			<Box display="flex" justifyContent="space-between" flexWrap="wrap">
				<ReturnButton to={`/ressources-humaines`}>Retour au personnel</ReturnButton>
				<Box display="flex" alignItems="center">
					<Box mr={1}>
						<Link to={`/ressources-humaines/modifier/${id}`}>
							<PrimaryButton size="small">
								<Edit />
							</PrimaryButton>
						</Link>
					</Box>
					<Box>
						<DeleteButton
							size="small"
							url={routes.users.delete({ id })}
							confirmationMessage="Êtes vous sûr de vouloir supprimer cet utilisateur ?"
							successMessage="L'utilisateur a bien été supprimé !"
							returnLabel="Retour à la page 'ressources humaines'"
							returnUrl={`/ressources-humaines`}
						/>
					</Box>
				</Box>
			</Box>

			{!isLoading && !areStatsLoading && (
				<Box>
					<Box display="flex" justifyContent="space-between" maxWidth={900} flexWrap="wrap">
						<Box display="flex">
							<Box
								style={{
									borderRadius: 3,
									height: 100,
									width: 100,
									background: `#DDD center / cover no-repeat url(${user?.avatar?.path})`,
								}}
							/>
							<Box px={2}>
								<Box p={0.5}>
									{user.firstName} {user.lastName}
								</Box>
								<Detail uppercase>
									{hasContract ? (
										<>
											<Dot />
											En contrat actuellement{' '}
										</>
									) : (
										<>
											<Dot color="#ce482d" />
											Pas de contrat en cours
										</>
									)}
								</Detail>
								<Box display="flex">
									<Box>
										<Detail>Email : {user.email} </Detail>
										<Detail>Adresse postale : {user.address.address || '-'} </Detail>
										<Detail>IBAN : {user.rib || '-'} </Detail>
										<Detail>Numéro de sécurité sociale : {user.socialSecurity || '-'} </Detail>
										<Detail>
											Nationalité :{' '}
											{(user.nationalityPassport &&
												Nationalities[user.nationalityPassport].nationality) ||
												'-'}
										</Detail>
									</Box>
									<Box>
										<Detail>Téléphone : {user.phoneNumber || '-'} </Detail>
										<Box display="flex">
											<Detail>Code postal : {user.address.zipCode || '-'} </Detail>
											<Detail>Ville : {user.address.city || '-'} </Detail>
										</Box>
										<Detail>BIC : {user.bic || '-'} </Detail>
										<Detail>Ville de naissance : {user.birthCity || '-'} </Detail>
										<Detail>Code postal de naissance : {user.birthZipCode || '-'} </Detail>
									</Box>
								</Box>
							</Box>
						</Box>
						<Box>
							<Detail uppercase>{stats.missions.length > 0 ? 'En mission' : 'Pas de missions'}</Detail>
							{stats.missions.map((mission) => (
								<Box
									key={mission.id}
									style={{ display: 'block', fontSize: 15, fontWeight: 'bold', color: '#338CFF' }}
								>
									{mission.tricoCode}
								</Box>
							))}
						</Box>
					</Box>
					<Box py={1}>
						<Chip
							variant="outlined"
							color="primary"
							size="small"
							label={RolesAbbreviations[user.role]}
						/>
					</Box>
					<Box>
						<form
							onSubmit={() => {
								stateForm.submit();
							}}
						>
							<Select
								values={States}
								label="Statut"
								required
								width={200}
								{...stateForm.field('state')}
							/>
							{stateForm.field('state').value !== user.state && (
								<SubmitButton
									submitting={stateForm.isSubmitting}
									success={stateForm.success}
									edit
									style={{ marginTop: 10, marginLeft: 10 }}
									onClick={() => console.log(false)}
								>
									Confirmer le nouveau statut du salarié
								</SubmitButton>
							)}
						</form>
					</Box>

					{!isFilesLoading && files.length ? <PDFTable files={files} refreshData={refreshData} /> : null}

					<CardContainer>
						{areStatsLoading ? (
							<CircularProgress />
						) : (
							<>
								<Card title="absences">{stats.totalAbsent}</Card>
								<Card title="retards">{stats.totalLate}</Card>
								<Card title="Carence à effectuer">
									{hasContract
										? Math.ceil(hasContractSince / 3)
										: Math.ceil(lastContractDuration / 3)}{' '}
									jours
								</Card>
								<Card title="Sous contrat depuis">
									{hasContract ? hasContractSince + ' jours' : 'Pas de contrat en cours'}
								</Card>
							</>
						)}
					</CardContainer>
					<Detail uppercase>Heures payées cumulées par année</Detail>
					<Select values={years} value={hoursStatsYear} onChange={setHoursStatsYear} width={200} />
					<Box py={1} style={{ fontSize: 18, fontWeight: 'bold', color: '#338CFF' }}>
						{paidHoursThisYear.totalPaidHours}h
					</Box>

					<Detail uppercase>Évolution du poste</Detail>
					{gradeEvolutions?.map((grade, i) => (
						<Box mt={0.5} style={{ fontWeight: i === 0 ? 'bold' : 'normal' }} key={i}>
							{moment(grade.date).format('DD/MM/YYYY')} - {grade.grade.name}
							<DeleteButton
								size="small"
								mini={true}
								marginLeft="10px"
								url={routes.users.grades.deleteEvolution({ id: grade.id })}
								confirmationMessage="Êtes vous sûr de vouloir supprimer cette évolution ?"
								successMessage="L'évolution a bien été supprimée !"
								returnLabel="Retour à la page de l'utilisateur."
								onSuccess={() => window.location.reload()}
							/>
						</Box>
					))}
					{!!gradeEvolutions && gradeEvolutions.length === 0 && <Box>Pas d'évolution</Box>}

					<Box mt={2} display="flex">
						<Box>
							<Detail uppercase>Mobilité</Detail>
							<Switch value={false} disabled />
						</Box>
						<Box>
							<Detail uppercase>Permis</Detail>
							<Switch value={user.isDriver} disabled />
						</Box>
					</Box>

					<Box mr={1}>
						<Title mt={3}>Cooptation</Title>
						<Box mr={1}>
							{!user?.sponsor ? (
								'Pas de référent'
							) : (
								<>
									Référent :&nbsp;
									<a href={`/ressources-humaines/${user.sponsor?.id}`}>
										{user.sponsor?.firstName} {user.sponsor?.lastName}
									</a>
								</>
							)}
						</Box>
						<Box mr={1}>
							{!user?.sponsees?.length ? (
								'Pas de filleul'
							) : (
								<div>
									Filleuls :&nbsp;
									{user.sponsees.map(({ id, firstName, lastName }, i) => (
										<span key={i}>
											<a href={`/ressources-humaines/${id}`}>
												{firstName} {lastName}
											</a>
											{i !== user.sponsees.length - 1 && ', '}
										</span>
									))}
								</div>
							)}
						</Box>
					</Box>

					<Title mt={3}>Formation</Title>
					<form noValidate onSubmit={trainingForm.submit}>
						<Box display="flex" alignItems="center" style={{ margin: -8 }} py={1}>
							<DatePicker
								format="DD/MM/YYYY"
								label="Date de la formation"
								width={350}
								flexGrow={1}
								margin={8}
								{...trainingForm.field('date')}
							/>
							<TextInput
								{...trainingForm.field('name')}
								label="Formation"
								width={350}
								flexGrow={1}
								style={{ margin: 8 }}
							/>
						</Box>
						<Box display="flex" justifyContent="end">
							<SubmitButton submitting={trainingForm.isSubmitting} success={trainingForm.success}>
								Ajouter
							</SubmitButton>
						</Box>
					</form>

					<Box>
						{trainings.map((training) => (
							<Box key={training.id} display="flex" alignItems="center" p={1}>
								{moment(training.date).format('DD/MM/YYYY')} - {training.name}
								<Box ml={1}>
									<DeleteButton
										mini
										round
										url={routes.users.training.delete({ id, trainingId: training.id })}
										confirmationMessage="Voulez-vous vraiment supprimer cette formation ?"
										successMessage="La formation a bien été supprimée."
										onSuccess={() => setTrainings(trainings.filter(({ id }) => id !== training.id))}
									/>
								</Box>
							</Box>
						))}
					</Box>

					<Title my={2}>Commentaire</Title>
					<Paper variant="outlined">
						<Box p={2} minHeight="100">
							{user.comment || 'Pas de commentaire'}
						</Box>
					</Paper>

					<Title my={2}>Commentaire de troisième mission</Title>
					<Paper variant="outlined">
						<Box p={2} minHeight="100">
							{user.thirdMissionComment || 'Pas de commentaire'}
						</Box>
					</Paper>
				</Box>
			)}

			<Title mt={3}>Missions</Title>
			<MissionsTable userId={id} />
		</Box>
	);
};

const Detail = styled(Box)({
	fontSize: (props: { uppercase?: boolean }) => (props.uppercase ? 10 : 12),
	color: '#999',
	textTransform: (props: { uppercase?: boolean }) => (props.uppercase ? 'uppercase' : undefined),
	padding: 4,
});

const Dot = styled(Box)({
	marginRight: '10px',
	display: 'inline-block',
	width: 10,
	height: 10,
	borderRadius: 10,
	background: ({ color = '#4ECE2D' }: { color?: string }) => color,
});

export default UserDetails;
