import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import routes from '../../api/routes';
import ProgramForm from '../../components/associations/ProgramForm';
import DeleteButton from '../../components/form/DeleteButton';
import { AssociationDto } from '../../dto/association';
import { ProgramDto } from '../../dto/program';
import useFetch from '../../hooks/useFetch';

const EditProgram = () => {
	const { id, programId } = useParams<{ id: string; programId: string }>();
	const [isAssociationLoading, association] = useFetch<AssociationDto>(routes.associations.get({ id: +id }));
	const [isProgramLoading, program] = useFetch<ProgramDto>(routes.programs.get({ id: +programId }));
	const url = routes.programs.edit({ id: +programId });

	return (
		<Box px={4} py={0} position="relative" display="flex" flexDirection="column" alignItems="center" mb={8}>
			{isProgramLoading ? (
				<Box my={10}>
					<CircularProgress />
				</Box>
			) : (
				<ProgramForm
					edit
					init={program}
					url={url}
					associationId={+id}
					icon={
						<Box display="flex" justifyContent="center" alignItems="center" py={4}>
							{isAssociationLoading ? (
								<CircularProgress />
							) : (
								<img
									src={association?.image?.path}
									style={{ maxHeight: 200 }}
									alt={association.name}
								/>
							)}
						</Box>
					}
				>
					<Box py={1} mr={1}>
						<DeleteButton
							url={routes.programs.delete({ id: +programId })}
							confirmationMessage="Êtes vous sûr de vouloir supprimer le programme ?"
							successMessage="Le programme a bien été supprimé !"
							returnLabel="Retour à l'association"
							returnUrl={`/associations/${id}`}
						>
							Supprimer le programme
						</DeleteButton>
					</Box>
				</ProgramForm>
			)}
		</Box>
	);
};

export default EditProgram;
