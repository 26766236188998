import React from 'react';
import {
	Box,
	CircularProgress,
	makeStyles,
	useTheme,
	useMediaQuery,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	IconButton,
} from '@material-ui/core';
import { SmartStock } from './StocksMission';

type LightStocksMissionTableProps = {
	areStocksLoading: boolean;
	smartStock: SmartStock[];
};

const LightStocksMissionTable = ({ smartStock, areStocksLoading }: LightStocksMissionTableProps) => {
	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));
	const classes = useStyles({ border: false });

	return (
		<Box
			my={0.5}
			style={{
				overflow: 'auto',
				maxWidth: isBigScreen ? 'calc(100vw - 380px)' : 'calc(100vw - 20px)',
				border: '1px solid #CCC',
				borderRadius: '3px',
			}}
		>
			<Table size="medium" classes={{ root: classes.tableRoot }}>
				<TableHead>
					<TableRow>
						<TableCell style={{ borderRight: '1px solid #CCC', position: 'relative' }}>
							Nom
							<IconButton size="small" style={{ marginLeft: 10 }}></IconButton>
						</TableCell>
						<TableCell>Association</TableCell>
						<TableCell>Disponible</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{areStocksLoading ? (
						<Box display="flex" justifyContent="center" my={8} width="100%">
							<CircularProgress />
						</Box>
					) : (
						smartStock.map((row, i) => (
							<TableRow key={row.id}>
								<TableCell style={{ borderRight: '1px solid #CCC', position: 'relative' }}>
									{row.name} {row.stockEntry[0].gender} {row.stockEntry[0].season}
								</TableCell>
								<TableCell>{row.stockEntry[0]?.associationId}</TableCell>
								<TableCell>{row.name}</TableCell>
							</TableRow>
						))
					)}
				</TableBody>
			</Table>
		</Box>
	);
};

const useStyles = makeStyles({
	paper: {
		margin: 5,
		padding: '20px 10px',
		flexGrow: 1,
		display: 'flex',
		alignItems: 'center',
		flexFlow: 'column',
		boxSizing: 'border-box',
	},
	tableRoot: {
		whiteSpace: 'nowrap',
		'& thead th': {
			textTransform: 'uppercase',
			color: '#444',
			fontSize: 11.5,
		},
		'& tbody td': {},
		'& tbody tr:hover': {
			backgroundColor: '#FAFAFA',
			boxShadow: 'inset 3px 0px 0px 0px #0093EE',
		},
	},
});

export default LightStocksMissionTable;
