import { AppBar, Box, IconButton, Link, Toolbar } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Menu as MenuIcon } from '@material-ui/icons';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const TopBar = ({ showMenu }: { showMenu: () => any }) => {
	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));
	const classes = useStyles({ border: false });
	const { user, logout } = useAuth();

	return (
		<AppBar classes={{ root: classes.appbar }} position="static" color="transparent">
			<Toolbar>
				{!isBigScreen && (
					<IconButton
						onClick={showMenu}
						edge="start"
						className={classes.menuButton}
						color="inherit"
						aria-label="menu"
					>
						<MenuIcon />
					</IconButton>
				)}
				<Box display="flex" justifyContent="center" alignItems="center" width="100%">
					<RouterLink to="/">
						<img
							src={user.client && user.client.logoPath ? user.client.logoPath : '/images/trico.png'}
							style={{ height: 60, cursor: 'pointer' }}
							alt="trico"
						/>
					</RouterLink>
				</Box>
				{isBigScreen && (
					<Box>
						<Link style={{ cursor: 'pointer' }} onClick={logout}>
							Déconnexion
						</Link>
					</Box>
				)}
			</Toolbar>
		</AppBar>
	);
};

const useStyles = makeStyles((theme) => ({
	menuButton: {
		marginRight: theme.spacing(2),
		position: 'absolute',
		left: 20,
	},
	title: {
		flexGrow: 1,
	},
	appbar: {
		boxShadow: 'none',
		borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
	},
}));

export default TopBar;
