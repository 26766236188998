import {
	Box,
	CircularProgress,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetApp from '@material-ui/icons/GetApp';
import { isValid as isIBANValid } from 'iban';
import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import routes from '../../api/routes';
import {
	DonationAmountDto,
	DonationBankingDetailsDto,
	DonationStatusDto,
	DonationStatusLabels,
	DonorCallDto,
	DonorCallStatusLabels,
	DonationGradesDto,
	DonationPersonalDetailsDto,
} from '../../dto/donation';
import { Genders, Titles } from '../../dto/user';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import { isIBANInSEPA } from '../../utils/iban';
import { DatePickerProps, default as DefaultDatePicker } from '../form/DatePicker';
import DateTimePicker from '../form/DateTimePicker';
import DeleteButton from '../form/DeleteButton';
import Select from '../form/Select';
import SubmitButton from '../form/SubmitButton';
import TextInput from '../form/TextInput';
import PrimaryButton from '../PrimaryButton';
import Title from '../Title';
import DonationCommunication from './DonationCommunication';
import SendAgainWelcomeEmail from './SendAgainWelcomeEmail';
import SendAgainAsso from './SendAgainAsso';

type DonationDetailsProps = {
	donationId: number;
	expandedSection?: string;
	reload?: () => void;
	exit?: () => void;
};

const DonationDetails = ({ donationId, expandedSection, reload, exit }: DonationDetailsProps) => {
	const classes = useDonationDetailsStyles();
	const tableClasses = useStyles({ border: false });
	const [callsVersion, setCallsVersion] = useState(0); //Used to force reload of "calls" after adding one

	let bankingForm = useForm({
		init: new DonationBankingDetailsDto(),
		url: routes.donations.updateBankingDetails({ donationId }),
		validation: (values, addError) => {
			if (!isIBANValid(values['rib'])) {
				addError('rib', "L'IBAN est invalide");
			}
			if (!isIBANInSEPA(values['rib'] || '')) {
				addError('rib', "L'iban doit être dans la zone SEPA");
			}
		},
	});

	let amountForm = useForm({
		init: new DonationAmountDto(),
		url: routes.donations.updateDonationAmount({ donationId }),
	});

	let statusForm = useForm({
		init: new DonationStatusDto(),
		url: routes.donations.updateDonationStatus({ donationId }),
	});

	const donationForm = useForm({
		init: new DonationPersonalDetailsDto(),
		url: routes.donations.updateDonationPersonalDetails({ donationId }),
	});

	const gradeForm = useForm({
		init: new DonationGradesDto(),
		url: routes.donations.updateGrade({ donationId }),
	});

	let [isDonationLoading, donation] = useFetch(routes.donations.get({ id: donationId }), [], (donation) => {
		bankingForm.setValues({ rib: donation?.rib, bic: donation?.bic });
		amountForm.setValues({ monthlyDonation: donation?.monthlyDonation });
		statusForm.setValues({ status: donation.status });
		gradeForm.setValues({ grade: donation?.grade });
		donationForm.setValues({
			title: donation?.title,
			gender: donation?.gender,
			email: donation?.email,
			firstName: donation?.firstName,
			lastName: donation?.lastName,
			birthdate: donation?.birthdate,
			phoneNumber: donation?.phoneNumber,
			streetNumber: donation?.streetNumber,
			street: donation?.street,
			addressDetails: donation?.addressDetails,
			city: donation?.city,
			zipCode: donation?.zipCode,
		});
	});

	let [areCallsLoading, calls] = useFetch(routes.donations.calls.forDonation({ id: donationId }), [
		callsVersion,
	]);
	donation = donation ?? {};
	let form = useForm({
		init: new DonorCallDto(),
		url: routes.donations.calls.add({ id: donationId }),
		onSuccess: () => {
			setCallsVersion(callsVersion + 1);
			form.clear();
		},
	});

	if (isDonationLoading) {
		return (
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				minHeight="min(400px, 80vh)"
				minWidth="min(400px, 80vw)"
			>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<div className={classes.root}>
			<Box display="grid" gridTemplateColumns="1fr auto 1fr" marginBottom="20px">
				<Box gridColumn="2/3">
					<Title>
						Bulletin {donation.firstName} {donation.lastName}
					</Title>
				</Box>
				<Box marginLeft="auto">
					<Link to={{ pathname: routes.associations.getPdf({}, { donationId }) }} target="_blank">
						<PrimaryButton style={{ marginRight: 10 }} mini variant="contained">
							<GetApp style={{ fontSize: 14 }} />
						</PrimaryButton>
					</Link>
					<DeleteButton
						mini
						url={routes.donations.delete({ id: donationId })}
						onSuccess={() => {
							exit?.();
							reload?.();
						}}
					/>
				</Box>
			</Box>

			<SendAgainWelcomeEmail donation={donation} />
			{
				donation && donation?.displayedStatus === 'transmitted' &&
				<SendAgainAsso donation={donation} />	
			}

			<ExpansionPanel className={classes.panel}>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary}>
					<Typography>Statut du bulletin</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<form noValidate onSubmit={statusForm.submit}>
						<Select
							{...statusForm.field('status')}
							fullWidth
							values={DonationStatusLabels}
							label="Statut"
						/>
						<Box mt={2} display="flex" justifyContent="end">
							<SubmitButton
								submitting={statusForm.isSubmitting}
								success={statusForm.success}
								edit
								className={classes.submitButton}
							>
								Enregistrer
							</SubmitButton>
						</Box>
					</form>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<ExpansionPanel
				className={classes.panel}
				defaultExpanded={expandedSection === 'personnal_information'}
			>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary}>
					<Typography>Coordonnées personnelles</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<form noValidate onSubmit={donationForm.submit}>
						<Box mb={2}>
							<Select fullWidth values={Titles} {...donationForm.field('title')} label="Civilité" />
						</Box>
						<Box mb={2}>
							<Select fullWidth values={Genders} {...donationForm.field('gender')} label="Sexe" />
						</Box>
						<Box mb={2}>
							<TextInput fullWidth {...donationForm.field('lastName')} label="Nom" />
						</Box>
						<Box mb={2}>
							<TextInput fullWidth {...donationForm.field('firstName')} label="Prénom" />
						</Box>
						<Box mb={2}>
							<TextInput fullWidth {...donationForm.field('phoneNumber')} label="Téléphone" />
						</Box>
						<Box mb={2}>
							<DatePicker {...donationForm.field('birthdate')} label="Date de naissance" />
						</Box>
						<Box mb={2}>
							<TextInput fullWidth {...donationForm.field('streetNumber')} label="Numéro de rue" />
						</Box>
						<Box mb={2}>
							<TextInput fullWidth {...donationForm.field('street')} label="Rue" />
						</Box>
						<Box mb={2}>
							<TextInput
								fullWidth
								{...donationForm.field('addressDetails')}
								label="Complément d'adresse"
							/>
						</Box>
						<Box mb={2}>
							<TextInput fullWidth {...donationForm.field('city')} label="Ville" />
						</Box>
						<Box mb={2}>
							<TextInput fullWidth {...donationForm.field('zipCode')} label="Code postal" />
						</Box>
						<Box mb={2}>
							<TextInput fullWidth {...donationForm.field('email')} label="Email" />
						</Box>
						<Box mt={2} display="flex !important">
							<SubmitButton
								submitting={donationForm.isSubmitting}
								success={donationForm.success}
								edit
								className={classes.submitButton}
							>
								Enregistrer
							</SubmitButton>
						</Box>
					</form>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<DonationCommunication donation={donation} />

			<ExpansionPanel className={classes.panel}>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary}>
					<Typography>Le don</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<form noValidate onSubmit={amountForm.submit}>
						<Box mb={2}>
							<TextInput fullWidth {...amountForm.field('monthlyDonation')} label="Montant du don" />
						</Box>
						<Select
							fullWidth
							values={{
								[donation.donationDay]:
									donation.donationDay + (donation.donationDay === '1' ? 'er' : 'ème') + ' du mois',
							}}
							value={donation.donationDay}
							label="Jour du don"
						/>
						<Box mt={2} display="flex" justifyContent="end">
							<SubmitButton
								submitting={amountForm.isSubmitting}
								success={amountForm.success}
								edit
								className={classes.submitButton}
							>
								Enregistrer
							</SubmitButton>
						</Box>
					</form>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<ExpansionPanel className={classes.panel}>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary}>
					<Typography>Coordonnées bancaires</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<form noValidate onSubmit={bankingForm.submit}>
						<TextInput fullWidth {...bankingForm.field('rib')} label="IBAN" />
						<Box py={2}>
							<TextInput fullWidth {...bankingForm.field('bic')} label="BIC" />
						</Box>
						<Box display="flex" justifyContent="end">
							<SubmitButton
								submitting={bankingForm.isSubmitting}
								success={bankingForm.success}
								edit
								className={classes.submitButton}
							>
								Enregistrer
							</SubmitButton>
						</Box>
					</form>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<ExpansionPanel className={classes.panel} defaultExpanded={expandedSection === 'calls'}>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary}>
					<Typography>Historique des appels</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<Box width="100%" style={{ color: '#B54', fontWeight: 'bold', margin: '0px 20px' }}>
						{3 - calls?.length ?? '?'} appels restants
					</Box>
					{!areCallsLoading ? (
						<Paper variant="outlined">
							<Table size="medium" classes={{ root: tableClasses.tableRoot }}>
								<TableHead>
									<TableRow>
										<TableCell>Début</TableCell>
										<TableCell>Fin</TableCell>
										<TableCell>Status</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{calls &&
										calls.map((row, i) => (
											<React.Fragment>
												<TableRow key={row.id} className={row.comment ? 'noBottomBorder' : ''}>
													<TableCell align="left">
														{moment(row.datetime).format('DD/MM/YYYY')}
													</TableCell>
													<TableCell align="left">
														{moment(row.datetime).format('HH:mm')}
													</TableCell>
													<TableCell align="left">{DonorCallStatusLabels[row.status]}</TableCell>
												</TableRow>
												{row.comment && (
													<TableRow className="comment">
														<TableCell colSpan={3} style={{ whiteSpace: 'pre' }}>
															{row.comment}
														</TableCell>
													</TableRow>
												)}
											</React.Fragment>
										))}
								</TableBody>
							</Table>
						</Paper>
					) : (
						<CircularProgress />
					)}
					<Box width="100%" my={2}>
						<Title small mb={1}>
							Retours sur un rendez-vous
						</Title>
						<Box display="flex" width="100%" my={1}>
							<DateTimePicker
								{...form.field('callDatetime')}
								fullWidth
								label="Date et heure"
								required
							/>
						</Box>
						<Box display="flex" width="100%">
							<TextInput
								rows={4}
								fullWidth
								multiline
								label="Commentaire"
								{...form.field('comment')}
								value={form.values.comment || ''}
							/>
						</Box>

						{(
							[
								{ label: 'À répondu', background: '#4ECE3D', color: 'white', status: 'responded' },
								{
									label: "Je n'ai pas eu de réponse",
									background: '#E0ECF6',
									color: '#444',
									status: 'no_response',
								},
								{
									label: 'Annuler le dossier',
									background: '#FF5858',
									color: 'white',
									status: 'cancelled',
								},
							] as const
						).map(({ label, background, color, status }) => (
							<Box my={1}>
								<SubmitButton
									icon={false}
									style={{ width: '100%', background, color }}
									success={form.success && gradeForm.success}
									submitting={form.isSubmitting && gradeForm.isSubmitting}
									variant="contained"
									onClick={() => {
										form.submit(null, { status });
										gradeForm.submit();
									}}
								>
									{label}
								</SubmitButton>
							</Box>
						))}
					</Box>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</div>
	);
};

const useDonationDetailsStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	panel: {
		'&::before': {
			backgroundColor: 'white',
		},
	},
	panelSummary: { backgroundColor: '#E0ECF6', border: '1 px solid red' },
	panelDetails: {
		marginTop: '20px',
		display: 'block',
		width: '100%',
		boxSizing: 'border-box',
		'& > *': {
			display: 'block',
			width: '100%',
			marginTop: '20px',
			flexGrow: 1,
		},
		'& > *:first-child': {
			margin: '0px !important',
		},
	},
	submitButton: {
		margin: 'auto',
	},
}));

const useStyles = makeStyles(({ isBigScreen }: { isBigScreen?: boolean }) => ({
	tabs: {
		marginBottom: -1,
		'& button': {
			borderColor: '#CCC',
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
			fontSize: 12,
			padding: '10px 15px',
		},
	},
	tableRoot: {
		whiteSpace: 'nowrap',
		'& thead th': {
			textTransform: 'uppercase',
			color: '#444',
			fontSize: 11.5,
		},
		'& tbody tr.noBottomBorder > td': {
			borderBottom: 'none',
		},
		'& tbody tr:not(.comment):hover': {
			backgroundColor: '#FAFAFA',
			boxShadow: 'inset 3px 0px 0px 0px #0093EE',
		},
	},
}));

export default DonationDetails;

const DatePicker = (props: DatePickerProps) => {
	return <DefaultDatePicker {...props} format="DD/MM/YYYY" width={'100%'} flexGrow={1} />;
};
