import React, { useState } from 'react';
import { Box, Paper, makeStyles, useTheme } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

type MissionCommentsProps = {
	midMissionComment: string;
	endMissionComment: string;
};

const MissionComments = ({ midMissionComment, endMissionComment }: MissionCommentsProps) => {
	let theme = useTheme();
	let classes = useStyles({ border: false });
	let [tab, setTab] = useState(0);
	return (
		<Box width="100%">
			<Box mt={2} className={classes.tabs}>
				<Box mr={1} display="flex">
					<ToggleButtonGroup
						style={{ display: 'flex', flexWrap: 'wrap' }}
						exclusive
						value={tab}
						onChange={(e, v) => setTab(v)}
					>
						<ToggleButton value={0}>Mi-mission</ToggleButton>
						<ToggleButton value={1}>Fin mission</ToggleButton>
					</ToggleButtonGroup>
				</Box>
			</Box>
			<Paper variant="outlined">
				<SwipeableViews
					axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={tab}
					onChangeIndex={(e, nv) => setTab(nv)}
				>
					<Box p={2}>{midMissionComment || 'Pas de commentaire.'}</Box>
					<Box p={2}>{endMissionComment || 'Pas de commentaire.'}</Box>
				</SwipeableViews>
			</Paper>
		</Box>
	);
};

const useStyles = makeStyles({
	tabs: {
		marginBottom: -1,
		'& button': {
			borderColor: '#CCC',
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
			marginRight: -1,
			fontSize: 12,
			padding: '10px 15px',
		},
	},
});

export default MissionComments;
