const urlFor = code => `https://restcountries.eu/data/${code.toLowerCase()}.svg`;
export const Nationalities = {
	"FRA": {
		"name": "France",
		"isInEU": true,
		"nationality": "Française" 
	},
	"AFG": { "name": "Afghanistan", "isInEU": false, "nationality": "Afghan" },
	"ALA": { "name": "Åland", "isInEU": true, "nationality": "Alandais" },
	"ALB": { "name": "Albanie", "isInEU": false, "nationality": "Albanais" },
	"DZA": { "name": "Algérie", "isInEU": false, "nationality": "Algérien" },
	"ASM": { "name": "Samoa américaines", "isInEU": false, "nationality": "Samoan" },
	"AND": { "name": "Andorre", "isInEU": false, "nationality": "Andorran" },
	"AGO": { "name": "Angola", "isInEU": false, "nationality": "Angolais" },
	"AIA": { "name": "Anguilla", "isInEU": false, "nationality": "Anguillien" },
	"ATA": { "name": "Antarctique", "isInEU": false, "nationality": "Antarctique" },
	"ATG": { "name": "Antigua-et-Barbuda", "isInEU": false, "nationality": "Antiguais" },
	"ARG": { "name": "Argentine", "isInEU": false, "nationality": "Argentin" },
	"ARM": { "name": "Arménie", "isInEU": false, "nationality": "Arménien" },
	"ABW": { "name": "Aruba", "isInEU": false, "nationality": "Arubais" },
	"AUS": { "name": "Australie", "isInEU": false, "nationality": "Australien" },
	"AUT": { "name": "Autriche", "isInEU": true, "nationality": "Autrichien" },
	"AZE": { "name": "Azerbaïdjan", "isInEU": false, "nationality": "Azerbaïdjanais" },
	"BHS": { "name": "Bahamas", "isInEU": false, "nationality": "Bahamien" },
	"BHR": { "name": "Bahreïn", "isInEU": false, "nationality": "Bahreïni" },
	"BGD": { "name": "Bangladesh", "nationality": "Bangladais", "isInEU": false, },
	"BRB": { "name": "Barbade", "nationality": "Barbadien", "isInEU": false, },
	"BLR": { "name": "Biélorussie", "nationality": "Biélorusse", "isInEU": false, },
	"BEL": { "name": "Belgique", "nationality": "Belge", "isInEU": true, },
	"BLZ": { "name": "Belize", "nationality": "Belizien", "isInEU": false, },
	"BEN": { "name": "Bénin", "nationality": "Béninois", "isInEU": false, },
	"BMU": { "name": "Bermudes", "nationality": "Bermudien", "isInEU": false, },
	"BTN": { "name": "Bhoutan", "nationality": "Bhoutanais", "isInEU": false, },
	"BOL": { "name": "Bolivie", "nationality": "Bolivien", "isInEU": false, },
	"BES": { "name": "Bonaire, Saint-Eustache et Saba", "nationality": "Bonairien", "isInEU": false, },
	"BIH": { "name": "Bosnie-Herzégovine", "nationality": "Bosnien", "isInEU": false, },
	"BWA": { "name": "Botswana", "nationality": "Botswanais", "isInEU": false, },
	"BVT": { "name": "Île Bouvet", "nationality": "Bouvetien", "isInEU": false, },
	"BRA": { "name": "Brésil", "nationality": "Brésilien", "isInEU": false, },
	"IOT": { "name": "Territoire britannique de l'océan Indien", "nationality": "Britannique", "isInEU": false, },
	"UMI": { "name": "Îles mineures éloignées des États-Unis", "nationality": "Américain", "isInEU": false, },
	"VGB": { "name": "Îles Vierges britanniques", "nationality": "Britannique", "isInEU": false, },
	"VIR": { "name": "Îles Vierges des États-Unis", "nationality": "Américain", "isInEU": false, },
	"BRN": { "name": "Brunei", "nationality": "Brunéien", "isInEU": false, },
	"BGR": { "name": "Bulgarie", "nationality": "Bulgare", "isInEU": true, },
	"BFA": { "name": "Burkina Faso", "nationality": "Burkinabé", "isInEU": false, },
	"BDI": { "name": "Burundi", "nationality": "Burundais", "isInEU": false, },
	"KHM": { "name": "Cambodge", "nationality": "Cambodgien", "isInEU": false, },
	"CMR": { "name": "Cameroun", "nationality": "Camerounais", "isInEU": false, },
	"CAN": { "name": "Canada", "nationality": "Canadien", "isInEU": false, },
	"CPV": { "name": "Cap Vert", "nationality": "Cap-verdien", "isInEU": false, },
	"CYM": { "name": "Îles Caïmans", "nationality": "Caïmanais", "isInEU": false, },
	"CAF": { "name": "République centrafricaine", "nationality": "Centrafricain", "isInEU": false, },
	"TCD": { "name": "Tchad", "nationality": "Tchadien", "isInEU": false, },
	"CHL": { "name": "Chili", "nationality": "Chilien", "isInEU": false, },
	"CHN": { "name": "Chine", "nationality": "Chinois", "isInEU": false, },
	"CXR": { "name": "Île Christmas", "nationality": "Christmasien", "isInEU": false, },
	"CCK": { "name": "Îles Cocos", "nationality": "Cocosien", "isInEU": false, },
	"COL": { "name": "Colombie", "nationality": "Colombien", "isInEU": false, },
	"COM": { "name": "Comores", "nationality": "Comorien", "isInEU": false, },
	"COG": { "name": "Congo", "nationality": "Congolais", "isInEU": false, },
	"COD": { "name": "Congo (Rép. dém.)", "nationality": "Congolais", "isInEU": false, },
	"COK": { "name": "Îles Cook", "nationality": "Cookien", "isInEU": false, },
	"CRI": { "name": "Costa Rica", "nationality": "Costaricien", "isInEU": false, },
	"HRV": { "name": "Croatie", "nationality": "Croate", "isInEU": true, },
	"CUB": { "name": "Cuba", "nationality": "Cubain", "isInEU": false, },
	"CUW": { "name": "Curaçao", "nationality": "CuracaÏen", "isInEU": false, },
	"CYP": { "name": "Chypre", "nationality": "Chypriote", "isInEU": true, },
	"CZE": { "name": "République tchèque", "nationality": "Tchèque", "isInEU": true, },
	"DNK": { "name": "Danemark", "nationality": "Danois", "isInEU": true, },
	"DJI": { "name": "Djibouti", "nationality": "Djiboutien", "isInEU": false, },
	"DMA": { "name": "Dominique", "nationality": "Dominiquais", "isInEU": false, },
	"DOM": { "name": "République dominicaine", "nationality": "Dominicain", "isInEU": false, },
	"ECU": { "name": "Equateur", "nationality": "Équatorien", "isInEU": false, },
	"EGY": { "name": "Égypte", "nationality": "Égyptien", "isInEU": false, },
	"SLV": { "name": "Salvador", "nationality": "Salvadorien", "isInEU": false, },
	"GNQ": { "name": "Guinée équatoriale", "nationality": "Équato-guinéen", "isInEU": false, },
	"ERI": { "name": "Érythrée", "nationality": "Érythréen", "isInEU": false, },
	"EST": { "name": "Estonie", "nationality": "Estonien", "isInEU": true, },
	"ETH": { "name": "Éthiopie", "nationality": "Éthiopien", "isInEU": false, },
	"FLK": { "name": "Îles Malouines", "nationality": "Malouin", "isInEU": false, },
	"FRO": { "name": "Îles Féroé", "nationality": "Féroïen", "isInEU": false, },
	"FJI": { "name": "Fidji", "nationality": "Fidjien", "isInEU": false, },
	"FIN": { "name": "Finlande", "nationality": "Finlandais", "isInEU": true, },
	"GUF": { "name": "Guyane française", "nationality": "Guyanais", "isInEU": false, },
	"PYF": { "name": "Polynésie française", "nationality": "Polynésien", "isInEU": false, },
	"ATF": { "name": "Terres australes et antarctiques françaises", "nationality": "Français", "isInEU": false, },
	"GAB": { "name": "Gabon", "nationality": "Gabonais", "isInEU": false, },
	"GMB": { "name": "Gambie", "nationality": "Gambien", "isInEU": false, },
	"GEO": { "name": "Géorgie", "nationality": "Géorgien", "isInEU": false, },
	"DEU": { "name": "Allemagne", "nationality": "Allemand", "isInEU": true, },
	"GHA": { "name": "Ghana", "nationality": "Ghanéen", "isInEU": false, },
	"GIB": { "name": "Gibraltar", "nationality": "Gibraltarien", "isInEU": false, },
	"GRC": { "name": "Grèce", "nationality": "Grec", "isInEU": true, },
	"GRL": { "name": "Groenland", "nationality": "Groenlandais", "isInEU": false, },
	"GRD": { "name": "Grenade", "nationality": "Grenadin", "isInEU": false, },
	"GLP": { "name": "Guadeloupe", "nationality": "Guadeloupéen", "isInEU": false, },
	"GUM": { "name": "Guam", "nationality": "Guamien", "isInEU": false, },
	"GTM": { "name": "Guatemala", "nationality": "Guatémaltèque", "isInEU": false, },
	"GGY": { "name": "Guernesey", "nationality": "Guernesiais", "isInEU": false, },
	"GIN": { "name": "Guinée", "nationality": "Guinéen", "isInEU": false, },
	"GNB": { "name": "Guinée-Bissau", "nationality": "Guinéen", "isInEU": false, },
	"GUY": { "name": "Guyana", "nationality": "Guyanais", "isInEU": false, },
	"HTI": { "name": "Haïti", "nationality": "Haïtien", "isInEU": false, },
	"HMD": { "name": "Îles Heard et McDonald", "nationality": "Heardais", "isInEU": false, },
	"VAT": { "name": "Cité du Vatican", "nationality": "Vatican", "isInEU": false, },
	"HND": { "name": "Honduras", "nationality": "Hondurien", "isInEU": false, },
	"HKG": { "name": "Hong Kong", "nationality": "Hongkongais", "isInEU": false, },
	"HUN": { "name": "Hongrie", "nationality": "Hongrois", "isInEU": true, },
	"ISL": { "name": "Islande", "nationality": "Islandais", "isInEU": false, },
	"IND": { "name": "Inde", "nationality": "Indien", "isInEU": false, },
	"IDN": { "name": "Indonésie", "nationality": "Indonésien", "isInEU": false, },
	"CIV": { "name": "Côte d'Ivoire", "nationality": "Ivoirien", "isInEU": false, },
	"IRN": { "name": "Iran", "nationality": "Iranien", "isInEU": false, },
	"IRQ": { "name": "Irak", "nationality": "Irakien", "isInEU": false, },
	"IRL": { "name": "Irlande", "nationality": "Irlandais", "isInEU": true, },
	"IMN": { "name": "Île de Man", "nationality": "Manx", "isInEU": false, },
	"ISR": { "name": "Israël", "nationality": "Israélien", "isInEU": false, },
	"ITA": { "name": "Italie", "nationality": "Italien", "isInEU": true, },
	"JAM": { "name": "Jamaïque", "nationality": "Jamaïcain", "isInEU": false, },
	"JPN": { "name": "Japon", "nationality": "Japonais", "isInEU": false, },
	"JEY": { "name": "Jersey", "nationality": "Jersey", "isInEU": false, },
	"JOR": { "name": "Jordanie", "nationality": "Jordanien", "isInEU": false, },
	"KAZ": { "name": "Kazakhstan", "nationality": "Kazakh", "isInEU": false, },
	"KEN": { "name": "Kenya", "nationality": "Kenyan", "isInEU": false, },
	"KIR": { "name": "Kiribati", "nationality": "Kiribati", "isInEU": false, },
	"KWT": { "name": "Koweït", "nationality": "Koweïtien", "isInEU": false, },
	"KGZ": { "name": "Kirghizistan", "nationality": "Kirghize", "isInEU": false, },
	"LAO": { "name": "Laos", "nationality": "Laotien", "isInEU": false, },
	"LVA": { "name": "Lettonie", "nationality": "Letton", "isInEU": true, },
	"LBN": { "name": "Liban", "nationality": "Libanais", "isInEU": false, },
	"LSO": { "name": "Lesotho", "nationality": "Lesothan", "isInEU": false, },
	"LBR": { "name": "Liberia", "nationality": "Libérien", "isInEU": false, },
	"LBY": { "name": "Libye", "nationality": "Libyen", "isInEU": false, },
	"LIE": { "name": "Liechtenstein", "nationality": "Liechtensteinois", "isInEU": false, },
	"LTU": { "name": "Lituanie", "nationality": "Lituanien", "isInEU": true, },
	"LUX": { "name": "Luxembourg", "nationality": "Luxembourgeois", "isInEU": true, },
	"MAC": { "name": "Macao", "nationality": "Macanais", "isInEU": false, },
	"MKD": { "name": "Macédoine du Nord", "nationality": "Macédonien", "isInEU": false, },
	"MDG": { "name": "Madagascar", "nationality": "Malgache", "isInEU": false, },
	"MWI": { "name": "Malawi", "nationality": "Malawien", "isInEU": false, },
	"MYS": { "name": "Malaisie", "nationality": "Malaisien", "isInEU": false, },
	"MDV": { "name": "Maldives", "nationality": "Maldivien", "isInEU": false, },
	"MLI": { "name": "Mali", "nationality": "Mali", "isInEU": false, },
	"MLT": { "name": "Malte", "nationality": "Maltais", "isInEU": true, },
	"MHL": { "name": "Îles Marshall", "nationality": "Marshallais", "isInEU": false, },
	"MTQ": { "name": "Martinique", "nationality": "Martiniquaise", "isInEU": false, },
	"MRT": { "name": "Mauritanie", "nationality": "Mauritanien", "isInEU": false, },
	"MUS": { "name": "Maurice", "nationality": "Mauricien", "isInEU": false, },
	"MYT": { "name": "Mayotte", "nationality": "Mayottais", "isInEU": false, },
	"MEX": { "name": "Mexique", "nationality": "Mexicain", "isInEU": false, },
	"FSM": { "name": "Micronésie", "nationality": "Micronésien", "isInEU": false, },
	"MDA": { "name": "Moldavie", "nationality": "Moldave", "isInEU": false, },
	"MCO": { "name": "Monaco", "nationality": "Monégasque", "isInEU": false, },
	"MNG": { "name": "Mongolie", "nationality": "Mongol", "isInEU": false, },
	"MNE": { "name": "Monténégro", "nationality": "Monténégrin", "isInEU": false, },
	"MSR": { "name": "Montserrat", "nationality": "Montserratien", "isInEU": false, },
	"MAR": { "name": "Maroc", "nationality": "Marocain", "isInEU": false, },
	"MOZ": { "name": "Mozambique", "nationality": "Mozambicain", "isInEU": false, },
	"MMR": { "name": "Birmanie", "nationality": "Birman", "isInEU": false, },
	"NAM": { "name": "Namibie", "nationality": "Namibien", "isInEU": false, },
	"NRU": { "name": "Nauru", "nationality": "Nauruan", "isInEU": false, },
	"NPL": { "name": "Népal", "nationality": "Népalais", "isInEU": false, },
	"NLD": { "name": "Pays-Bas", "nationality": "Néerlandais", "isInEU": true, },
	"NCL": { "name": "Nouvelle-Calédonie", "nationality": "Calédonien", "isInEU": false, },
	"NZL": { "name": "Nouvelle-Zélande", "nationality": "Néo-Zélandais", "isInEU": false, },
	"NIC": { "name": "Nicaragua", "nationality": "Nicaraguayen", "isInEU": false, },
	"NER": { "name": "Niger", "nationality": "Nigérien", "isInEU": false, },
	"NGA": { "name": "Nigéria", "nationality": "Nigérian", "isInEU": false, },
	"NIU": { "name": "Niue", "nationality": "Niuan", "isInEU": false, },
	"NFK": { "name": "Île Norfolk", "nationality": "Norfolkais", "isInEU": false, },
	"PRK": { "name": "Corée du Nord", "nationality": "Nord-coréen", "isInEU": false, },
	"MNP": { "name": "Îles Mariannes du Nord", "nationality": "Mariannais", "isInEU": false, },
	"NOR": { "name": "Norvège", "nationality": "Norvégien", "isInEU": false, },
	"OMN": { "name": "Oman", "nationality": "Omanais", "isInEU": false, },
	"PAK": { "name": "Pakistan", "nationality": "Pakistanais", "isInEU": false, },
	"PLW": { "name": "Palaos", "nationality": "Palaosien", "isInEU": false, },
	"PSE": { "name": "Territoire palestinien", "nationality": "Palestinien", "isInEU": false, },
	"PAN": { "name": "Panama", "nationality": "Panaméen", "isInEU": false, },
	"PNG": { "name": "Papouasie-Nouvelle-Guinée", "nationality": "Papouan", "isInEU": false, },
	"PRY": { "name": "Paraguay", "nationality": "Paraguayen", "isInEU": false, },
	"PER": { "name": "Pérou", "nationality": "Péruvien", "isInEU": false, },
	"PHL": { "name": "Philippines", "nationality": "Philippin", "isInEU": false, },
	"PCN": { "name": "Îles Pitcairn", "nationality": "Pitcairnais", "isInEU": false, },
	"POL": { "name": "Pologne", "nationality": "Polonais", "isInEU": true, },
	"PRT": { "name": "Portugal", "nationality": "Portugais", "isInEU": true, },
	"PRI": { "name": "Porto Rico", "nationality": "Portoricain", "isInEU": false, },
	"QAT": { "name": "Qatar", "nationality": "Qatari", "isInEU": false, },
	"KOS": { "name": "Republic of Kosovo", "nationality": "Kosovar", "isInEU": false, },
	"REU": { "name": "Réunion", "nationality": "Réunionnais", "isInEU": false, },
	"ROU": { "name": "Roumanie", "nationality": "Roumain", "isInEU": true, },
	"RUS": { "name": "Russie", "nationality": "Russe", "isInEU": false, },
	"RWA": { "name": "Rwanda", "nationality": "Rwandais", "isInEU": false, },
	"BLM": { "name": "Saint-Barthélemy", "nationality": "Barthélémois", "isInEU": false, },
	"SHN": { "name": "Sainte-Hélène", "nationality": "Hélénien", "isInEU": false, },
	"KNA": { "name": "Saint-Christophe-et-Niévès", "nationality": "Saint-Christophais", "isInEU": false, },
	"LCA": { "name": "Saint-Lucie", "nationality": "Saint-Luciais", "isInEU": false, },
	"MAF": { "name": "Saint-Martin", "nationality": "Saint-Martinois", "isInEU": false, },
	"SPM": { "name": "Saint-Pierre-et-Miquelon", "nationality": "Saint-Pierrais", "isInEU": false, },
	"VCT": { "name": "Saint-Vincent-et-les-Grenadines", "nationality": "Saint-Vincentais", "isInEU": false, },
	"WSM": { "name": "Samoa", "nationality": "Samoan", "isInEU": false, },
	"SMR": { "name": "Saint-Marin", "nationality": "Saint-Marinais", "isInEU": false, },
	"STP": { "name": "Sao Tomé-et-Principe", "nationality": "Sao-toméen", "isInEU": false, },
	"SAU": { "name": "Arabie Saoudite", "nationality": "Saoudien", "isInEU": false, },
	"SEN": { "name": "Sénégal", "nationality": "Sénégalais", "isInEU": false, },
	"SRB": { "name": "Serbie", "nationality": "Serbe", "isInEU": false, },
	"SYC": { "name": "Seychelles", "nationality": "Seychellois", "isInEU": false, },
	"SLE": { "name": "Sierra Leone", "nationality": "Sierraléonais", "isInEU": false, },
	"SGP": { "name": "Singapour", "nationality": "Singapourien", "isInEU": false, },
	"SXM": { "name": "Saint-Martin", "nationality": "Saint-Martinois", "isInEU": false, },
	"SVK": { "name": "Slovaquie", "nationality": "Slovaque", "isInEU": true, },
	"SVN": { "name": "Slovénie", "nationality": "Slovène", "isInEU": true, },
	"SLB": { "name": "Salomon", "nationality": "Salomon", "isInEU": false, },
	"SOM": { "name": "Somalie", "nationality": "Somalien", "isInEU": false, },
	"ZAF": { "name": "Afrique du Sud", "nationality": "Sud-africain", "isInEU": false, },
	"SGS": { "name": "Géorgie du Sud-et-les Îles Sandwich du Sud", "nationality": "Sud-georgien", "isInEU": false, },
	"KOR": { "name": "Corée du Sud", "nationality": "Sud-coréen", "isInEU": false, },
	"SSD": { "name": "Soudan du Sud", "nationality": "Soudanais", "isInEU": false, },
	"ESP": { "name": "Espagne", "nationality": "Espagnol", "isInEU": true, },
	"LKA": { "name": "Sri Lanka", "nationality": "Sri-lankais", "isInEU": false, },
	"SDN": { "name": "Soudan", "nationality": "Soudanais", "isInEU": false, },
	"SUR": { "name": "Surinam", "nationality": "Surinamien", "isInEU": false, },
	"SJM": { "name": "Svalbard et Jan Mayen", "nationality": "Svalbardi", "isInEU": false, },
	"SWZ": { "name": "Swaziland", "nationality": "Swazi", "isInEU": false, },
	"SWE": { "name": "Suède", "nationality": "Suédois", "isInEU": true, },
	"CHE": { "name": "Suisse", "nationality": "Suisse", "isInEU": false, },
	"SYR": { "name": "Syrie", "nationality": "Syrien", "isInEU": false, },
	"TWN": { "name": "Taïwan", "nationality": "Taiwanais", "isInEU": false, },
	"TJK": { "name": "Tadjikistan", "nationality": "Tadjik", "isInEU": false, },
	"TZA": { "name": "Tanzanie", "nationality": "Tanzanien", "isInEU": false, },
	"THA": { "name": "Thaïlande", "nationality": "Thaï", "isInEU": false, },
	"TLS": { "name": "Timor oriental", "nationality": "Timorais", "isInEU": false, },
	"TGO": { "name": "Togo", "nationality": "Togolais", "isInEU": false, },
	"TKL": { "name": "Tokelau", "nationality": "Tokelauen", "isInEU": false, },	
	"TON": { "name": "Tonga", "nationality": "Tongien", "isInEU": false, },
	"TTO": { "name": "Trinité et Tobago", "nationality": "Trinidadien", "isInEU": false, },
	"TUN": { "name": "Tunisie", "nationality": "Tunisien", "isInEU": false, },
	"TUR": { "name": "Turquie", "nationality": "Turc", "isInEU": false, },
	"TKM": { "name": "Turkménistan", "nationality": "Turkmène", "isInEU": false, },
	"TCA": { "name": "Îles Turques-et-Caïques", "nationality": "Turques-et-Caïques", "isInEU": false, },
	"TUV": { "name": "Tuvalu", "nationality": "Tuvaluan", "isInEU": false, },
	"UGA": { "name": "Uganda", "nationality": "Ougandais", "isInEU": false, },
	"UKR": { "name": "Ukraine", "nationality": "Ukrainien", "isInEU": false, },
	"ARE": { "name": "Émirats arabes unis", "nationality": "Émirati", "isInEU": false, },
	"GBR": { "name": "Royaume-Uni", "nationality": "Britannique", "isInEU": false, },
	"USA": { "name": "États-Unis", "nationality": "Américain", "isInEU": false, },
	"URY": { "name": "Uruguay", "nationality": "Uruguayen", "isInEU": false, },
	"UZB": { "name": "Ouzbékistan", "nationality": "Ouzbek", "isInEU": false, },
	"VUT": { "name": "Vanuatu", "nationality": "Vanuatuan", "isInEU": false, },
	"VEN": { "name": "Venezuela", "nationality": "Vénézuélien", "isInEU": false, },
	"VNM": { "name": "Viêt Nam", "nationality": "Vietnamien", "isInEU": false, },
	"WLF": { "name": "Wallis-et-Futuna", "nationality": "Wallisien", "isInEU": false, },
	"ESH": { "name": "Sahara Occidental", "nationality": "Saharien", "isInEU": false, },
	"YEM": { "name": "Yémen", "nationality": "Yéménite", "isInEU": false, },
	"ZMB": { "name": "Zambie", "nationality": "Zambien", "isInEU": false, },
	"ZWE": { "name": "Zimbabwe", "nationality": "Zimbabwéen", "isInEU": false, }
}

export const NationalitiesList = Object.entries(Nationalities).map(([code, n]) => ({ ...n, code, flag: urlFor(code) }))
export type Nationality = keyof typeof Nationalities;