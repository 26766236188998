import { ClothingSize, Gender } from './user';

export const StockSizes = ['', 'xs', 's', 'm', 'l', 'xl'] as const;
export const Seasons = ['summer', 'winter'] as const;
export type StockSize = typeof StockSizes[number];
export type Season = typeof Seasons[number];

export class StockEntry {
	id: number;
	type: string;
	gender: Gender;
	size: ClothingSize;
	quantity: number;
	inMission: number;
	inCleaning: number;
	outOfService: number;
	season: Season;
	available?: number;
	associationId: number;
}

export type StockEntries = Record<ClothingSize, number>;

export type TricoStocks = {
	quechuas: StockEntries;
	umbrellas: number;
};

export type AssociationStocks = {
	paulos: StockEntries;
	kWays: StockEntries;
};

export type StocksEditDto = {
	trico: TricoStocks;
	association: AssociationStocks & { associationId: number };
};
