import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import Title from '../../../components/Title';
import GenericMissionsTable from '../../../components/humanResources/GenericMissionsTable';
import DropDown from '../../../components/DropDown';
import AssociationSelect from '../../../components/associations/AssocationSelect';
import DatePicker from '../../../components/form/DatePicker';

export type PageType = 'payroll' | 'badge' | 'missionStocks' | 'comptabilite';

const recordMap: Record<PageType, string> = {
	payroll: 'Paie',
	badge: 'Badge',
	missionStocks: 'Stocks par mission',
	comptabilite: 'Comptabilité',
};

function getUrlType(): PageType {
	const url = window.location.href;
	const urlPatterns: { pattern: string; value: PageType }[] = [
		{ pattern: 'logistique/stocks-mission', value: 'missionStocks' },
		{ pattern: 'paie', value: 'payroll' },
		{ pattern: 'logistique/badges', value: 'badge' },
		{ pattern: 'comptabilite/mission', value: 'comptabilite' },
	];

	const matchedPattern = urlPatterns.find(({ pattern }) => url.includes(pattern));
	return matchedPattern.value;
}

const GenericMissionPage = () => {
	const [associationId, setAssociationId] = useState<number>();
	const [periodStart, setPeriodStart] = useState(null);
	const [periodEnd, setPeriodEnd] = useState(null);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [missionType, setMissionType] = useState(null);
	const [reValues, setReValues] = useState([]);
	const [selectedRe, setSelectedRe] = useState(null);
	const pageType: PageType = getUrlType();

	const dropdownType = {};
	dropdownType['Tout sélectionner'] = 'Tout sélectionner';
	dropdownType['Paris'] = 'paris';
	dropdownType['Fixe'] = 'fixe';
	dropdownType['IT'] = 'it';
	dropdownType['CCO'] = 'cco';

	return (
		<Box px={3} py={3}>
			{/* Header */}
			<Box>
				<Typography color="textPrimary">{recordMap[pageType]}</Typography>
			</Box>

			{/* Filters */}
			<Box py={2} margin="-8px">
				<Box display="flex" width="100%" alignItems="center">
					<DropDown
						values={['Tout sélectionner', 'Paris', 'Fixe', 'IT', 'CCO']}
						onChange={(e) => setSelectedRe(e)}
						margin={8}
						width="50%"
					/>
					<AssociationSelect
						value={associationId}
						onChange={(value) => setAssociationId(value)}
						label="Association"
						required
						width={'50%'}
						flexGrow={1}
						margin={8}
						variant="outlined"
					/>
				</Box>
				<Box display="flex" width="100%" alignItems="center">
					<DatePicker
						format="DD/MM/YYYY"
						label="Début de la période"
						width={'50%'}
						flexGrow={1}
						margin={8}
						value={periodStart}
						onChange={(date) => {
							setPeriodStart(date);
						}}
					/>
					<DatePicker
						format="DD/MM/YYYY"
						label="Fin de la période"
						width="50%"
						flexGrow={1}
						margin={8}
						value={periodEnd}
						onChange={(date) => {
							setPeriodEnd(date);
						}}
					/>
				</Box>
				<Box display="flex" width="100%" alignItems="center">
					<DropDown
						values={reValues}
						onChange={(e) => setSelectedRe(e)}
						margin={8}
						width="100%"
						label={'RE'}
					/>
				</Box>
			</Box>

			{/* Table */}
			<Title>Liste des missions</Title>
			<GenericMissionsTable
				periodStart={periodStart}
				periodEnd={periodEnd}
				associationId={associationId}
				clearPeriod={() => {
					setPeriodStart(null);
					setPeriodEnd(null);
				}}
				missionType={missionType}
				pageType={pageType}
				setReValues={setReValues}
				reValues={reValues}
				selectedRe={selectedRe}
			/>
		</Box>
	);
};

export default GenericMissionPage;
