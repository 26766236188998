import React, { useState, useEffect } from 'react';
import {
	Box,
	CircularProgress,
	makeStyles,
	useTheme,
	useMediaQuery,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	IconButton,
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { PositionsInfos } from '../../dto/user';
import { MissionUserDto } from '../../dto/user';

type MissionRecruitersTableProps = {
	users: MissionUserDto[];
	areUsersLoading: boolean;
};

const isUserDisabled = (user) => user?.trialPeriodEndType && new Date(user?.trialPeriodEndDate) <= new Date();

const LightUsersMissionTable = ({ users, areUsersLoading }: MissionRecruitersTableProps) => {
	const theme = useTheme();
	const history = useHistory();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));
	const [tmpUsers, setTmpUsers] = useState([]);

	useEffect(() => {
		setTmpUsers([
			...users.filter((user) => !isUserDisabled(user)),
			...users.filter((user) => isUserDisabled(user)),
		]);
	}, [users]);

	const classes = useStyles({ border: false });

	const isStockMission = window.location.href.includes('logistique/stocks-mission');

	return (
		<Box
			my={0.5}
			style={{
				overflow: 'auto',
				maxWidth: isBigScreen ? 'calc(100vw - 380px)' : 'calc(100vw - 20px)',
				border: '1px solid #CCC',
				borderRadius: '3px',
			}}
		>
			<Table size="medium" classes={{ root: classes.tableRoot }}>
				<TableHead>
					<TableRow>
						<TableCell style={{ borderRight: '1px solid #CCC', position: 'relative' }}>
							Nom
							<IconButton size="small" style={{ marginLeft: 10 }}></IconButton>
						</TableCell>
						<TableCell>Role</TableCell>
						{isStockMission && <TableCell>Taille</TableCell>}
					</TableRow>
				</TableHead>
				<TableBody>
					{areUsersLoading ? (
						<Box display="flex" justifyContent="center" my={8} width="100%">
							<CircularProgress />
						</Box>
					) : (
						tmpUsers.map((row, i) => (
							<TableRow
								key={row.id}
								style={{
									cursor: 'pointer',
									backgroundColor: isUserDisabled(row) ? '#c4c4c4' : 'undefined',
								}}
								onClick={() => history.push(`/ressources-humaines/modifier/${row.id}`)}
							>
								<TableCell component="th" scope="row" style={{ borderRight: '1px solid #CCC' }}>
									<Box display="flex" justifyContent="space-between">
										<Box display="flex" alignItems="center">
											<Box mr={1}>
												{row.avatarPath ? (
													<Box
														style={{
															borderRadius: 3,
															height: 25,
															width: 25,
															background: `center / cover no-repeat url(${row.avatarPath})`,
														}}
													/>
												) : (
													<AccountCircle />
												)}
											</Box>
											{row.firstName} {row.lastName}
										</Box>
									</Box>
								</TableCell>
								<TableCell title={PositionsInfos[row?.position]?.label} style={{ cursor: 'help' }}>
									{row.position}
								</TableCell>
								{isStockMission && (
									<TableCell title={PositionsInfos[row?.position]?.label} style={{ cursor: 'help' }}>
										{row.clothingSize.toUpperCase()}
									</TableCell>
								)}
							</TableRow>
						))
					)}
				</TableBody>
			</Table>
		</Box>
	);
};

const useStyles = makeStyles({
	paper: {
		margin: 5,
		padding: '20px 10px',
		flexGrow: 1,
		display: 'flex',
		alignItems: 'center',
		flexFlow: 'column',
		boxSizing: 'border-box',
	},
	tableRoot: {
		whiteSpace: 'nowrap',
		'& thead th': {
			textTransform: 'uppercase',
			color: '#444',
			fontSize: 11.5,
		},
		'& tbody td': {},
		'& tbody tr:hover': {
			backgroundColor: '#FAFAFA',
			boxShadow: 'inset 3px 0px 0px 0px #0093EE',
		},
	},
});

export default LightUsersMissionTable;
