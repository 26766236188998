export enum MissionToUserStatus {
	ACTIVE = 'ACTIVE',
	REMOVED = 'REMOVED',
	COMPOSING = 'COMPOSING',
}

export class MissionToUserDto {
	id: number;
	missionId: number;
	userId: number;
	status: MissionToUserStatus;
	isDriver: boolean;
	perDiem: boolean;
	swile: boolean;
}
