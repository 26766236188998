import { AssociationDto } from './association';
export const ProgramTypes = {
	rue: 'Rue',
	CC: 'CC',
	mixte: 'Mixte',
	PAP: 'PAP',
};

export type ProgramType = keyof typeof ProgramTypes;

export const ProgramGoalTypes = {
	automatic: 'Bulletin de soutient automatique',
	collect_hours: 'Heures de collecte',
};

export type ProgramGoalType = keyof typeof ProgramGoalTypes;

export class ProgramDto {
	constructor(associationId: number) {
		this.associationId = associationId;
	}

	id: number;
	startDate: string = '';
	endDate: string = '';
	budget: number;
	billing: number;
	goalType: keyof typeof ProgramGoalTypes = 'automatic';
	programType: ProgramType;
	associationId: number;
	association?: AssociationDto;
	hourlyObjective: number;
	donationCountObjective: number;
	realRate: number;
}
