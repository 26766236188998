import { Box } from '@material-ui/core';
import React from 'react';
import routes from '../../api/routes';
import MissionForm from '../../components/missions/MissionForm';
import { MissionDto } from '../../dto/mission';

const CreateMission = () => {
	const url = routes.missions.create;
	return (
		<Box px={4} py={0} position="relative" display="flex" flexDirection="column" alignItems="center" mb={8}>
			<MissionForm init={new MissionDto()} url={url}>
				{' '}
			</MissionForm>
		</Box>
	);
};

export default CreateMission;
