import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Tablets from './Tablets';
import CreateTablet from './CreateTablet';
import Stocks from './Stocks';
import EditStocks from './EditStocks';
import useAuth from '../../hooks/useAuth';
import GenericMissionPage from '../humanRessources/payroll/GenericMissionPage';
import Badges from './Badges';
import StocksMission from './StocksMission';

const LogisticsPages = ({ match }: { match: { path: string } }) => {
	const { path } = useRouteMatch();
	const { user } = useAuth();

	return (
		<Switch>
			<Route exact path={`${path}/tablettes`} component={Tablets} />
			<Route exact path={`${path}/badges`} component={GenericMissionPage} />
			<Route exact path={`${path}/badges/:id`} component={Badges} />
			<Route exact path={`${path}/tablettes/creation`} component={CreateTablet} />
			{user.client.id !== 1 ? null : (
				<>
					<Route exact path={`${path}/stocks`} component={Stocks} />
					<Route exact path={`${path}/stocks/modifier`} component={EditStocks} />
					<Route exact path={`${path}/stocks-mission`} component={GenericMissionPage} />
					<Route exact path={`${path}/stocks-mission/:id`} component={StocksMission} />
				</>
			)}
		</Switch>
	);
};

export default LogisticsPages;
