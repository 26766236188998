import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import GenericMissionPage from './GenericMissionPage';
import PayrollMission from './PayrollMission';

const PayrollPages = ({ match }: { match: { path: string } }) => {
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route exact path={`${path}`} component={GenericMissionPage} />
			<Route exact path={`${path}/:id`} component={PayrollMission} />
		</Switch>
	);
};

export default PayrollPages;
