import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper } from '@material-ui/core';
import { default as MUIModal } from '@material-ui/core/Modal';
import { Close } from "@material-ui/icons";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

type ModalProps = {
	open?: boolean;
	onClose?: () => any;
	children?: any;
	noCloseButton?: boolean;
};

const Modal = ({ open, onClose, children, noCloseButton }: ModalProps) => {
	const classes = useStyles({ border: false });

	return (
		<Box>
			<MUIModal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={open || false}
				onClose={onClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 0,
				}}
			>
				<Fade in={open}>
					<div className={classes.wrapper}>
						<Paper className={classes.paper}>
							{!noCloseButton && <Close
								fontSize="small"
								onClick={onClose}
								style={{ display: "block", position: 'absolute', right: "0px", top: "-35px", cursor: "pointer", color: "white", fontSize: "24px" }}
							/>}
							{children}
						</Paper>
					</div>
				</Fade>
			</MUIModal>
		</Box >
	);
}

const useStyles = makeStyles(theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 'none'
	},
	paper: {
		outline: "none",
		maxHeight: '90vh',
		maxWidth: "100vw",
		padding: 20,
		boxSizing: "border-box",
		overflow: "auto",
	},
	wrapper: {
		margin: "60px 0px",
		position: 'relative',
		outline: "none",
	}
}));

export default Modal;