import React, { useState, useRef, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import { Box, Button } from '@material-ui/core';
import useClickOutside from '../../hooks/useClickOutside';

type ColorPickerProps = {
	error?: string;
	value: string;
	onChange: (value: string) => any;
};

const ColorPicker = ({ value, onChange }: ColorPickerProps) => {
	let [open, setOpen] = useState(false);
	let [previewColor, setPreviewColor] = useState('#FFFFFF');
	let ref = useRef(null);

	useEffect(() => {
		if (previewColor !== value) setPreviewColor(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	useClickOutside(ref as any, () => {
		setOpen(false);
	});

	return (
		<Box>
			<Button
				variant="outlined"
				onClick={() => setOpen(true)}
				style={{ background: previewColor, height: 30, minWidth: 30, margin: 5 }}
			/>
			{open && (
				<div style={{ position: 'absolute', zIndex: 20 }} ref={ref}>
					<SketchPicker
						color={previewColor}
						onChangeComplete={(color) => {
							onChange?.(color.hex);
						}}
						onChange={(color) => {
							setPreviewColor(color.hex);
						}}
					/>
				</div>
			)}
		</Box>
	);
};

export default ColorPicker;
