import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import routes from '../../api/routes';
import AssociationForm from '../../components/associations/AssociationForm';
import DeleteButton from '../../components/form/DeleteButton';
import { AssociationDto } from '../../dto/association';
import useFetch from '../../hooks/useFetch';

const EditAssociation = () => {
	let { id } = useParams<{ id: string }>();
	const url = routes.associations.edit({ id: +id });
	let [isLoading, associationData] = useFetch<AssociationDto>(routes.associations.get({ id: +id }));

	return (
		<Box px={4} py={0} position="relative" display="flex" flexDirection="column" alignItems="center" mb={8}>
			{isLoading ? (
				<CircularProgress size={30} color="primary" style={{ marginTop: 200 }} />
			) : (
				<AssociationForm edit init={associationData} url={url}>
					<Box py={1}>
						<DeleteButton
							url={routes.associations.delete({ id: +id })}
							confirmationMessage="Êtes vous sur de vouloir supprimer l'association ?"
							successMessage="L'association a bien été supprimée."
							returnLabel="Retour aux associations"
							returnUrl="/associations"
						>
							Supprimer l'association
						</DeleteButton>
					</Box>
				</AssociationForm>
			)}
		</Box>
	);
};

export default EditAssociation;
