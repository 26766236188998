import {
	Box,
	Button,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Add, Edit, Mail } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import routes from '../../api/routes';
import { Card, CardContainer } from '../../components/Cards';
import DeleteButton from '../../components/form/DeleteButton';
import ReturnButton from '../../components/form/ReturnButton';
import PrimaryButton from '../../components/PrimaryButton';
import Title from '../../components/Title';
import { AssociationDto } from '../../dto/association';
import { ProgramDto, ProgramGoalTypes } from '../../dto/program';
import useFetch from '../../hooks/useFetch';
import Modal from '../../components/Modal';
import { post } from '../../api';

const ViewAssociation = () => {
	const { id } = useParams<{ id: string }>();
	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));
	const [isLoading, association] = useFetch<AssociationDto>(routes.associations.get({ id: +id }));
	const [areCurrentProgramsLoading, currentPrograms] = useFetch<ProgramDto[]>(
		routes.programs.current({ associationId: +id }),
	);
	const [arePastProgramsLoading, pastPrograms] = useFetch<ProgramDto[]>(
		routes.programs.past({ associationId: +id }),
	);
	const [sendAssociationDonations, setSendAssociationDonations] = React.useState(false);

	const classes = useStyles({ border: false });

	if (isLoading) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center" height={300}>
				<CircularProgress />
			</Box>
		);
	}

	const isStaging =
		window.location.hostname.includes('staging.leto-trico.fr') ||
		window.location.hostname.includes('localhost');

	return (
		<Box px={3} py={3} pt={1}>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<ReturnButton to="/associations">Retour aux associations</ReturnButton>
				<Box display="flex">
					<Box mr={1}>
						<Link to={`/associations/modifier/${id}`}>
							<PrimaryButton size="small">
								<Edit />
							</PrimaryButton>
						</Link>
					</Box>
					<DeleteButton
						size="small"
						url={routes.associations.delete({ id: +id })}
						confirmationMessage="Êtes vous sur de vouloir supprimer l'association ?"
						successMessage="L'association a bien été supprimée."
						returnLabel="Retour aux associations"
						returnUrl="/associations"
					/>
					<Box ml={1}>
						<PrimaryButton size="small" onClick={() => setSendAssociationDonations(true)}>
							<Mail />
						</PrimaryButton>
					</Box>
					<Modal open={sendAssociationDonations} onClose={() => setSendAssociationDonations(false)}>
						<>
							<p>
								Déclencher manuellement l'envoi automatique des bulletins de l'association
								{isStaging ? ' [Désactivé sur le staging]' : ''}
							</p>
							<Box display="flex" justifyContent="space-between">
								<Button onClick={() => setSendAssociationDonations(false)} variant="contained">
									Annuler
								</Button>
								<Button
									variant="contained"
									color="primary"
									onClick={() => {
										post(routes.cron.post({ associationId: +id }));
										setSendAssociationDonations(false);
									}}
									disabled={isStaging}
								>
									Confirmer l'envoi
								</Button>
							</Box>
						</>
					</Modal>
				</Box>
			</Box>

			<Box display="flex" justifyContent="center" alignItems="center" py={4}>
				<img src={association?.image?.path} style={{ maxHeight: 200 }} alt={association.name} />
			</Box>

			<CardContainer>
				{areCurrentProgramsLoading ? (
					<CircularProgress />
				) : (
					<>
						<Title mx={2} mt={1} width="100%">
							{currentPrograms.length > 0 ? (
								<>
									<b>
										{currentPrograms.length} programme{currentPrograms.length > 1 ? 's' : ''}
									</b>{' '}
									en cours
								</>
							) : (
								'Pas de programmes en cours'
							)}
						</Title>
						{currentPrograms.map((program, i) => (
							<>
								<Box
									mx={2}
									mt={i > 0 ? 2 : 0}
									py={1}
									width="100%"
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<Title small>
										Programme du {moment(program.startDate).format('DD/MM/YYYY')} au{' '}
										{moment(program.endDate).format('DD/MM/YYYY')}
									</Title>
									<Box display="flex">
										<Box mr={0.5}>
											<Link
												to={`/associations/${association.id}/programmes/modifier/${program.id}`}
											>
												<PrimaryButton mini round variant="text" color="default">
													<Edit style={{ fontSize: 14 }} />
												</PrimaryButton>
											</Link>
										</Box>
										<DeleteButton
											mini
											round
											url={routes.programs.delete({ id: program.id })}
											confirmationMessage="Êtes vous sûr de vouloir supprimer le programme ?"
											successMessage="Le programme a bien été supprimé !"
											returnLabel="Retour à l'association"
											returnUrl={`/associations/${id}`}
										/>
									</Box>
								</Box>
								<Card title="Début">{moment(program.startDate).format('DD/MM/YYYY')}</Card>
								<Card title="Fin">{moment(program.endDate).format('DD/MM/YYYY')}</Card>
								<Card title="Bulletin">{(program.programType || '').toUpperCase()}</Card>
								<Card title="Type d'objectif">{ProgramGoalTypes[program.goalType]}</Card>
								<Card title="Objectif bulletins">{program.donationCountObjective}</Card>
								<Card title="Objectif heures">{program.hourlyObjective}</Card>
							</>
						))}
					</>
				)}
			</CardContainer>

			<Box my={2}>
				<Link to={`/associations/${id}/programmes/creation`}>
					<PrimaryButton>
						<Add />
						<Box ml={1}>Créer un programme</Box>
					</PrimaryButton>
				</Link>
			</Box>

			{/* Table */}
			<Title>Programmes passés de {association?.name}</Title>
			<Box
				style={{
					overflow: 'auto',
					maxWidth: isBigScreen ? 'calc(100vw - 352px)' : 'calc(100vw - 20px)',
					border: '1px solid #CCC',
					borderRadius: '3px',
				}}
				my={2}
			>
				{arePastProgramsLoading ? (
					<Box display="flex" justifyContent="center" my={8}>
						<CircularProgress />
					</Box>
				) : (
					<Table size="medium" classes={{ root: classes.tableRoot }}>
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								<TableCell>Début</TableCell>
								<TableCell>Fin</TableCell>
								<TableCell>Type programme</TableCell>
								<TableCell>Type d'objectif</TableCell>
								<TableCell>Objectif bulletins</TableCell>
								<TableCell>Objectif heures</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{pastPrograms.map((program, i) => (
								<TableRow key={program.id}>
									<TableCell>
										<Link to={`/associations/${association.id}/programmes/modifier/${program.id}`}>
											<Edit fontSize="small" color="action" />
										</Link>
									</TableCell>
									<TableCell>{moment(program.startDate).format('DD/MM/YYYY')}</TableCell>
									<TableCell>{moment(program.endDate).format('DD/MM/YYYY')}</TableCell>
									<TableCell>{(program.programType || '').toUpperCase()}</TableCell>
									<TableCell>{ProgramGoalTypes[program.goalType]}</TableCell>
									<TableCell>{program.donationCountObjective}</TableCell>
									<TableCell>{program.hourlyObjective}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				)}
			</Box>
		</Box>
	);
};

const useStyles = makeStyles(({ isBigScreen }: { isBigScreen?: boolean }) => ({
	excelButton: {
		padding: '8px 8px',
		minWidth: 10,
		marginRight: 5,
	},
	cardsContainer: {
		position: 'relative',
		borderRadius: '3px',
		paddingRight: 2,
		paddingLeft: 2,
	},
	select: {
		margin: 9,
		width: 350,
		flexGrow: 1,
	},
	tableRoot: {
		whiteSpace: 'nowrap',
		'& thead th': {
			textTransform: 'uppercase',
			color: '#444',
			fontSize: 11.5,
			//textAlign: "center"
		},
		'& thead tr th:first-child': {
			//textAlign: "left"
		},
		'& tbody td': {
			//textAlign: "center"
		},
		'& tbody tr:hover': {
			backgroundColor: '#FAFAFA',
			boxShadow: 'inset 3px 0px 0px 0px #0093EE',
		},
	},
}));

export default ViewAssociation;
