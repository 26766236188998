import { Button, Collapse, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
	Assignment,
	AssignmentInd,
	Close,
	ExpandLess,
	ExpandMore,
	LocalShipping,
	PieChart,
} from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PhoneIcon from '@material-ui/icons/Phone';
import React, { useState } from 'react';
import { FaHandshake } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { UserAccesses } from '../dto/user';
import useAuth from '../hooks/useAuth';
import { Payments } from '@mui/icons-material';

type Item = {
	text: string;
	to?: string;
	icon?: JSX.Element;
	onlyTrico?: boolean;
};

const menuItems: Array<Item & { access: keyof UserAccesses; subMenu?: Item[] }> = [
	{
		text: 'Tableau de bord',
		to: '/associations',
		icon: <PieChart />,
		access: 'hasAccessToBODashboard',
	},
	{
		text: 'Gestion des Bulletins',
		icon: <Assignment />,
		onlyTrico: false,
		access: 'hasAccessToBODonations',
		subMenu: [
			{ text: 'Bulletins', to: '/gestion-des-bulletins/bulletins', onlyTrico: false },
			{ text: 'Rendez-vous', to: '/gestion-des-bulletins/rendez-vous', onlyTrico: false },
			{ text: 'Appels de bienvenue', to: '/gestion-des-bulletins/appels-de-bienvenue', onlyTrico: false },
			{
				text: 'Logs export bulletins',
				to: '/gestion-des-bulletins/export-logs',
				onlyTrico: true,
			},
		],
	},
	{
		text: 'Logistique',
		icon: <LocalShipping />,
		onlyTrico: false,
		access: 'hasAccessToBOLogistic',
		subMenu: [
			{ text: 'Création de badges', to: '/logistique/badges', onlyTrico: true },
			{ text: 'Gestion des stocks', to: '/logistique/stocks', onlyTrico: true },
			{ text: 'Stocks par mission', to: '/logistique/stocks-mission', onlyTrico: true },
			{ text: 'Gestion des tablettes', to: '/logistique/tablettes', onlyTrico: false },
		],
	},
	{
		text: 'Comptabilité',
		icon: <Payments />,
		onlyTrico: false,
		access: 'hasAccessToBOLogistic',
		subMenu: [
			{ text: 'Mission', to: '/comptabilite/mission', onlyTrico: true },
			{ text: 'ROT', to: '/comptabilite/rot', onlyTrico: true },
		],
	},
	{
		text: 'Opérationnel',
		to: '/missions',
		icon: <FaHandshake size={24} />,
		onlyTrico: false,
		access: 'hasAccessToBOMissions',
	},
	{
		text: 'Ressources humaines',
		icon: <AssignmentInd />,
		access: 'hasAccessToBOHR',
		subMenu: [
			{ text: 'Tricoteurs', to: '/ressources-humaines', onlyTrico: false },
			{ text: 'Paie', to: '/paie' },
		],
	},
	{
		text: 'Entretiens',
		to: '/interviews',
		icon: <PhoneIcon />,
		onlyTrico: false,
		access: 'hasAccessToBOInterviews',
	},
];

const SideMenu = ({ open, close }: { open: boolean; close: () => any }) => {
	const theme = useTheme();
	const [openMenus, setOpenMenus] = useState<Record<string, boolean>>({});
	const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));
	let drawerClasses = useDrawerStyles({ fullWidth: !isBigScreen });
	let classes = useStyles({ border: false });
	let { user, logout } = useAuth();

	return (
		<Drawer classes={drawerClasses} open={open} variant={isBigScreen ? 'permanent' : 'temporary'}>
			{!isBigScreen && (
				<Button onClick={close} className={classes.closeButton}>
					<Close />
				</Button>
			)}
			<List style={{ marginTop: isBigScreen ? 0 : 30 }}>
				{menuItems
					.filter(({ access }) => user[access])
					.map(({ text, icon, to, subMenu, onlyTrico }, index) =>
						onlyTrico && user.client.id !== 1 ? null : (
							<React.Fragment key={text}>
								<ListItem
									button
									component={
										to ? (props: any) => <Link to={to} {...props} onClick={close} /> : undefined
									}
									onClick={
										subMenu
											? () => setOpenMenus({ ...openMenus, [index]: !openMenus[index] })
											: undefined
									}
								>
									<ListItemIcon>{icon as any}</ListItemIcon>
									<ListItemText primary={text} />
									{!!subMenu && (openMenus[index] ? <ExpandLess /> : <ExpandMore />)}
								</ListItem>
								{subMenu && (
									<Collapse in={openMenus[index]} timeout="auto" unmountOnExit>
										<List disablePadding>
											{subMenu.map(({ text, to, onlyTrico }) =>
												onlyTrico && user.client.id !== 1 ? null : (
													<ListItem
														dense
														button
														key={text}
														component={(props) => <Link to={to} {...props} onClick={close} />}
													>
														<ListItemText
															style={{ marginLeft: 60, color: '#777' }}
															primary={text}
														/>
													</ListItem>
												),
											)}
										</List>
									</Collapse>
								)}
							</React.Fragment>
						),
					)}
				{!isBigScreen && (
					<ListItem onClick={logout} button>
						<ListItemIcon>
							<ExitToAppIcon />
						</ListItemIcon>
						<ListItemText primary="Déconnection" />
					</ListItem>
				)}
			</List>
		</Drawer>
	);
};

const useDrawerStyles = makeStyles({
	root: {
		width: (props: { fullWidth: boolean }) => (props.fullWidth ? '100%' : '300px'),
	},
	paper: {
		width: (props: { fullWidth: boolean }) => (props.fullWidth ? '100%' : '300px'),
	},
});

const useStyles = makeStyles({
	closeButton: {
		position: 'absolute',
		right: 5,
		width: 34,
		minWidth: 34,
		borderRadius: 50,
	},
});

export default SideMenu;
