import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import routes from '../../api/routes';
import DeleteButton from '../../components/form/DeleteButton';
import UserForm from '../../components/humanResources/UserForm';
import { UserDto } from '../../dto/user';
import useFetch from '../../hooks/useFetch';

const EditUser = () => {
	const { id = '0' } = useParams<{ id: string }>();
	const url = routes.users.edit({ id });
	const [isLoading, user] = useFetch<UserDto>(routes.users.get({ id }));

	return (
		<Box px={4} py={0} position="relative" display="flex" flexDirection="column" alignItems="center" mb={8}>
			{isLoading ? (
				<Box my={10}>
					<CircularProgress />
				</Box>
			) : (
				<UserForm edit init={user} url={url}>
					<Box py={1} mr={1}>
						<DeleteButton
							url={routes.users.delete({ id })}
							confirmationMessage="Êtes vous sûr de vouloir supprimer cet utilisateur ?"
							successMessage="L'utilisateur a bien été supprimé !"
							returnLabel="Retour à la page 'ressources humaines'"
							returnUrl={`/ressources-humaines`}
						>
							Supprimer l'utilisateur
						</DeleteButton>
					</Box>
				</UserForm>
			)}
		</Box>
	);
};

export default EditUser;
