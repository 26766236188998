/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Link, Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Pagination } from '@material-ui/lab';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import routes from '../../api/routes';
import Title from '../../components/Title';
import { rowsPerPage } from '../../dto/donation';
import useFetch from '../../hooks/useFetch';
import DropDown from '../../components/DropDown';
import DatePicker from '../../components/form/DatePicker';
import { FaFileExcel } from 'react-icons/fa';

const states: string[] = ['success', 'unknown', 'error'];
const errorCountText: string[] = ['0', '>0'];

const Donations = () => {
	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));

	const [page, setPage] = useState(1);
	const [status, setStatus] = useState<null | string>(null);
	const [errorCount, setErrorCount] = useState<null | string>(null);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [name, setName] = useState(null);

	const [key, setKey] = useState(1);

	const [exportNames, setExportNames] = useState([]);
	const [, currentCount] = useFetch<{ count: number }>(routes.donations.exportLogs.count({}, { status, name, startDate, endDate, errorCount }), [status, name, startDate, endDate, errorCount]);
	const [isLoading, logs] = useFetch<any[]>(routes.donations.exportLogs.all({}, { page, status, name, startDate, endDate, errorCount }), [page, status, name, startDate, endDate, errorCount]);
	const [, exportNamesFetched] = useFetch<any[]>(routes.donations.exportLogs.exportNames({}, {}), []);

	useEffect(() => {
		if (exportNamesFetched){
			setExportNames(exportNamesFetched.map((item) => item.name));
		}
	}, [exportNamesFetched])

	const classes = useStyles({ border: false });
	const [error, setError] = useState(null);

	const columns = [
		'Id',
		'Tache',
		'Description',
		'Status',
		"Nombre d'erreurs",
		'Date/heure début',
		'Date/heure fin',
		'logs',
	];

	const resetFilters = () => {
		setStatus(null);
		setStartDate(null);
		setEndDate(null);
		setName(null);
		setKey(key + 1);
		setErrorCount(null);
	}

	const handleDownload = (row) => {
    try {
      window.open(routes.donations.exportLogs.excelLogs({}, { logs: row.logs }));
    } catch (err) {
      setError(err);
    }
  };

	return (
		<Box p={3}>
			<Box mb={2} display="flex" justifyContent="space-between">
				<Title>Logs des exports de bulletin</Title>
				<button onClick={() => resetFilters()} style={{
					fontVariant: "small-caps",
					color: "#222",
					fontSize: 15}}>
					<text>Supprimer les filtres</text>
				</button>
			</Box>

			{/* Filters */}
			<Box py={2} margin="-8px">
				<Box display="flex" width="100%" alignItems="center" key={key}>
					<DropDown
						values={states}
						label='Status'
						onChange={(e) => setStatus(e)}
						margin={8}
						width="50%"
					/>
					<DropDown
						values={exportNames}
						label='Tache'
						onChange={(e) => setName(e)}
						margin={8}
						width="50%"
					/>
				</Box>
				<Box display="flex" width="100%" alignItems="center">
					<DatePicker
						format="DD/MM/YYYY"
						label="Début de la période"
						width={'50%'}
						flexGrow={1}
						margin={8}
						value={startDate}
						onChange={(date) => {
							setStartDate(date);
						}}
					/>
					<DatePicker
						format="DD/MM/YYYY"
						label="Fin de la période"
						width="50%"
						flexGrow={1}
						margin={8}
						value={endDate}
						onChange={(date) => {
							setEndDate(date);
						}}
					/>
				</Box>
				<Box display="flex" width="100%" alignItems="center" key={key +'-err'}>
					<DropDown
							values={errorCountText}
							label="Nombre d'erreurs"
							onChange={(e) => setErrorCount(e)}
							margin={8}
							width="100%"
						/>
				</Box>
			</Box>

			<Box
				my={2}
				style={{
					overflow: 'auto',
					maxWidth: isBigScreen ? 'calc(100vw - 346px)' : 'calc(100vw - 20px)',
					border: '1px solid #CCC',
					borderRadius: '3px',
					marginTop: 15,
				}}
			>
				<Table size="medium" classes={{ root: classes.tableRoot }}>
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell>{column}</TableCell>
							))}
						</TableRow>
					</TableHead>
					{!isLoading && (
						<TableBody>
							{logs &&
							  Array.isArray(logs) &&
								logs.length &&
								logs.map((row, i) => (
									<TableRow key={row.id}>
										<TableCell style={{ verticalAlign: 'top' }}>{row.id}</TableCell>
										<TableCell style={{ verticalAlign: 'top' }}>{row.name}</TableCell>
										<TableCell style={{ verticalAlign: 'top' }}>{row.description}</TableCell>
										<TableCell style={{ verticalAlign: 'top' }}>{row.status}</TableCell>
										<TableCell style={{ verticalAlign: 'top' }}>{row.errorCount}</TableCell>
										<TableCell style={{ verticalAlign: 'top' }}>
											{row.startDate ? moment(row.startDate).format('DD/MM/YYYY HH:mm:ss') : ''}
										</TableCell>
										<TableCell style={{ verticalAlign: 'top' }}>
											{row.endDate ? moment(row.endDate).format('DD/MM/YYYY HH:mm:ss') : ''}
										</TableCell>
										<TableCell
											style={{
												whiteSpace: 'pre',
												wordWrap: 'break-word',
											}}
										>
											{row.logs}
										</TableCell>
										<TableCell>
											{
												row.logs && row.logs !== '\n' && 
												// <Link
												// 	href={routes.donations.exportLogs.excelLogs({}, { logs: row.logs },)}>
													<Button className={classes.excelButton} size="small" variant="contained" color="primary" onClick={() => handleDownload(row)}>
														<FaFileExcel size={16} />
													</Button>
												// </Link>
											}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					)}
				</Table>

				{isLoading && (
					<Box display="flex" width="100%" justifyContent="center" my={8}>
						<CircularProgress />
					</Box>
				)}
			</Box>
			<Box p={1} display="flex" justifyContent="end">
				<Pagination
					page={page}
					onChange={(_, page) => setPage(page)}
					count={Math.ceil((currentCount?.count || 0) / rowsPerPage)}
				/>
			</Box>
		</Box>
	);
};

const useStyles = makeStyles(({ isBigScreen }: { isBigScreen?: boolean }) => ({
	tabs: {
		marginBottom: -1,
		'& button': {
			borderColor: '#CCC',
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
			fontSize: 12,
			padding: '10px 15px',
		},
	},
	tableRoot: {
		whiteSpace: 'nowrap',
		'& thead th': {
			textTransform: 'uppercase',
			color: '#444',
			fontSize: 11.5,
		},
		'& tbody tr.noBottomBorder > td': {
			borderBottom: 'none',
		},
		'& tbody tr:not(.comment):hover': {
			backgroundColor: '#FAFAFA',
			boxShadow: 'inset 3px 0px 0px 0px #0093EE',
		},
	},
	excelButton: {
		padding: '8px 8px',
		minWidth: 10,
		marginRight: 5,
	},
}));

export default Donations;
