import React from 'react';
import { Box } from '@material-ui/core';
import routes from '../../api/routes';
import UserForm from '../../components/humanResources/UserForm';
import { UserDto } from '../../dto/user';

const CreateUser = () => {
	return (
		<Box px={4} py={0} position="relative" display="flex" flexDirection="column" alignItems="center" mb={8}>
			<UserForm init={new UserDto()} url={routes.users.create} />
		</Box>
	);
};

export default CreateUser;
