import React, { useState } from 'react';
import { User } from '../../types/user';

let serializedUser = localStorage.getItem('user');
let savedUser: any;

try {
	savedUser = serializedUser ? JSON.parse(serializedUser) : null;
} catch (err) {
	console.error(err);
	savedUser = null;
	localStorage.removeItem('token');
}

export type UserContextProps = {
	user: User | null;
	setUser: (user: User | null) => any;
};
export const UserContext = React.createContext<UserContextProps>({ user: savedUser, setUser: () => {} });

const UserProvider = ({ children }: { children: any }) => {
	let [user, rawSetUser] = useState<User | null>(savedUser);

	const setUser = (user: User | null) => {
		if (user) {
			localStorage.setItem('user', JSON.stringify(user));
		} else {
			localStorage.removeItem('user');
		}
		rawSetUser(user);
	};

	return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};

export default UserProvider;
