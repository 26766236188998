import { MenuItem } from '@material-ui/core';
import React from 'react';
import Select from './form/Select';

type DropDownProps = {
	values: Array<string>;
	onChange?: (e) => any;
	margin?: number | string;
	width?: number | string;
	label?: string;
	value?: string;
};

const DropDown = ({ values, onChange, margin, width, label = 'Type', value }: DropDownProps) => {
	return (
		<Select
			label={label}
			{...(values as any)}
			onChange={(e) => onChange(e)}
			margin={margin}
			width={width}
			value={value}
		>
			{values.map((value) => (
				<MenuItem key={value} value={value}>
					{value}
				</MenuItem>
			))}
		</Select>
	);
};

export default DropDown;
