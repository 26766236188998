import React from 'react';
import useFetch from '../../hooks/useFetch';
import { AssociationDto } from '../../dto/association';
import routes from '../../api/routes';
import { MenuItem } from '@material-ui/core';
import Select, { SelectProps } from '../form/Select';

const AssociationSelect = function <T = any>(
	props: SelectProps<T> & { allOption?: boolean; tricoOption?: boolean; notAll?: boolean },
) {
	const [isLoading, associations] = useFetch<AssociationDto[]>(routes.associations.all);
	return (
		<Select label="Association" {...(props as any)}>
			{!props.notAll && <MenuItem value={undefined}>Toutes</MenuItem>}
			{props?.tricoOption && <MenuItem value={-1}>Trico</MenuItem>}
			{!isLoading &&
				associations?.map((asso) => (
					<MenuItem key={asso.id} value={asso.id}>
						{asso.name}
					</MenuItem>
				))}
		</Select>
	);
};

export default AssociationSelect;
