import React from 'react';
import TextInput, { TextInputProps } from './TextInput';

export type NumberInputProps = Omit<TextInputProps, 'value' | 'onChange'> & {
	value?: number;
	onChange?: (value: number) => any;
	min?: number;
	max?: number;
	isFloat?: boolean;
};

const NumberInput = React.memo(({ value, onChange, min, max, isFloat, ...props }: NumberInputProps) => {
	if (isFloat) {
		return (
			<TextInput
				{...props}
				type="number"
				pattern="[0-9]+(,[0-9]+)?"
				value={value === 0 ? '0' : String(value || '')}
				onChange={(v) => {
					onChange?.(parseFloat(v));
				}}
			/>
		);
	}
	return (
		<TextInput
			{...props}
			type="text"
			pattern="[0-9]+(,[0-9]+)?"
			value={String(value || '').replace(/\./g, ',')}
			onChange={(v) => {
				let nvalue = v
					.replace(/\./, ',')
					.replace(/[^0-9,]/g, '')
					.replace(/,/g, '.');
				if (nvalue) {
					if (min && min !== 0 && +nvalue < min) nvalue = min + '';
					else if (max && max !== 0 && +nvalue > max) nvalue = max + '';
				}
				onChange?.(+nvalue);
			}}
		/>
	);
});

export default NumberInput;
