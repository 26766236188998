const utils = (baseUrl: string) => {
	let utils = {
		prefixRoute(route: string) {
			return baseUrl + (route[0] === "/" ? '' : '/') + route;
		},

		prefixRoutes<T>(routes: T): T {
			if (typeof routes === "object") {
				let newRoutes: Record<string, any> = {};
				for (let [key, value] of Object.entries(routes)) {
					newRoutes[key] = utils.prefixRoutes(value) as any;
				}
				return newRoutes as T;
			} else if (typeof routes === "string") {
				return utils.prefixRoute(routes) as any as T;
			} else {
				return routes;
			}
		},

		paramsRoute: <T, Q extends object = Record<string, any>>(path: string) => (
			params: T = {} as any,
			queryParams: Q = {} as any
		) => {
			let finalPath = path;
			let query = Object.entries(queryParams)
				.filter(([k, v]) => v !== undefined && v !== null)
				.map(kv => kv.join('=')).join('&');
			for (let [key, value] of Object.entries(params)) {
				finalPath = finalPath.replace(':' + key, String(value));
			}
			return utils.prefixRoute(finalPath) + (query ? "?" : "") + query;
		}
	}

	return utils;
};

export default utils;