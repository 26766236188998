import React, { useRef } from "react";
import moment from "moment";
import "moment/locale/fr";
import MomentUtils from "@date-io/moment";
import {
	MuiPickersUtilsProvider,
	DateTimePicker as MUIDateTimePicker,
	DateTimePickerProps as MUIDateTimePickerProps,
} from '@material-ui/pickers';
import { FormControl, FormHelperText } from "@material-ui/core";
moment.locale("fr");

export type DateTimePickerProps = Omit<MUIDateTimePickerProps, 'error' | 'margin' | 'value' | 'onChange'> & {
	error?: string;
	width?: number;
	flexGrow?: number;
	margin?: number;
	inputRef?: any;
	value?: string;
	onChange?: (value: string) => any
};

const DateTimePicker = ({ value, onChange, error, width, flexGrow, margin, inputRef, ...props }: DateTimePickerProps) => {
	let ref = useRef<HTMLDivElement>();

	inputRef?.({
		value,
		checkValidity: () => !props.required || value,
		validationMessage: (props.required && !value) ? "Ce champ est obligatoire, veuillez sélectionner une valeur" : "",
		scrollIntoView: (params: any) => (ref.current as any)?.scrollIntoView?.(params)
	});

	return <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="fr">
		<FormControl ref={ref as any} fullWidth={props.fullWidth} error={!!error} style={{ width, flexGrow, margin, boxSizing: 'border-box' }}>
			<MUIDateTimePicker
				value={value ? new Date(value) : null}
				ampm={false}
				onChange={date => onChange?.(date.format())}
				fullWidth={props.fullWidth}
				inputVariant="outlined"
				cancelLabel="Annuler"
				error={!!error}
				{...props} />
			{error && <FormHelperText>{error}</FormHelperText>}
		</FormControl>
	</MuiPickersUtilsProvider>
}

export default DateTimePicker;