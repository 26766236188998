import {
	Box,
	Breadcrumbs,
	Button,
	// Chip,
	CircularProgress,
	FormLabel,
	Link,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AccountCircle, Add, Edit, Search, FiberNew, Update } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { FaFileExcel } from 'react-icons/fa';
import { Link as RouterLink, useHistory, useRouteMatch } from 'react-router-dom';
import routes from '../../api/routes';
import Select from '../../components/form/Select';
import Switch from '../../components/form/Switch';
import TextInput from '../../components/form/TextInput';
import GradesForm from '../../components/humanResources/GradesForm';
import { GradeSelect } from '../../components/humanResources/UserForm';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import { TricoteursFiltersDto } from '../../dto/donation';
import { GradeDto } from '../../dto/grade';
import { HumanResourcesRowDto, rowsPerPage, States } from '../../dto/user';
import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';

const HumanResources = () => {
	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));
	const [requestedPage, setRequestedPage] = useState(1);
	const [users, setUsers] = useState<HumanResourcesRowDto[]>([]);
	const [searchString, setSearchString] = useState('');
	const [searchStringPreview, setSearchStringPreview] = useState('');
	const form = useForm({
		init: { ...new TricoteursFiltersDto(), state: 'Actif' },
	});
	let filters = form.values;

	const [isLoading, rawUsers] = useFetch<HumanResourcesRowDto[]>(
		routes.users.search(
			{},
			{
				search: searchString || undefined,
				page: 0, // not used anymore
				gradeId: filters.gradeId,
				state: filters.state,
				isWorking: filters.isWorking,
				infoHR: filters.infoHR,
			},
		),
		[searchString],
	);

	const currentDate = new Date();
	const threeMonthsAgo = currentDate.setMonth(currentDate.getMonth() - 3);
	const needAnUpdate = (lastUpdate: string) => {
		if (!lastUpdate || lastUpdate.length < 14) return true;
		return !moment(lastUpdate).isAfter(threeMonthsAgo);
	};

	useEffect(() => {
		const currentDateEseEffect = new Date();
		const threeMonthsAgoUseEffect = currentDateEseEffect.setMonth(currentDateEseEffect.getMonth() - 3);
		const needAnUpdateUseEffect = (lastUpdate: string) => {
			if (!lastUpdate || lastUpdate.length < 14) return true;
			return !moment(lastUpdate).isAfter(threeMonthsAgoUseEffect);
		};

		let filteredUsers = rawUsers;
		const gradeIdInt = parseInt(filters.gradeId);
		if (filters.isWorking === true && filters.isWorking && rawUsers)
			filteredUsers = filteredUsers.filter((u) => u.currentMissionTricoCode);
		if (filters.gradeId !== '' && filters.gradeId && rawUsers)
			filteredUsers = filteredUsers.filter((u) => +u.gradeId === gradeIdInt);
		if (filters.state !== '' && filters.state && rawUsers)
			filteredUsers = filteredUsers.filter((u) => u.state === filters.state);
		if (filters.infoHR === 'new' && filters.infoHR && rawUsers)
			filteredUsers = filteredUsers.filter((u) => !u.socialSecurity);
		if (filters.infoHR === 'update' && filters.infoHR && rawUsers)
			filteredUsers = filteredUsers.filter((u) => needAnUpdateUseEffect(u.updateHRInfosDate));
		setUsers(filteredUsers);
	}, [rawUsers, filters.gradeId, filters.state, filters.isWorking, filters.infoHR]);

	// old page count (before december 15th 2022)
	// const pageCount = Math.max(Math.ceil((usersCount?.count || 0) / rowsPerPage), 1);
	const pageCount = users && users.length ? Math.ceil(users.length / rowsPerPage) : 1;
	const page = Math.max(1, Math.min(requestedPage, pageCount));

	filters.page = page;
	const [openGradeModal, setOpenGradeModal] = useState(false);
	const timeout = useRef({ timeout: null });

	let [, grades] = useFetch<GradeDto[]>(routes.users.grades.all);
	let Grades = {};
	if (grades && grades.length) grades.forEach((grade) => (Grades[grade.id] = grade.description));

	const history = useHistory();

	const classes = useStyles({ border: false });
	const { url } = useRouteMatch();
	const { user } = useAuth();

	return (
		<Box px={3} py={3}>
			{/* Header */}
			<Box display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center">
				<Breadcrumbs aria-label="breadcrumb">
					<Typography color="textPrimary">Ressources humaines</Typography>
				</Breadcrumbs>
				<Box>
					<Button
						style={{ marginRight: 5 }}
						onClick={() => setOpenGradeModal(true)}
						size="small"
						variant="contained"
						color="primary"
					>
						<Edit /> Poste
					</Button>
					<Link
						href={routes.users.excel({ clientId: user && user.client ? user.client.id : '0' })}
						target="_blank"
					>
						<Button className={classes.excelButton} size="small" variant="contained" color="primary">
							<FaFileExcel size={16} />
						</Button>
					</Link>
					<RouterLink to={`${url}/creation`}>
						<Button size="small" variant="contained" color="primary">
							<Add /> Créer
						</Button>
					</RouterLink>
				</Box>
			</Box>

			{/* Filters */}
			<Box display="flex" flexWrap="wrap" py={2}>
				{/* Filtre 1: Statut Tricoteurs : Actif (par défaut) / Inactif / NG / Tous les statuts */}
				<Select
					label="Statut"
					values={{ '': 'Tous les statuts', ...States }}
					{...form.field('state')}
					margin={8}
				/>

				{/* Filtre 2: En contrat actuellement: afficher uniquement les personnes avec qqch dans la colonne mission en cours Case unique on/off */}
				<Box width={120} paddingLeft={4}>
					<FormLabel component="legend">En contrat actuellement</FormLabel>
					<Switch {...form.field('isWorking')} color="primary" />
				</Box>

				{/* Filtre 3: NEW / Old / Tous les statuts (défaut) */}
				<Select
					label="Infos RH"
					values={{ '': 'Tous les statuts', update: 'À mettre à jour', new: 'Nouveau' }}
					{...form.field('infoHR')}
					margin={8}
					width={150}
				/>

				{/* Filtre 4: Par poste (liste des postes sur le profil d'une personne) */}
				<GradeSelect {...form.field('gradeId')} label="Poste" selectAll />
			</Box>

			<Modal open={openGradeModal} onClose={() => setOpenGradeModal(false)}>
				<GradesForm />
			</Modal>

			{/* Table */}
			<Title>Liste des tricoteurs</Title>
			<Box
				my={2}
				style={{
					overflow: 'auto',
					maxWidth: isBigScreen ? 'calc(100vw - 356px)' : 'calc(100vw - 20px)',
					border: '1px solid #CCC',
					borderRadius: '3px',
				}}
			>
				<Table size="medium" classes={{ root: classes.tableRoot }}>
					<TableHead>
						<TableRow>
							<TableCell style={{ borderRight: '1px solid #CCC', position: 'relative' }}>
								<Box style={{ margin: '-20px 0px', height: 0, minWidth: 200 }}>
									<TextInput
										onChange={(value) => {
											setSearchStringPreview(value);
											clearTimeout(timeout.current.timeout);
											timeout.current.timeout = setTimeout(() => setSearchString(value), 200);
										}}
										style={{ padding: 0 }}
										variant="outlined"
										size="small"
										label="Nom"
										icon={<Search onClick={() => setSearchString(searchStringPreview)} />}
									/>
								</Box>
							</TableCell>
							<TableCell>Poste</TableCell>
							<TableCell>Mission en cours</TableCell>
							<TableCell>Naissance</TableCell>
							<TableCell>Mail</TableCell>
							<TableCell>Téléphone</TableCell>
							<TableCell>Vêtements</TableCell>
							<TableCell>Droit image</TableCell>
							<TableCell>RGPD</TableCell>
							<TableCell>Mutuelle</TableCell>
							{/* <TableCell>Adresse</TableCell>
							<TableCell>CP</TableCell>
							<TableCell>Ville</TableCell>
							<TableCell>Nationalité</TableCell>
							<TableCell>Fin titre séjour</TableCell>
							<TableCell>NB Mission</TableCell>
							<TableCell>Sexe</TableCell>
							<TableCell>Matricule</TableCell>
							<TableCell>Contrat</TableCell>
							<TableCell>TX Horaire</TableCell>
							<TableCell>Fin de contrat</TableCell>
							<TableCell>Horaire Hebdo</TableCell>
							<TableCell>Heures mois</TableCell>
							<TableCell>Absences</TableCell>
							<TableCell>Retards</TableCell>
							<TableCell>Role</TableCell> */}
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<Box display="flex" justifyContent="center" my={8} width="100%">
								<CircularProgress />
							</Box>
						) : (
							users &&
							users.length &&
							users.slice(-rowsPerPage + page * rowsPerPage, page * rowsPerPage).map((row) => (
								<TableRow
									key={row.id}
									style={{
										cursor: 'pointer',
										backgroundColor: row.state !== States.Actif ? '#c4c4c4' : 'undefined',
									}}
									onClick={() =>
										history.push(
											`/ressources-humaines/${
												user && user.client && user.client.id === 1 ? '' : 'modifier/'
											}${row.id}`,
										)
									}
								>
									<TableCell component="th" scope="row" style={{ borderRight: '1px solid #CCC' }}>
										<Box display="flex" alignItems="center" gridGap={8}>
											<Box display="flex">
												{row.avatarPath ? (
													<Box
														style={{
															borderRadius: 3,
															height: 25,
															width: 25,
															background: `center / cover no-repeat url(${row.avatarPath})`,
														}}
													/>
												) : (
													<AccountCircle />
												)}
											</Box>
											<Box>
												{row.firstName} {row.lastName}
											</Box>
											{!row.socialSecurity && (
												<Box display="flex">
													<FiberNew />
												</Box>
											)}
											{needAnUpdate(row.updateHRInfosDate) && (
												<Box display="flex">
													<Update />
												</Box>
											)}
										</Box>
									</TableCell>
									<TableCell align="left">{Grades[row.gradeId]}</TableCell>
									<TableCell align="center">{row.currentMissionTricoCode}</TableCell>
									<TableCell align="left">
										{row.birthdate ? moment(row.birthdate).format('DD/MM/YYYY') : ''}
									</TableCell>
									<TableCell align="left">{row.email}</TableCell>
									<TableCell align="left">{row.phoneNumber}</TableCell>
									<TableCell align="left">{(row.clothingSize || '').toUpperCase()}</TableCell>
									<TableCell align="left">{row.pictureRights ? 'Oui' : 'Non'}</TableCell>
									<TableCell align="left">{row.rgpd ? 'Oui' : 'Non'}</TableCell>
									<TableCell align="left">
										{row.mutuelle ? 'Oui ' + row?.mutuelleOption : 'Non'}
									</TableCell>
									{/* <TableCell align="left">{row.address?.address}</TableCell>
									<TableCell align="left">{row.address?.zipCode}</TableCell>
									<TableCell align="left">{row.address?.city}</TableCell>
									<TableCell align="left">{row.nationality}</TableCell>
									<TableCell align="left">
										{row.residencePermetExpiryDate
											? moment(row.residencePermetExpiryDate).format('DD/MM/YYYY')
											: ''}
									</TableCell>
									<TableCell align="left">{row.nbMissions}</TableCell>
									<TableCell align="left">{(row.gender || '?')[0].toUpperCase()}</TableCell>
									<TableCell align="left">{row.tricoId}</TableCell>
									<TableCell align="left">{row.contract}</TableCell>
									<TableCell align="left">{row.hourlyRate}€</TableCell>
									<TableCell align="left">
										{row.contractEndDate ? moment(row.contractEndDate).format('DD/MM/YYYY') : ''}
									</TableCell>
									<TableCell align="left">{'5,5H'}</TableCell>
									<TableCell align="left">{'20'}</TableCell>
									<TableCell align="left">{row.totalAbsent}</TableCell>
									<TableCell align="left">{row.totalLate}</TableCell>
									<TableCell align="left">
										{row.position && (
											<Chip variant="outlined" color="primary" size="small" label={row.position} />
										)}
									</TableCell> */}
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</Box>
			<Box p={1} display="flex" justifyContent="end">
				<Pagination page={page} onChange={(_, page) => setRequestedPage(page)} count={pageCount} />
			</Box>
		</Box>
	);
};

const useStyles = makeStyles(({ isBigScreen }: { isBigScreen?: boolean }) => ({
	excelButton: {
		padding: '8px 8px',
		minWidth: 10,
		marginRight: 5,
	},
	select: {
		margin: 9,
		width: 350,
		flexGrow: 1,
	},
	tabs: {
		marginBottom: -1,
		'& button': {
			borderColor: '#CCC',
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
			marginRight: -1,
			fontSize: 12,
			padding: '10px 15px',
		},
	},
	tableRoot: {
		whiteSpace: 'nowrap',
		'& thead th': {
			textTransform: 'uppercase',
			color: '#444',
			fontSize: 11.5,
		},
		'& tbody td': {},
		'& tbody tr:hover': {
			backgroundColor: '#FAFAFA',
			boxShadow: 'inset 3px 0px 0px 0px #0093EE',
		},
	},
}));

export default HumanResources;
