import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import HumanResources from './HumanResources';
import CreateUser from './CreateUser';
import EditUser from './EditUser';
import UserDetails from './UserDetails';
import useAuth from '../../hooks/useAuth';

const HumanResourcesPages = ({ match }: { match: { path: string } }) => {
	const { path } = useRouteMatch();
	const { user } = useAuth();

	return (
		<Switch>
			<Route exact path={`${path}`} component={HumanResources} />
			<Route path={`${path}/creation`} component={CreateUser} />
			<Route path={`${path}/modifier/:id`} component={EditUser} />
			{user && user.client && user.client.id === 1 ? (
				<Route path={`${path}/:id`} component={UserDetails} />
			) : null}
		</Switch>
	);
};

export default HumanResourcesPages;
