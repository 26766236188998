import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import GenericMissionPage from '../humanRessources/payroll/GenericMissionPage';
import MissionAccounting from './MissionAccounting';

const AccountingPages = ({ match }: { match: { path: string } }) => {
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}/mission/:missionId`} component={MissionAccounting} />
			<Route path={`${path}/mission`} component={GenericMissionPage} />
			<Route path={`${path}/rot`} component={MissionAccounting} />
		</Switch>
	);
};

export default AccountingPages;
