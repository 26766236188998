import React from 'react';
import {
	Box,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Typography,
} from '@material-ui/core';
import Switch from '../form/Switch';
import Title from '../Title';
import { useDonationDetailsStyles } from './WelcomeCallsDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DonationDto } from '../../dto/donation';
import useFetch from '../../hooks/useFetch';
import routes from '../../api/routes';

const DonationCommunication = ({ donation }: { donation: DonationDto }) => {
	const classes = useDonationDetailsStyles();
	const oldFormVersion = donation.commQualitative === null;
	const [, association] = useFetch(
		routes.associations.associationWithMissionId({ id: donation.missionId }),
		[donation.missionId],
	);

	return (
		<ExpansionPanel className={classes.panel}>
			<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary}>
				<Typography>Communication</Typography>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>
				<Box display="flex" flexWrap="wrap" maxWidth={400}>
					{oldFormVersion ? (
						<>
							<Box px={2} mb={3} textAlign="center" flexGrow={1}>
								<Title small>
									Newsletters
									<br />
								</Title>
								<Switch value={donation.receivesNewsletters} />
							</Box>
							<Box px={2} mb={3} textAlign="center" flexGrow={1}>
								<Title small>
									Magazine <br />
								</Title>
								<Switch value={donation.receivesMagazine} />
							</Box>
						</>
					) : (
						<>
							{association?.commQualitative && (
								<Box px={2} mb={3} textAlign="center" flexGrow={1}>
									<Title small>
										Contacter veille
										<br />
										qualitative
									</Title>
									<Switch value={donation.commQualitative} />
								</Box>
							)}
							{association?.commEmail && (
								<Box px={2} mb={3} textAlign="center" flexGrow={1}>
									<Title small>Contacter par mail</Title>
									<Switch value={donation.commEmail} />
								</Box>
							)}
							{association?.commSms && (
								<Box px={2} mb={3} textAlign="center" flexGrow={1}>
									<Title small>Contacter par sms</Title>
									<Switch value={donation.commSms} />
								</Box>
							)}
							{association?.commMail && (
								<Box px={2} mb={3} textAlign="center" flexGrow={1}>
									<Title small>Contacter par courrier</Title>
									<Switch value={donation.commMail} />
								</Box>
							)}
							{association?.commPhone && (
								<Box px={2} mb={3} textAlign="center" flexGrow={1}>
									<Title small>Contacter par tel</Title>
									<Switch value={donation.commPhone} />
								</Box>
							)}
							{association?.advertisement && (
								<Box px={2} mb={3} textAlign="center" flexGrow={1}>
									<Title small>
										Propositions
										<br />
										d'autres organismes
									</Title>
									<Switch value={donation.advertisement} />
								</Box>
							)}
							{association?.receivesMagazine && (
								<Box px={2} mb={3} textAlign="center" flexGrow={1}>
									<Title small>Journal par courrier</Title>
									<Switch value={donation.receivesMagazine} />
								</Box>
							)}
							{association?.receivesNewsletters && (
								<Box px={2} mb={3} textAlign="center" flexGrow={1}>
									<Title small>Journal par mail</Title>
									<Switch value={donation.receivesNewsletters} />
								</Box>
							)}
							{association?.subscribe && (
								<Box px={2} mb={3} textAlign="center" flexGrow={1}>
									<Title small>
										Adhérer à<br />
										l'association
									</Title>
									<Switch value={donation.subscribe} />
								</Box>
							)}
						</>
					)}
					{association?.receivesReceiptByEmail && (
						<Box px={2} textAlign="center" flexGrow={1}>
							<Title small>
								Reçu fiscal
								<br />
								par email
							</Title>
							<Switch value={donation.receivesReceiptByEmail} />
						</Box>
					)}
					{association?.receivesReceiptByLetter && (
						<Box px={2} textAlign="center" flexGrow={1}>
							<Title small>
								Reçu fiscal
								<br />
								par courier
							</Title>
							<Switch value={donation.receivesReceiptByLetter} />
						</Box>
					)}
				</Box>
			</ExpansionPanelDetails>
		</ExpansionPanel>
	);
};
export default DonationCommunication;
