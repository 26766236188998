import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import useForm from '../../../hooks/useForm';
import apiRoutes from '../../../api/routes';
import SubmitButton from '../../../components/form/SubmitButton';
import { Select, NumberInput, TextInput } from '../../../components/humanResources/UserForm';
import Title from '../../../components/Title';
import { useStyles } from '../../../components/interviews/AddUserToMission';
import { MissionPayrollExpenseDisplay, MissionPayrollExpenseDto } from '../../../dto/missionPayrollExpense';
import { DatePickerProps, default as DefaultDatePicker } from '../../../components/form/DatePicker';
import UserSelect, { UserSuggestion } from '../../../components/user/UserSelect';

const DatePicker = (props: DatePickerProps) => {
	return <DefaultDatePicker {...props} format="DD/MM/YYYY" width={'100%'} flexGrow={1} />;
};

type EditMissionPayrollExpenseProps = {
	expense: Partial<MissionPayrollExpenseDto>;
	update: () => void;
	exit: () => void;
};

const EditMissionPayrollExpense = ({ expense, exit, update }: EditMissionPayrollExpenseProps) => {
	const [creator, setCreator] = useState<UserSuggestion>(
		expense.creator
			? {
					id: expense.creator.id + '',
					firstName: expense.creator.firstName,
					lastName: expense.creator.lastName,
					path: '',
			  }
			: null,
	);

	const form = useForm({
		init: {
			id: expense.id,
			missionId: expense.missionId,
			creatorId: expense.creatorId,
			type: expense.type,
			amount: expense.amount,
			text: expense.text,
			date: expense.date,
		},
		url: apiRoutes.missions.payrollExpenses.editOrCreate,
		onSuccess: () => {
			update();
		},
	});

	const classes = useStyles({ border: false });

	return (
		<>
			<Title display="flex" justifyContent="center">
				{expense.id ? 'Modifier' : 'Créer'} une dépense
			</Title>
			<form onSubmit={form.submit}>
				<Box my={2}>
					<UserSelect
						value={creator}
						onChange={(user) => {
							form.field('creatorId').onChange(user?.id ? +user.id : null);
							setCreator(user);
						}}
					/>
				</Box>
				<Box className={classes.fields}>
					<Select
						margin="0 0 16px 0"
						values={MissionPayrollExpenseDisplay}
						{...form.field('type')}
						label="Type"
						required
					/>
					<NumberInput {...form.field('amount')} label="Montant" required margin={0} />
					<DatePicker {...form.field('date')} label="Date" required />
					<TextInput {...form.field('text')} label="Commentaire" margin={0} style={{ marginTop: 16 }} />
				</Box>
				<Box display="flex" justifyContent="space-between">
					<Button onClick={exit}>Annuler</Button>
					<SubmitButton submitting={form.isSubmitting} success={form.success} edit>
						Sauvegarder
					</SubmitButton>
				</Box>
			</form>
		</>
	);
};

export default EditMissionPayrollExpense;
