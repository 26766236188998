import {
	Box,
	Button,
	CircularProgress,
	IconButton,
	Link,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Pagination } from '@material-ui/lab';
import moment from 'moment';
import React, { useState } from 'react';
import { FaFileExcel } from 'react-icons/fa';
import routes from '../../api/routes';
import AssociationSelect from '../../components/associations/AssocationSelect';
import Select from '../../components/form/Select';
import TextInput from '../../components/form/TextInput';
import MissionSelect from '../../components/missions/MissionSelect';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import { UserSuggestion } from '../../components/user/UserSelect';
import {
	DonationListRowDto,
	DonationsListFiltersDto,
	rowsPerPage,
	Grades,
	GradesColor,
} from '../../dto/donation';
import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import TableLastCall from '../../components/donations/TableLastCall';
import WelcomeCallsDetails from '../../components/donations/WelcomeCallsDetails';

const serialize = (data) => encodeURIComponent(JSON.stringify(data));

const WelcomeCalls = () => {
	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));

	const [reloadToken, setReloadToken] = useState(0);
	const [page, setPage] = useState(1);

	const form = useForm({
		init: new DonationsListFiltersDto(),
	});
	const [recruiter] = useState<UserSuggestion>(null);
	const [searchValue, setSearchValue] = useState('');
	const [inputSearch, setInputSearch] = useState('');

	let filters = form.values;
	filters.page = page;
	filters.recruiterId = recruiter?.id;
	filters.donationStatus = 'complete';

	const [sort, setSort] = useState({});

	const [, currentCount] = useFetch<{ count: number }>(
		routes.donations.count({}, { filters: serialize({ ...filters, searchValue: inputSearch }) }),
		[
			filters.recruiterId,
			filters.donationStatus,
			filters.associationId,
			filters.missionIds,
			filters.displayedStatus,
			filters.allowExport,
			filters.donationGrade,
			filters.callLeft,
			filters.welcomeCallLeft,
			reloadToken,
			searchValue,
		],
	);

	const [isLoading, donations, ,] = useFetch<DonationListRowDto[]>(
		routes.donations.all({}, { filters: serialize({ ...filters, searchValue: inputSearch, sort }) }),
		[
			page,
			filters.recruiterId,
			filters.donationStatus,
			filters.associationId,
			filters.missionIds,
			filters.allowExport,
			filters.displayedStatus,
			filters.donationGrade,
			filters.callLeft,
			filters.welcomeCallLeft,
			reloadToken,
			JSON.stringify(sort),
			searchValue,
		],
	);

	const [openDonationId, setOpenDonationId] = useState(null);

	const classes = useStyles({ border: false });
	const { user } = useAuth();

	const columns = [
		{ label: 'RUM', field: 'rum' },
		{ label: 'Date', field: 'datetime' },
		{ label: 'Mission', field: 'tricoCode' },
		{ label: 'Recruteur', field: 'recruiterFirstName' },
		{ label: 'Note', field: 'grade' },
		{ label: 'Nb Appels', field: 'callCount' },
		{ label: 'Status', field: 'status' },
		{ label: 'Commentaire', field: 'comment' },
		{ label: "Autorisation d'export", field: 'export' },
	];

	const sortBy = (field: string) => () => {
		if (sort[field] === 'DESC') {
			setSort({ [field]: 'ASC' });
		} else if (sort[field] === 'ASC') {
			setSort({});
		} else {
			setSort({ [field]: 'DESC' });
		}
	};

	return (
		<Box p={3}>
			<Box mb={2} display="flex" justifyContent="space-between">
				<Title>Liste des bulletins</Title>
				<Box>
					<Link
						href={routes.donations.excel(
							{ clientId: user && user.client ? user.client.id : '0' },
							{ filters: serialize({ ...filters, searchValue: inputSearch }) },
						)}
					>
						<Button className={classes.excelButton} size="small" variant="contained" color="primary">
							<FaFileExcel size={16} />
						</Button>
					</Link>
				</Box>
			</Box>

			{openDonationId && (
				<Modal open onClose={() => setOpenDonationId(null)}>
					<WelcomeCallsDetails
						reload={() => setReloadToken(reloadToken + 1)}
						exit={() => setOpenDonationId(null)}
						expandedSection="personnal_information"
						donationId={openDonationId}
					/>
				</Modal>
			)}
			<Box width="100%" display="flex" marginBottom={'8px'} alignItems={'center'}>
				<TextInput
					label="Recherche"
					width={'95%'}
					style={{ marginRight: '8px' }}
					value={inputSearch}
					onChange={(e) => setInputSearch(e)}
				/>
				<Button
					onClick={() => setSearchValue(inputSearch)}
					className={classes.excelButton}
					size="medium"
					variant="contained"
					color="primary"
				>
					Rechercher
				</Button>
			</Box>

			<Box display="flex" flexWrap="wrap" m={-0.8}>
				<AssociationSelect {...form.field('associationId')} margin={8} />
				<MissionSelect
					associationId={filters.associationId}
					multiple
					{...form.field('missionIds')}
					margin={8}
				/>
				<Select
					margin={8}
					values={{ '': 'Toute la sélection', ...Grades }}
					label="Note"
					{...form.field('donationGrade')}
				/>
				<Select
					margin={8}
					label="Nb d'appels restants"
					{...form.field('welcomeCallLeft')}
					values={{ '': 'Toute la sélection', '0': '0', '1': '1', '2': '2', '3': '3' }}
				/>
			</Box>

			<Box
				my={2}
				p={2}
				style={{
					border: '1px solid #CCC',
					borderRadius: '3px',
				}}
			>
				Il y a <b>{currentCount?.count || 0}</b> bulletins correspondant à vos filtres.
			</Box>

			<Box
				my={2}
				style={{
					overflow: 'auto',
					maxWidth: isBigScreen ? 'calc(100vw - 346px)' : 'calc(100vw - 20px)',
					border: '1px solid #CCC',
					borderRadius: '3px',
					marginTop: 15,
				}}
			>
				<Table size="medium" classes={{ root: classes.tableRoot }}>
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell style={{ cursor: 'pointer' }}>
									<Box
										display="flex"
										flexDirection="row"
										justifyContent="space-between"
										alignItems="center"
										onClick={
											column.field !== 'comment' &&
											column.field !== 'status' &&
											sortBy(column.field)
										}
									>
										<Box>{column.label}</Box>
										{column.field !== 'comment' &&
											column.field !== 'status' &&
											column.field !== 'export' && (
												<IconButton size="small" style={{ marginLeft: 10 }}>
													<ArrowDownwardIcon
														style={{
															fontSize: 14,
															transition: 'transform 0.3s',
															transform:
																sort[column.field] === 'ASC'
																	? 'rotate(180deg)'
																	: 'rotate(0deg)',
														}}
														fontSize="small"
													/>
												</IconButton>
											)}
									</Box>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					{!isLoading && (
						<TableBody>
							{donations &&
								donations.map((row, i) => (
									<TableRow
										key={row.id}
										style={{ cursor: 'pointer' }}
										onClick={() => {
											setOpenDonationId(row.id);
										}}
									>
										<TableCell style={{ color: GradesColor[row.donationGrade] }}>
											{row.rum}
										</TableCell>
										<TableCell style={{ color: GradesColor[row.donationGrade] }}>
											{row.datetime ? moment(row.datetime).format('DD/MM/YYYY') : ''}
										</TableCell>
										<TableCell style={{ color: GradesColor[row.donationGrade] }}>
											{row.tricoCode}
										</TableCell>
										<TableCell style={{ color: GradesColor[row.donationGrade] }}>
											{row.recruiterFirstName} {row.recruiterLastName}
										</TableCell>
										<TableCell style={{ color: GradesColor[row.donationGrade] }}>
											{row.donationGrade}
										</TableCell>
										<TableCell style={{ color: GradesColor[row.donationGrade] }}>
											{parseInt((row.welcomeCallCount as any) || '0')}
										</TableCell>
										<TableLastCall
											allowExport={row.allowExport}
											donationId={row.id}
											color={GradesColor[row.donationGrade]}
										/>
									</TableRow>
								))}
						</TableBody>
					)}
				</Table>

				{isLoading && (
					<Box display="flex" width="100%" justifyContent="center" my={8}>
						<CircularProgress />
					</Box>
				)}

				<Box p={1} display="flex" justifyContent="end">
					<Pagination
						page={page}
						onChange={(_, page) => {
							setPage(page);
						}}
						count={Math.ceil((currentCount?.count || 0) / rowsPerPage)}
					/>
				</Box>
			</Box>
		</Box>
	);
};

const useStyles = makeStyles(({ isBigScreen }: { isBigScreen?: boolean }) => ({
	tabs: {
		marginBottom: -1,
		'& button': {
			borderColor: '#CCC',
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
			fontSize: 12,
			padding: '10px 15px',
		},
	},
	tableRoot: {
		whiteSpace: 'nowrap',
		'& thead th': {
			textTransform: 'uppercase',
			color: '#444',
			fontSize: 11.5,
		},
		'& tbody tr.noBottomBorder > td': {
			borderBottom: 'none',
		},
		'& tbody tr:not(.comment):hover': {
			backgroundColor: '#FAFAFA',
			boxShadow: 'inset 3px 0px 0px 0px #0093EE',
		},
	},
	excelButton: {
		padding: '8px 8px',
		minWidth: 10,
		marginRight: 5,
	},
}));

export default WelcomeCalls;
