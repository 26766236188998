import React, { useState } from 'react';
import { Box, Typography, Paper } from '@material-ui/core';
import Title from '../components/Title';
import AssociationSelect from '../components/associations/AssocationSelect';
import DatePicker from '../components/form/DatePicker';
import MissionsTable from '../components/missions/MissionsTable';
import { AssociationFiltersDto } from '../dto/association';
import useFetch from '../hooks/useFetch';
import { AssociationStats, calculateStats } from '../dto/associationUtils';
import routes from '../api/routes';
import { CardContainer, Card } from '../components/Cards';
import AgeStats from '../components/missions/AgeStats';
import PrimaryButton from '../components/PrimaryButton';
import SendIcon from '@material-ui/icons/Send';
import Modal from '../components/Modal';
import MessageForm from '../components/missions/MessageForm';
import { formatNumber } from '../utils';

const Home = () => {
	const [associationId, setAssociationId] = useState<number>();
	const [periodStart, setPeriodStart] = useState(null);
	const [periodEnd, setPeriodEnd] = useState(null);
	const filters = new AssociationFiltersDto();
	filters.periodStart = periodStart;
	filters.periodEnd = periodEnd;
	filters.associationId = associationId;
	filters.runningMissionsOnly = true;
	const [statsLoading, dashboardStats] = useFetch<AssociationStats>(
		routes.associations.dashboardStats({}, filters),
		[periodStart, periodEnd, associationId],
	);
	const dstats = calculateStats(dashboardStats || ({} as any));
	const [showMessageModal, setShowMessageModal] = useState(false);

	return (
		<Box px={3} py={3}>
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<Title>Accueil</Title>
				<Box>
					<PrimaryButton onClick={() => setShowMessageModal(true)}>
						<Box mr={1.5} mt={1}>
							{' '}
							<SendIcon />{' '}
						</Box>
						Envoyer un message
					</PrimaryButton>
				</Box>
			</Box>

			{showMessageModal && (
				<Modal open onClose={() => setShowMessageModal(false)}>
					<Box p={2}>
						<MessageForm onClose={() => setShowMessageModal(false)} />
					</Box>
				</Modal>
			)}

			{/* Filters */}
			<Box py={2}>
				<Box display="flex" mx={-1} flexWrap="wrap">
					<AssociationSelect
						value={associationId}
						onChange={(value) => setAssociationId(value)}
						label="Association"
						required
						width={350}
						flexGrow={1}
						margin={8}
						variant="outlined"
					/>
					<DatePicker
						format="DD/MM/YYYY"
						label="Début de la période"
						width={350}
						flexGrow={1}
						margin={8}
						value={periodStart}
						onChange={(date) => setPeriodStart(date)}
					/>
					<DatePicker
						format="DD/MM/YYYY"
						label="Fin de la période"
						width={350}
						flexGrow={1}
						margin={8}
						value={periodEnd}
						onChange={(date) => setPeriodEnd(date)}
					/>
				</Box>
			</Box>

			<Title mt={1}>Récapitulatif des missions en cours</Title>
			{!statsLoading && (
				<CardContainer>
					<Card title="tx réel">
						<Typography style={{ color: '#9ACD33', fontSize: 20 }}>
							{formatNumber(dstats.realRate, 2)}
						</Typography>
					</Card>
					<Card title="don moyen">
						<Typography style={{ color: '#ba5ae2', fontSize: 20 }}>
							{formatNumber(dstats.averageDonation, 2)} €
						</Typography>
					</Card>
					<Card title="total don">{formatNumber(dstats.donationsTotalAmout, 2)} €</Card>
					<Card title="age moyen">
						<Typography style={{ color: '#EE814F', fontSize: 20 }}>
							{formatNumber(dstats.averageAge, 2)} ans
						</Typography>
					</Card>
					<Card title="% homme">{formatNumber(dstats.malePercent, 2)} %</Card>
					<Card title="% femme">{formatNumber(dstats.femalePercent, 2)} %</Card>
					<Card title="RD en mission">
						<Typography style={{ color: '#1D80FF', fontSize: 22 }}>
							{formatNumber(dstats.activeRecruitersCount, 2)}
						</Typography>
					</Card>
				</CardContainer>
			)}

			<Title mb={2}>Répartition des âges</Title>
			<Paper variant="outlined">
				<Box p={2} display="flex" alignItems="center" flexDirection="column">
					<Box display="flex" width="80vw" maxWidth={400}>
						<AgeStats
							periodStart={periodStart}
							periodEnd={periodEnd}
							associationId={associationId}
							runningMissionsOnly={true}
						/>
					</Box>
				</Box>
			</Paper>

			{/* Table */}
			<Title mt={3}>Liste des missions</Title>
			<MissionsTable
				periodStart={periodStart}
				periodEnd={periodEnd}
				associationId={associationId}
				clearPeriod={() => {
					setPeriodStart(null);
					setPeriodEnd(null);
				}}
			/>
		</Box>
	);
};

export default Home;
