import { Box } from '@material-ui/core';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Pie } from 'react-chartjs-2';
import routes from '../../api/routes';
import { DonationFiltersDto } from '../../dto/donation';
import useFetch from '../../hooks/useFetch';

Chart.register(...registerables, ChartDataLabels);

const AgeStats = ({
	missionId,
	recruiterId,
	runningMissionsOnly,
	associationId,
	periodStart,
	periodEnd,
}: DonationFiltersDto) => {
	let [ageStatsAreLoading, ageStats] = useFetch<Record<string, number>>(
		routes.donations.ageStatsForMission(
			{},
			{ missionId, recruiterId, runningMissionsOnly, associationId, periodStart, periodEnd },
		),
		[missionId, runningMissionsOnly, associationId, periodStart, periodEnd, recruiterId],
	);
	if (ageStatsAreLoading) return <Box>chargement...</Box>;

	let colors = ['#8D4747', '#c05757', '#ee814f', '#F4BC1C', '#9ACD32', '#62be7e'];
	let data = {
		labels: Object.keys(ageStats),
		datasets: [
			{
				data: Object.values(ageStats),
				backgroundColor: colors,
				hoverBackgroundColor: colors,
			},
		],
	};

	return (
		<Pie
			data={data}
			width={400}
			height={500}
			options={{
				plugins: {
					datalabels: {
						formatter: (value, ctx) => {
							let sum = 0;
							let dataArr = ctx.chart.data.datasets[0].data;
							dataArr.forEach((data) => {
								sum += data as number;
							});
							let percentage = Math.round((value * 100) / sum) + '%';
							return percentage;
						},
						color: '#fff',
					},
					legend: {
						position: 'bottom',
						// fullWidth: false,
						labels: {
							usePointStyle: true,
							padding: 25,
						},
					},
				},
			}}
		/>
	);
};

export default AgeStats;
