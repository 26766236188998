import React, { useEffect, useState } from 'react';
import { TableCell } from '@material-ui/core';
import { DonorCallDto } from '../../dto/donation';
import routes from '../../api/routes';
import useFetch from '../../hooks/useFetch';
import moment from 'moment';
import { Checkbox } from '@mui/material';
import useForm from '../../hooks/useForm';

const TableLastCall = ({
	donationId,
	color,
	allowExport,
}: {
	donationId: number;
	color: string;
	allowExport: boolean;
}) => {
	const [, calls] = useFetch(routes.donations.welcomeCalls.forDonation({ id: donationId }));
	const [call, setCall] = useState<DonorCallDto>();

	const allowExportForm = useForm({
		init: { allowExport: allowExport },
		url: routes.donations.updateDonationAllowExport({ donationId }),
	});

	useEffect(() => {
		if (calls) {
			const sortCalls = [...calls].sort((a, b) =>
				moment(b.callDatetime) > moment(a.callDatetime) ? 1 : -1,
			);
			setCall(sortCalls[0]);
		}
	}, [calls, call]);

	const status = {
		responded: 'a répondu',
		not_responded: "n'a pas répondu",
		unknown: '',
	};

	return (
		<>
			<TableCell style={{ color }}>{call && status[call?.status]}</TableCell>
			<TableCell style={{ color }}>{call && call?.comment}</TableCell>
			<TableCell>
				<Checkbox
					size="small"
					checked={allowExportForm.values.allowExport}
					onClick={(e) => {
						e.stopPropagation();
						allowExportForm.values.allowExport = !allowExportForm.values.allowExport;
						allowExportForm.submit();
					}}
				/>
			</TableCell>
		</>
	);
};

export default TableLastCall;
