import { Role } from './role';
import { Category, ETAMCategoryOption, ICCategoryOption } from './user';

export class GradeDto {
	id: number;
	name: string = '';
	description: string = '';
	hourlyRate: number;

	category?: Category;
	categoryOption?: ETAMCategoryOption | ICCategoryOption;
	role?: Role;
	archived?: boolean;
}

export class GradeEvolutionDto {
	id: number;
	userId: string;
	grade: GradeDto;
	gradeId: string;
	date: string;
}
