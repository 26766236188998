import removeAccents from 'remove-accents';

export function formatNumber(number: number | string, nbOfDecimals: number = 0): string {
	const factor = 10 ** nbOfDecimals;
	const parsedNumber = typeof number === 'number' ? number : parseFloat(number);
	return String(Math.round((parsedNumber || 0) * factor) / factor).replace('.', ',');
}

export default function normalizeString(string) {
	return removeAccents(string).toLowerCase();
}

export const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);
