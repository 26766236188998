import { Box, Link } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import React, { useState } from 'react';
import { FileDto } from '../../dto/file';
import FilePickerModal from './FilePickerModal';
import FilePreview from './FilePreview';

export type FilePickerProps = {
	category?: string;
	value?: FileDto;
	onChange?: (file: FileDto) => any;
	label?: string;
	accept?: string;
};
const FilePicker = ({ value, onChange, category, label, accept }: FilePickerProps) => {
	let [open, setOpen] = useState(false);
	return (
		<>
			<Link onClick={() => setOpen(true)}>
				{value ? (
					<Box my={2}>
						<FilePreview file={value} />
					</Box>
				) : (
					<Box display="flex" alignItems="center" my={2} mt={1}>
						<AddCircle />
						<Box mx={1}>{label}</Box>
					</Box>
				)}
			</Link>
			<FilePickerModal
				category={category}
				accept={accept}
				open={open}
				onClose={() => setOpen(false)}
				onChange={onChange}
			/>
		</>
	);
};

export default FilePicker;
