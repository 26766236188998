import {
	Box,
	Breadcrumbs,
	Button,
	CircularProgress,
	InputLabel,
	Link,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Add, SupervisedUserCircle } from '@material-ui/icons';
import React from 'react';
import { FaFileExcel } from 'react-icons/fa';
import { Link as RouterLink, useHistory, useRouteMatch } from 'react-router-dom';
import routes from '../../api/routes';
import AssociationSelect from '../../components/associations/AssocationSelect';
import DatePicker from '../../components/form/DatePicker';
import NumberInput from '../../components/form/NumberInput';
import Select from '../../components/form/Select';
import Switch from '../../components/form/Switch';
import MissionSelect from '../../components/missions/MissionSelect';
import Title from '../../components/Title';
import UserSelect from '../../components/user/UserSelect';
import { AssociationFiltersDto } from '../../dto/association';
import { AssociationStats, calculateStats } from '../../dto/associationUtils';
import { MissionTypes } from '../../dto/mission';
import { ProgramType } from '../../dto/program';
import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import { formatNumber } from '../../utils';

let persistentFilters: AssociationFiltersDto;

const Associations = () => {
	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));
	const history = useHistory();
	const form = useForm({ init: persistentFilters || new AssociationFiltersDto() });
	const [statsLoading, dashboardStats] = useFetch<AssociationStats>(
		routes.associations.dashboardStats({}, form.values),
		[form.values],
	);
	const [isLoading, associationStats] = useFetch<AssociationStats[]>(
		routes.associations.listStats({}, form.values),
		[form.values],
	);
	const dstats = calculateStats(dashboardStats || ({} as any));
	const associations = (associationStats && associationStats.length ? associationStats : []).map(
		calculateStats,
	);
	const classes = useStyles({ border: false });
	const selectClasses = { formControl: classes.select };
	const { url } = useRouteMatch();
	const programTypeField = (name: ProgramType) => ({
		value: form.field('programTypes').value.includes(name),
		onChange: (isIncluded: boolean) =>
			form
				.field('programTypes')
				.onChange(
					isIncluded
						? Array.from(new Set([name, ...form.field('programTypes').value]))
						: form.field('programTypes').value.filter((val) => val !== name),
				),
	});
	const { user } = useAuth();

	return (
		<Box px={3} py={3}>
			{/* Header */}
			<Box display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center">
				<Breadcrumbs aria-label="breadcrumb">
					<Typography color="textPrimary">Associations</Typography>
				</Breadcrumbs>
				<Box>
					<Link
						href={routes.associations.excel(
							{ clientId: user && user.client ? user.client.id : '0' },
							form.values,
						)}
						target="_blank"
					>
						<Button className={classes.excelButton} size="small" variant="contained" color="primary">
							<FaFileExcel size={16} />
						</Button>
					</Link>
					<RouterLink to={`${url}/creation`}>
						<Button size="small" variant="contained" color="primary">
							<Add /> Créer
						</Button>
					</RouterLink>
				</Box>
			</Box>

			{/* Filters */}
			<Box py={2}>
				<Box display="flex" mx={-1} flexWrap="wrap">
					<AssociationSelect
						{...form.field('associationId')}
						classes={selectClasses}
						label="Association"
						width={350}
					/>
					<MissionSelect
						{...form.field('missionId')}
						classes={selectClasses}
						associationId={form.values.associationId}
						width={350}
					/>
					<DatePicker
						{...form.field('periodStart')}
						format="DD/MM/YYYY"
						label="Début de la période"
						width={350}
						flexGrow={1}
						margin={8}
					/>
					<DatePicker
						{...form.field('periodEnd')}
						format="DD/MM/YYYY"
						label="Fin de la période"
						width={350}
						flexGrow={1}
						margin={8}
					/>
					<Select
						{...form.field('missionType')}
						width={350}
						values={MissionTypes}
						classes={selectClasses}
						fullWidth
						label="Type mission"
						variant="outlined"
					/>
					<NumberInput
						{...form.field('minimumDonation')}
						classes={selectClasses}
						fullWidth
						label="Don minimum"
						variant="outlined"
					/>
					<Box width={350} flexGrow={1} style={{ margin: 8 }}>
						<UserSelect onChange={(user) => form.field('recruiterId').onChange(user?.id || '')} />
					</Box>
				</Box>
				<Box display="flex">
					<Box display="flex" alignItems="center" mr={2}>
						<InputLabel>Rue</InputLabel>
						<Switch {...programTypeField('rue')} />
					</Box>
					<Box display="flex" alignItems="center">
						<InputLabel>CC</InputLabel>
						<Switch {...programTypeField('CC')} />
					</Box>
					<Box display="flex" alignItems="center">
						<InputLabel>Mixte</InputLabel>
						<Switch {...programTypeField('mixte')} />
					</Box>
					<Box display="flex" alignItems="center">
						<InputLabel>PAP</InputLabel>
						<Switch {...programTypeField('PAP')} />
					</Box>
				</Box>
			</Box>

			{/* Stats */}
			<Title>Tableau de bord</Title>
			{!statsLoading && (
				<Box className={classes.cardsContainer} display="flex" my={3} flexWrap="wrap">
					<Card title="heures rue">
						<Typography style={{ color: '#3f51b5', fontSize: 20 }}>
							{formatNumber(dstats.streetHours, 2)} h
						</Typography>
					</Card>
					<Card title="heures payées">{formatNumber(dstats.paidHours, 2)} h</Card>
					<Card title="bs rue">{formatNumber(dstats.donationsTotalCount, 2)}</Card>
					<Card title="bs réel">
						<Typography style={{ color: '#61BE7E', fontSize: 20 }}>
							{formatNumber(dstats.completedDonationsCount, 2)}
						</Typography>
					</Card>
					<Card title="Taux transformation">{formatNumber(dstats.transformationRate, 2)} %</Card>
					<Card title="tx rue">{formatNumber(dstats.streetRate, 2)}</Card>
					<Card title="tx réel">
						<Typography style={{ color: '#9ACD33', fontSize: 20 }}>
							{formatNumber(dstats.realRate, 2)}
						</Typography>
					</Card>
					<Card title="don moyen">
						<Typography style={{ color: '#ba5ae2', fontSize: 20 }}>
							{formatNumber(dstats.averageDonation, 2)} €
						</Typography>
					</Card>
					<Card title="total don">{formatNumber(dstats.donationsTotalAmout, 2)} €</Card>
					<Card title="age median">{formatNumber(dstats.medianAge, 2)} ans</Card>
					<Card title="age moyen">
						<Typography style={{ color: '#EE814F', fontSize: 20 }}>
							{formatNumber(dstats.averageAge, 2)} ans
						</Typography>
					</Card>
					<Card title="% homme">{formatNumber(dstats.malePercent, 2)} %</Card>
					<Card title="% femme">{formatNumber(dstats.femalePercent, 2)} %</Card>
					<Card title="h rue/h payées">{dstats.streetPaidHoursRatio} %</Card>
					<Card title="bs envoyés">{dstats.sentDonations}</Card>
					<Card title="% 18-20">
						<Typography style={{ fontSize: 20 }}>{formatNumber(dstats._18_20_percent, 2)} %</Typography>
					</Card>
					<Card title="% 18-30">
						<Typography style={{ fontSize: 20 }}>{formatNumber(dstats._18_30_percent, 2)} %</Typography>
					</Card>
					<Box className={classes.containerBorders}></Box>
				</Box>
			)}

			{/* Table */}
			<Title>Liste des associations</Title>
			<Box
				style={{
					overflow: 'auto',
					maxWidth: isBigScreen ? 'calc(100vw - 356px)' : 'calc(100vw - 20px)',
					border: '1px solid #CCC',
					borderRadius: '3px',
				}}
				my={2}
			>
				{isLoading ? (
					<Box display="flex" justifyContent="center" my={8}>
						<CircularProgress />
					</Box>
				) : (
					<Table size="medium" classes={{ root: classes.tableRoot }}>
						<TableHead>
							<TableRow>
								<TableCell>ASSOCIATION</TableCell>
								<TableCell>M EN COURS</TableCell>
								<TableCell>H RUE</TableCell>
								<TableCell> PAYÉES</TableCell>
								<TableCell>BS RUE</TableCell>
								<TableCell>BS RÉEL</TableCell>
								<TableCell>TX TRANS</TableCell>
								<TableCell>TX RUE</TableCell>
								<TableCell>TX RÉEL</TableCell>
								<TableCell>DON MOYEN</TableCell>
								<TableCell>TOTAL DON</TableCell>
								<TableCell>AGE MEDIAN</TableCell>
								<TableCell>AGE MOYEN</TableCell>
								<TableCell>% 18 - 20</TableCell>
								<TableCell>% 18 - 30</TableCell>
								<TableCell>% H</TableCell>
								<TableCell>% F</TableCell>
								<TableCell>H RUE / H PAYÉES</TableCell>
								<TableCell>BS ENVOYÉS</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{associations.map((row, i) => (
								<TableRow
									key={row.name + i}
									style={{ cursor: 'pointer' }}
									onClick={() => {
										persistentFilters = form.values;
										history.push(`/associations/${row.id}`);
									}}
								>
									<TableCell component="th" scope="row">
										<Box display="flex" alignItems="center">
											<Box m={1}>
												{row.imagePath ? (
													<Box
														width={24}
														height={24}
														style={{
															borderRadius: 2,
															backgroundSize: 'cover',
															backgroundImage: `url(${row.imagePath})`,
														}}
													/>
												) : (
													<SupervisedUserCircle />
												)}
											</Box>
											{row.name}
										</Box>
									</TableCell>
									<TableCell align="right">{formatNumber(row.runningMissions, 2)}</TableCell>
									<TableCell align="right">{formatNumber(row.streetHours, 2)}h</TableCell>
									<TableCell align="right">{formatNumber(row.paidHours, 2)}h</TableCell>
									<TableCell align="right">{formatNumber(row.donationsTotalCount, 2)}</TableCell>
									<TableCell align="right">{formatNumber(row.completedDonationsCount, 2)}</TableCell>
									<TableCell align="right">{formatNumber(row.transformationRate, 2)} %</TableCell>
									<TableCell align="right">{formatNumber(row.streetRate, 2)}</TableCell>
									<TableCell align="right">{formatNumber(row.realRate, 2)}</TableCell>
									<TableCell align="right">{formatNumber(row.averageDonation, 2)} €</TableCell>
									<TableCell align="right">{formatNumber(row.donationsTotalAmout, 2)} €</TableCell>
									<TableCell align="right">{formatNumber(row.medianAge, 2)}</TableCell>
									<TableCell align="right">{formatNumber(row.averageAge, 2)}</TableCell>
									<TableCell align="right">{formatNumber(row._18_20_percent, 2)} %</TableCell>
									<TableCell align="right">{formatNumber(row._18_30_percent, 2)} %</TableCell>
									<TableCell align="right">{formatNumber(row.malePercent, 2)} %</TableCell>
									<TableCell align="right">{formatNumber(row.femalePercent, 2)} %</TableCell>
									<TableCell align="right">{formatNumber(row.streetPaidHoursRatio, 2)}</TableCell>
									<TableCell align="right">{formatNumber(row.sentDonations, 2)}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				)}
			</Box>
		</Box>
	);
};

const useStyles = makeStyles(({ isBigScreen }: { isBigScreen?: boolean }) => ({
	excelButton: {
		padding: '8px 8px',
		minWidth: 10,
		marginRight: 5,
	},
	cardsContainer: {
		position: 'relative',
		borderRadius: '3px',
		paddingRight: 2,
		paddingLeft: 2,
	},
	containerBorders: {
		boxSizing: 'border-box',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		border: '10px solid white',
		borderTop: '5px solid white',
		borderBottom: '5px solid white',
		borderRadius: '3px',
		boxShadow: '0px 0px 0px 1px #CCC',
	},
	card: {
		boxSizing: 'border-box',
		padding: '15px 5px',
		height: 80,
		width: 150,
		border: '1px solid #CCC',
		marginRight: -1,
		marginBottom: -1,
		flexGrow: 1,
		textAlign: 'center',
	},
	cardTitle: {
		textTransform: 'uppercase',
		color: '#444',
		fontSize: 11.5,
	},
	select: {
		margin: 9,
		width: 350,
		flexGrow: 1,
	},
	tableRoot: {
		whiteSpace: 'nowrap',
		'& thead th': {
			textTransform: 'uppercase',
			color: '#444',
			fontSize: 11.5,
		},
		'& tbody td': {
			textAlign: 'center',
		},
		'& tbody tr:hover': {
			backgroundColor: '#FAFAFA',
			boxShadow: 'inset 3px 0px 0px 0px #0093EE',
		},
	},
}));

const Card = ({ title, children }: { title: string; children?: any }) => {
	const classes = useStyles({ border: false });
	return (
		<Box className={classes.card} flexDirection="column">
			<Box className={classes.cardTitle}>{title}</Box>
			<Box display="flex" flex={1} height="100%" justifyContent="center" alignItems="center">
				{children}
			</Box>
		</Box>
	);
};

export default Associations;
