import { Box } from '@mui/material';
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Title from '../Title';
import { Paper } from '@material-ui/core';
import { FileDto } from '../../dto/file';
import moment from 'moment';
import DeleteButton from '../form/DeleteButton';
import routes from '../../api/routes';

export const getLabelByCategory = (category) => {
	const map = {
		IMAGERIGHT: "Droit à l'image",
		MUTUAL: 'Adhésion mutuelle',
		MUTUALREFUSAL: 'Refus mutuelle',
		RGPD: 'Consentement RGPD',
		REQUESTABSENCE: 'Autorisation d’absence',
		REQUESTPAIDLEAVE: 'Demande de congés payés',
		REQUESTUNPAIDLEAVE: 'Demande de congés sans solde',
		REQUESTDEPOSIT: "Demande d'acompte",
	};

	return map[category];
};

const PDFTable = ({ files, mb, refreshData }: { files: FileDto[]; mb?: number; refreshData: () => void }) => {
	console.log(files);
	return (
		<Box mb={mb}>
			<Title mt={3}>PDF Administratifs</Title>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
					<TableHead>
						<TableRow>
							<TableCell>Catégorie</TableCell>
							<TableCell>Nom et lien du PDF</TableCell>
							<TableCell align="right" style={{ paddingRight: 55 }}>
								Date de création
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{files.map((file) => (
							<TableRow key={file.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell align="left">{getLabelByCategory(file.category)}</TableCell>
								<TableCell component="th" scope="row">
									<a href={file.path} target="_blank" rel="noopener noreferrer">
										{file.name}
									</a>
								</TableCell>
								<TableCell align="right">
									<span style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
										<span>{moment(file.timestamp).format('DD/MM/YYYY HH:mm')}</span>
										<div style={{ width: 10 }} />
										<DeleteButton
											mini
											url={routes.files.delete({ id: file.id.toString() })}
											onSuccess={refreshData}
										/>
									</span>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default PDFTable;
