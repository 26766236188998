import { useContext } from 'react';
import { UserContext } from '../components/user/UserProvider';
import { User } from '../types/user';

const useAuth = () => {
	let { user, setUser } = useContext(UserContext);
	/*
  		__COMMENT__
  			Est-ce que ça ne serait pas plus simple d'utiliser un hook du type useReducer avec un state initial qui pourrait utiliser ton userContext initialisé avec le localStorage ?
  		__COMMENT__
  	*/
	const login = (user: User, token: string) => {
		localStorage.setItem('token', token);
		setUser(user);
	};

	const logout = () => {
		localStorage.removeItem('token');
		setUser(null);
	};

	return { user, login, logout };
};

export default useAuth;
