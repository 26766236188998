import utils from './utils';
import { AssociationFiltersDto } from '../dto/association';
import { DonationFiltersDto } from '../dto/donation';
import { AddUserToMissionDto } from '../dto/mission';

let baseUrl;

if (process.env.NODE_ENV === 'production') {
	baseUrl = 'https://api.leto-trico.fr';
} else if (process.env.REACT_APP_IS_LOCAL) {
	baseUrl = 'http://localhost:60112';
} else {
	baseUrl = 'http://localhost:60112';
	// baseUrl = 'http://46.101.53.121:60112';
}

if (process.env.REACT_APP_IS_STAGING) {
	baseUrl = 'https://api.staging.leto-trico.fr';
}

const { paramsRoute, prefixRoutes } = utils(baseUrl);

let routes = {
	root: '/',
	helloWorld: '/',
	cron: { post: paramsRoute<{ associationId: number }>('/associations/cron/:associationId') },
	auth: {
		login: '/auth/login',
		validPasswordChange: '/auth/valid-password-change',
		requestPasswordReset: '/auth/request-password-reset',
	},
	associations: {
		all: '/associations',
		create: '/associations',
		delete: paramsRoute<{ id: number }>('/associations/:id'),
		edit: paramsRoute<{ id: number }>('/associations/:id'),
		get: paramsRoute<{ id: number }>('/associations/:id'),
		programs: paramsRoute<{ id: number }>('/associations/:id/programs'),
		causes: '/associations/causes',
		listStats: paramsRoute<{}, AssociationFiltersDto>('/associations/stats-list'),
		dashboardStats: paramsRoute<{}, AssociationFiltersDto>('/associations/dashboard-stats'),
		excel: paramsRoute<{ clientId: string | null }, AssociationFiltersDto>('/associations/excel/:clientId'),
		getPdf: paramsRoute<{}, { donationId: number }>('/associations/pdf'),
		associationWithMissionId: paramsRoute<{ id: number }>('/associations/mission/:id'),
	},
	programs: {
		get: paramsRoute<{ id: number }>('/programs/:id'),
		edit: paramsRoute<{ id: number }>('/programs/:id'),
		delete: paramsRoute<{ id: number }>('/programs/:id'),
		create: '/programs',
		current: paramsRoute<{ associationId: number }>('/programs/current/:associationId'),
		past: paramsRoute<{ associationId: number }>('/programs/past/:associationId'),
	},
	missions: {
		get: paramsRoute<{ id: number }>('/missions/:id'),
		getExpenses: paramsRoute<{ id: number }>('/missions/expenses/:id'),
		editMissionExpense: paramsRoute<{ id: number }>('/missions/edit-expense/:id'),
		logo: paramsRoute<{ id: number }>('/missions/logo/:id'),
		edit: paramsRoute<{ id: number }>('/missions/:id'),
		partialEdit: '/missions/partialEdit',
		delete: paramsRoute<{ id: number }>('/missions/:id'),
		excel: paramsRoute<{ id: number }, { filters: string; periodStart?: string; periodEnd?: string }>(
			'/missions/:id/excel',
		),
		excelCompo: '/missions/excelCompo',
		sendMailExcelCompo: '/missions/sendMailExcelCompo',
		create: '/missions',
		sendMessage: '/missions/send-message',
		getMissionPayrollDetails: paramsRoute<{ id: string; selectedMissionUserId: number }>(
			'/missions/payroll/:id/:selectedMissionUserId',
		),
		allForPayroll: paramsRoute<
			{},
			{
				associationId?: number;
				status?: string;
				periodStart?: string;
				periodEnd?: string;
				userId?: string;
				orderByDate?: boolean;
				accounting?: boolean;
			}
		>('/missions/payroll'),
		payrollExpenses: {
			editOrCreate: '/missions/payroll/expense',
			delete: paramsRoute<{ id: string }>('/missions/payroll/expense/:id'),
		},
		excelPayroll: '/missions/excelPayroll',
		all: paramsRoute<
			{},
			{
				associationId?: number;
				status?: string;
				periodStart?: string;
				periodEnd?: string;
				userId?: string;
				orderByDate?: boolean;
			}
		>('/missions'),
		allWithStats: paramsRoute<
			{},
			{
				associationId?: number;
				status?: string;
				periodStart?: string;
				periodEnd?: string;
				userId?: string;
				orderByDate?: boolean;
			}
		>('/missions/stats'),
		counts: paramsRoute<{}, { associationId?: number; userId?: string }>('/missions/counts'),
		codesForAssociation: paramsRoute<{ associationId: number }>(
			'/missions/missions-codes-for-assocation/:associationId',
		),
		lastWorkedDay: paramsRoute<{ id: string }, { recruiterId?: string }>('/missions/:id/lastWorkedDay'),
		authorizations: {
			all: paramsRoute<{ id: string }>('/missions/:id/authorizations'),
			add: paramsRoute<{ id: string }>('/missions/:id/authorizations'),
			delete: paramsRoute<{ id: string }>('/missions/:id/authorizations'),
		},
		users: {
			all: paramsRoute<{ id: string }>('/missions/:id/users'),
			add: paramsRoute<{ id: string }, { userId: number }>('/missions/:id/addUser'),
			remove: paramsRoute<{ id: number }, { userId: number }>('/missions/:id/removeUser'),
			addToCompo: paramsRoute<{ id: number }>('/missions/:id/addUserToCompo'),
		},
		interviews: {
			getInterviewsAndElders: paramsRoute<{ id: number }, { search?: string }>(
				'/missions/:id/interviewsAndElders',
			),
			addUserToCompoMission: paramsRoute<
				{ id: number; changeGradeWarning: boolean },
				{ addUserToMissionDto: AddUserToMissionDto }
			>('/missions/:id/addUserToCompoMission/:changeGradeWarning'),
			create: '/missions/interviews/create',
			update: '/missions/interviews/update',
			remove: paramsRoute<{ interviewId: number }>('/missions/:interviewId/interviews'),
			absent: paramsRoute<{ interviewId: number }>('/missions/:interviewId/interviews/absent'),
		},
		reports: {
			get: paramsRoute<{ id: string }>('/missions/:id/reports'),
			dailyReport: paramsRoute<{ id: string }, { date: string }>('/missions/:id/daily-report'),
			recruiterReports: paramsRoute<{ id: string }, { recruiterId: string }>(
				'/missions/:id/recruiter-reports',
			),
			recruiterDailyReport: {
				get: paramsRoute<{ id: string }, { recruiterId: string; date: string }>(
					'/missions/:id/recruiter-daily-report',
				),
				update: '/missions/update/recruiter-daily-report',
			},
			createOrUpdate: '/missions/report',
		},
		overviewStats: paramsRoute<{ id: string }>('/missions/:id/overview-stats'),
	},
	files: {
		upload: '/files/upload',
		uploadToken: '/files/request-upload-token',
		confirmUpload: '/files/confirm-upload',
		category: paramsRoute<{ category: string }>('/files/category/:category'),
		all: paramsRoute<
			{},
			{
				userId?: number;
				missionId?: number;
				categories?: string[];
			}
		>('/files/all'),
		delete: paramsRoute<{ id: string }>('/files/:id'),
	},
	users: {
		all: '/users',
		create: '/users',
		search: paramsRoute<
			{},
			{
				search?: string;
				positions?: string;
				page?: number;
				gradeId?: string;
				state?: string;
				isWorking?: boolean;
				infoHR?: string;
			}
		>('/users'),
		searchCount: paramsRoute<
			{},
			{
				search?: string;
				positions?: string;
				gradeId?: string;
				state?: string;
				isWorking?: boolean;
				infoHR?: string;
			}
		>('/users/count'),
		suggestions: paramsRoute<{}, { search?: string; positions?: string, actifOnly?: boolean }>('/users/suggestions'),
		get: paramsRoute<{ id: string }>('/users/:id'),
		edit: paramsRoute<{ id: string }>('/users/:id'),
		delete: paramsRoute<{ id: string }>('/users/:id'),
		stats: paramsRoute<{ id: string }>('/users/:id/stats'),
		excel: paramsRoute<{ clientId: string | null }>('/users/excel/:clientId'),
		training: {
			all: paramsRoute<{ id: string }>('/users/:id/trainings'),
			create: paramsRoute<{ id: string }>('/users/:id/trainings'),
			delete: paramsRoute<{ id: string; trainingId: string }>('/users/:id/trainings/:trainingId'),
		},
		grades: {
			all: '/users/grades',
			edit: paramsRoute<{ id: number }>('/users/grades/:id'),
			delete: paramsRoute<{ id: number }>('/users/grades/:id'),
			create: '/users/grades',
			evolutions: paramsRoute<{ userId: string }>('/users/:userId/grade-evolutions'),
			deleteEvolution: paramsRoute<{ id: number }>('/users/grade-evolutions/:id'),
		},
	},
	donations: {
		all: paramsRoute<{}, { filters: string }>('/donations'),
		excel: paramsRoute<{ clientId: string | null }, { filters: string }>('/donations/excel/:clientId'),
		get: paramsRoute<{ id: number }>('/donations/:id'),
		delete: paramsRoute<{ id: number }>('/donations/:id'),
		count: paramsRoute<{}, { filters: string }>('/donations/count'),
		sendWelcomeEmailAgain: paramsRoute<{ id: number }>('/donations/send-welcome-email-again/:id'),
		sendAgainAsso: paramsRoute<{ id: number }>('/cron/send-again-asso/:id'),
		ageStatsForMission: paramsRoute<{}, DonationFiltersDto>('/donations/age-stats-mission'),
		hourlyStatsForMission: paramsRoute<
			{},
			{ missionId: string; recruiterId?: string; runningMissionsOnly?: boolean }
		>('/donations/hourly-stats-mission'),
		missionDashboadStats: paramsRoute<{}, AssociationFiltersDto>('/donations/dashboard-stats'),
		updateBankingDetails: paramsRoute<{ donationId: number }>('/donations/:donationId/banking-details'),
		updateDonationAmount: paramsRoute<{ donationId: number }>('/donations/:donationId/donation-amount'),
		updateDonationStatus: paramsRoute<{ donationId: number }>('/donations/:donationId/donation-status'),
		updateDonationAllowExport: paramsRoute<{ donationId: number }>(
			'/donations/:donationId/donation-allow-export',
		),
		updateGrade: paramsRoute<{ donationId: number }>('/donations/:donationId/donation-grade'),
		updateDonationPersonalDetails: paramsRoute<{ donationId: number }>(
			'/donations/:donationId/donation-personal-details',
		),
		welcomeCalls: {
			forDonation: paramsRoute<{ id: number }>('/donations/:id/welcome_calls'),
			add: paramsRoute<{ id: number }>('/donations/:id/welcome_calls'),
		},
		calls: {
			forDonation: paramsRoute<{ id: number }>('/donations/:id/calls'),
			all: paramsRoute<{}, { filters: string }>('/donations/calls'),
			count: paramsRoute<{}, { filters: string }>('/donations/calls/count'),
			add: paramsRoute<{ id: number }>('/donations/:id/calls'),
			groupedValidation: paramsRoute<{}, { rums: string[] }>('/donations/calls/grouped-validation'),
		},
		exportLogs: {
			all: paramsRoute<{}, { page: number, status: string, name: string, startDate: string, endDate: string, errorCount: string }>('/donations/export-logs'),
			count: paramsRoute<{}, { status: string, name: string, startDate: string, endDate: string, errorCount: string }>('/donations/export-logs/count'),
			exportNames: paramsRoute<{}, {}>('/donations/export-logs-names'),
			excelLogs: paramsRoute<{}, { logs: string }>('/donations/excelLogs'),
		},
		allowExport: paramsRoute<{}, { values: { id: number; allowExport: boolean }[] }>(
			'/donations/allow-export',
		),
	},
	logistics: {
		tablets: {
			all: paramsRoute<{}, { filters: string }>('/logistics/tablets'),
			count: paramsRoute<{}, { filters: string }>('/logistics/tablets/count'),
			create: '/logistics/tablets',
			assignUser: paramsRoute<{ tabletId: string }, { userId: string }>(
				'/logistics/tablets/:tabletId/user',
			),
			delete: paramsRoute<{ tabletId: string }>('/logistics/tablets/:tabletId'),
		},
		stocks: {
			forTrico: '/logistics/stocks',
			forAssociation: paramsRoute<{ associationId: number }>('/logistics/stocks/:associationId'),
			edit: '/logistics/stocks',
			editRow: '/logistics/editRow',
			getStocksTable: paramsRoute<{ associationId: number }>('/logistics/stocksTable/:associationId'),
			getMissionStocksTable: paramsRoute<{ missionId: string }>(
				'/logistics/missionStocksTable/:missionId',
			),
			delete: paramsRoute<{ id: number }>('/logistics/stocks/:id'),
			create: '/logistics/createStocks',
		},
	},
} as const;

export default prefixRoutes(routes);
