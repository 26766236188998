import React, { useState } from "react";
import { Button, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ChevronLeft } from "@material-ui/icons";
import Modal from "../Modal";
import PrimaryButton from "../PrimaryButton";

export type ReturnButtonProps = {
	to: string;
	children?: any;
	style?: React.CSSProperties;
	askConfirmation?: boolean;
	confirmationMessage?: string;
};

const ReturnButton = ({ to, children, style, askConfirmation, confirmationMessage }: ReturnButtonProps) => {
	let [showModal, setShowModal] = useState(false);
	return <>
		<Button
			{...(askConfirmation ? { onClick: () => setShowModal(true) } : { component: Link, to })}
			style={{ fontSize: "0.8em", color: "#3F51B5", paddingLeft: 0, margin: "10px -3px", ...style }}
		>
			<ChevronLeft style={{ fontSize: "24px" }} />
			<span>{children}</span>
		</Button>
		{askConfirmation && <Modal open={showModal} onClose={() => setShowModal(false)}>
			<p>{confirmationMessage ?? "Êtes vous sûr de vouloir quitter cette page ? Vous risquez de perdre des données."}</p>
			<Box display="flex" justifyContent="space-between">
				<Button onClick={() => setShowModal(false)}>Annuler</Button>
				<PrimaryButton {...({ component: Link, to })}>
					Confirmer
				</PrimaryButton>
			</Box>
		</Modal>}
	</>
}

export default ReturnButton;