import React, { useRef, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const ErrorMessage = ({ children }: { children: string }) => {
	let classes = useStyles({ border: false });
	let ref = useRef<HTMLDivElement>();

	useEffect(() => {
		ref.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
	}, [children])

	return <Box py={2} color="error">
		<div ref={ref as any}></div>
		<Typography className={classes.errorMessage} color="error">
			{children}
		</Typography>
	</Box>
}

const useStyles = makeStyles({
	errorMessage: {
		whiteSpace: "pre-wrap"
	}
});


export default ErrorMessage;