import {
	Box,
	Button,
	Checkbox,
	Collapse,
	IconButton,
	Link,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { Edit, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import moment from 'moment';
import React, { useState } from 'react';
import routes from '../../api/routes';
import { MissionWithAssociationDto } from '../../dto/mission';
import { InterviewsAndEldersDto, MissionElderDto, MissionInterviewDto } from '../../dto/missionInterviews';
import useFetch from '../../hooks/useFetch';
import DeleteButton from '../form/DeleteButton';
import AddUserAutocomplete from '../missions/AddUserAutocomplete';
import Modal from '../Modal';
import PrimaryButton from '../PrimaryButton';
import Title from '../Title';
import AddInterview from './AddInterview';
import AddUserToMission, { AddUserToMissionProps } from './AddUserToMission';
import { post } from '../../api';
import { MissionToUserStatus } from '../../dto/missionToUser';
import { useStyles as useMissionsStyles } from '../../pages/missions/Missions';
import { FaEnvelope, FaFileExcel } from 'react-icons/fa';
import ConfirmationButton from '../form/ConfirmationButton';

const useRowStyles = makeStyles({ root: { '& > *': { borderBottom: 'unset' } } });

const grayedOutRowStyle: React.CSSProperties = {
	backgroundColor: '#d3d3d345',
	opacity: '70%',
};

const purpledOutRowStyle: React.CSSProperties = {
	backgroundColor: '#7F00FF25',
	opacity: '70%',
};

type MissionRowProps = {
	tricoCode: string;
	missionId: number;
	missionStartDate: string;
	missionEndDate: string;
};

const MissionRow = ({ tricoCode, missionId, missionStartDate, missionEndDate }: MissionRowProps) => {
	const [reloadToken, setReloadToken] = useState(0);
	const triggerReload = () => setReloadToken(reloadToken + 1);

	const [selectedInterviews, setSelectedInterviews] = useState<MissionInterviewDto[]>([]);
	const [selectedElders, setSelectedElders] = useState<MissionElderDto[]>([]);

	const [showCompoFinishedModal, setShowCompoFinishedModal] = useState(false);
	const closeCompoFinishedModal = () => setShowCompoFinishedModal(false);
	const sendCompoByMail = () =>
		post(routes.missions.sendMailExcelCompo, { missionId }).catch((e) => console.error(e));

	const [userToAddToMission, setUserToAddToMission] = useState<AddUserToMissionProps['userToAdd']>();
	const exitAddToMissionModal = () => {
		setUserToAddToMission(null);
		triggerReload();
	};
	const setNextUserToAddToMission = (nextUser: AddUserToMissionProps['userToAdd']) => {
		if (nextUser) {
			setUserToAddToMission(nextUser);
		} else {
			exitAddToMissionModal();
			setShowCompoFinishedModal(true);
		}
	};

	const [interviewInEdition, setInterviewInEdition] = useState<Partial<MissionInterviewDto>>();
	const resetInterviewInEdition = () => setInterviewInEdition(null);

	const [open, setOpen] = useState(false);
	const classes = useRowStyles();
	const missionsClasses = useMissionsStyles({ border: false });

	const [areInterviewsAndEldersLoading, interviewsAndElders] = useFetch<InterviewsAndEldersDto>(
		routes.missions.interviews.getInterviewsAndElders({ id: missionId }, { search: '' }),
		[reloadToken],
	);
	const interviews = interviewsAndElders?.interviews?.sort((a, b) => {
		try {
			return a?.interviewDatetime.localeCompare(b?.interviewDatetime);
		} catch (e) {
			return 0;
		}
	});
	const elders = interviewsAndElders?.elders;

	const isInterviewComposing = (interview: MissionInterviewDto) => interview.applicant.isApplicant;
	const isElderComposing = (elder: InterviewsAndEldersDto['elders'][number]) =>
		elder.missionToUserStatus === MissionToUserStatus.COMPOSING;

	const handleSelectAllClick = (checked: boolean) => {
		setSelectedInterviews(checked ? interviews.filter((interview) => isInterviewComposing(interview)) : []);
		setSelectedElders(checked ? elders.filter((elder) => isElderComposing(elder)) : []);
	};

	const handleSelectInterview = (applicantId: number, checked: boolean) => {
		if (checked) {
			setSelectedInterviews([
				...selectedInterviews,
				interviews.find((i) => i.applicantId === applicantId),
			]);
		} else {
			setSelectedInterviews(selectedInterviews.filter((i) => i.applicantId !== applicantId));
		}
	};

	const handleSelectElder = (elderId: number, checked: boolean) => {
		if (checked) {
			setSelectedElders([...selectedElders, elders.find((e) => e.id === elderId)]);
		} else {
			setSelectedElders(selectedElders.filter((e) => e.id !== elderId));
		}
	};

	const checkAllStatus = () => {
		const selectableRows = [
			...elders.filter((elder) => isElderComposing(elder)),
			...interviews.filter((interview) => isInterviewComposing(interview)),
		];
		const selectedRows = [...selectedInterviews, ...selectedElders];

		if (selectedRows.length === 0) return 'UNCHECKED';
		if (selectableRows.length === selectedRows.length) return 'CHECKED';
		else return 'INDETERMINATE';
	};

	const isEditing = () => {
		const userToAddInterview = interviews.find((i) => i.applicantId === userToAddToMission.id);
		if (userToAddInterview) return !isInterviewComposing(userToAddInterview);

		const userToAddElder = elders.find((e) => e.id === userToAddToMission.id);
		if (userToAddElder) return !isElderComposing(userToAddElder);
	};

	if (areInterviewsAndEldersLoading) return <div>Loading...</div>;

	return (
		<>
			{interviewInEdition && (
				<Modal open={!!interviewInEdition} onClose={resetInterviewInEdition}>
					<AddInterview
						interview={interviewInEdition}
						reload={triggerReload}
						exit={resetInterviewInEdition}
					/>
				</Modal>
			)}
			{userToAddToMission && (
				<Modal open={!!userToAddToMission} onClose={exitAddToMissionModal}>
					<AddUserToMission
						key={userToAddToMission.id}
						missionId={missionId}
						exit={exitAddToMissionModal}
						userToAdd={userToAddToMission}
						missionStartDate={missionStartDate}
						missionEndDate={missionEndDate}
						edit={isEditing()}
						onSuccess={() => {
							if (selectedInterviews.length > 0) {
								setNextUserToAddToMission(selectedInterviews[1]?.applicant || selectedElders[0]);
								handleSelectInterview(userToAddToMission.id, false);
							} else if (selectedElders.length > 0) {
								setNextUserToAddToMission(selectedElders[1]);
								handleSelectElder(userToAddToMission.id, false);
							}
						}}
					/>
				</Modal>
			)}

			<Modal open={showCompoFinishedModal} onClose={closeCompoFinishedModal}>
				<p>
					Si vous avez fini la compo mission, elle sera envoyée par email à : infos-rh@chez-trico.fr,
					logistique@chez-trico.fr et au ROT de la mission
				</p>
				<Box display="flex" justifyContent="space-between">
					<Button onClick={closeCompoFinishedModal}>Annuler</Button>
					<PrimaryButton
						onClick={() => {
							sendCompoByMail();
							closeCompoFinishedModal();
						}}
					>
						Confirmer
					</PrimaryButton>
				</Box>
			</Modal>

			<TableRow>
				<TableCell className={classes.root}>
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row">
					{tricoCode}
				</TableCell>
				<TableCell>{interviews?.length}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Box display="flex" alignItems="center" justifyContent="space-between">
								<Title>Entretiens</Title>
								<Box display="flex" flexDirection="column" gridGap="10px">
									<Box width="max-content" marginLeft="auto">
										<AddUserAutocomplete
											actifOnly
											callback={({ id }, setUserToAdd, setIsLoading) => {
												if (
													interviews.some(({ applicantId }) => applicantId === +id) ||
													elders.some((e) => e.id === +id)
												) {
													setUserToAdd(null);
												} else {
													setIsLoading(true);
													post(routes.missions.users.addToCompo({ id: missionId }), {
														userId: +id,
													}).then((response) => {
														setUserToAdd(null);
														setIsLoading(false);
														if (!response.errors) {
															triggerReload();
														}
													});
												}
											}}
											placeholder="Ajouter un ancien"
										/>
									</Box>
									<Box display="flex" flexWrap="wrap" justifyContent="flex-end">
										<PrimaryButton onClick={() => setInterviewInEdition({ missionId })}>
											Ajouter un entretien
										</PrimaryButton>
										<PrimaryButton
											style={{ margin: '0 1rem' }}
											onClick={() =>
												setUserToAddToMission(
													selectedInterviews[0]?.applicant || selectedElders[0],
												)
											}
											disabled={!selectedInterviews.length && !selectedElders.length}
										>
											Ajouter à la compo mission
										</PrimaryButton>
										<Link href={`${routes.missions.excelCompo}?missionId=${missionId}`}>
											<Button
												className={missionsClasses.excelButton}
												size="small"
												variant="contained"
												color="primary"
											>
												<FaFileExcel size={16} />
											</Button>
										</Link>
										<ConfirmationButton
											onClick={sendCompoByMail}
											confirmationMessage="La compo sera envoyée à : infos-rh@chez-trico.fr, logistique@chez-trico.fr et au ROT de la mission"
										>
											<FaEnvelope size={16} />
										</ConfirmationButton>
									</Box>
								</Box>
							</Box>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell padding="checkbox">
											<Checkbox
												indeterminate={checkAllStatus() === 'INDETERMINATE'}
												checked={checkAllStatus() === 'CHECKED'}
												onChange={(e) => handleSelectAllClick(e.target.checked)}
												disabled={!interviews.length}
											/>
										</TableCell>
										<TableCell>Date</TableCell>
										<TableCell>Candidat / Ancien</TableCell>
										<TableCell>Commentaire</TableCell>
										<TableCell>Note</TableCell>
										<TableCell>{/* modifier / supprimer*/}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{interviews && interviews.length > 0 ? (
										interviews.map((interview, i) => (
											<TableRow
												key={`inteview_${interview.id}`}
												style={
													isInterviewComposing(interview)
														? interview.absent === true
															? purpledOutRowStyle
															: {}
														: grayedOutRowStyle
												}
											>
												<TableCell padding="checkbox">
													<Checkbox
														onChange={(e) =>
															handleSelectInterview(interview.applicantId, e.target.checked)
														}
														checked={selectedInterviews.some(({ id }) => id === interview.id)}
														inputProps={{
															'aria-labelledby': `enhanced-table-checkbox-${i}`,
														}}
													/>
												</TableCell>
												<TableCell component="th" scope="row">
													{interview.interviewDatetime
														? `${moment(interview.interviewDatetime).format('ll à HH:mm')}`
														: ''}
												</TableCell>
												<TableCell>
													{interview.applicant.firstName} {interview.applicant.lastName}
												</TableCell>
												<TableCell>{interview.applicant.comment}</TableCell>
												<TableCell>{interview.mark}</TableCell>
												<TableCell>
													<Box display="flex" justifyContent="space-evenly">
														<PrimaryButton
															mini
															variant="contained"
															onClick={() => setInterviewInEdition(interview)}
														>
															<Edit style={{ fontSize: 14 }} />
														</PrimaryButton>

														{/* DELETE */}
														<DeleteButton
															mini
															url={routes.missions.interviews.remove({
																interviewId: interview.id,
															})}
															confirmationMessage="Êtes vous sûr de vouloir supprimer cet entretien ?"
															successMessage="L'entretien a bien été supprimé !"
															returnLabel="Retour à la mission"
															onSuccess={triggerReload}
														/>

														{/* ABSENT */}
														<DeleteButton
															absent
															mini
															request={() =>
																post(
																	routes.missions.interviews.absent({
																		interviewId: interview.id,
																	}),
																)
															}
															confirmationMessage="Êtes vous sûr de vouloir changer le statut d'absence ?"
															successMessage="L'entretien a bien été mis à jour !"
															returnLabel="Retour à la mission"
															onSuccess={triggerReload}
														/>
													</Box>
												</TableCell>
											</TableRow>
										))
									) : (
										<TableRow>
											<Box py={2} position="absolute" height="100%">
												Cette mission n'a pas d'entretien
											</Box>
											<TableCell style={{ visibility: 'hidden', border: 0 }}>
												<Box py={1}>&nbsp;</Box>
											</TableCell>
										</TableRow>
									)}
									{elders?.length > 0 &&
										elders.map((elder, i) => (
											<TableRow
												key={`elder_${elder.id}`}
												style={isElderComposing(elder) ? {} : grayedOutRowStyle}
											>
												<TableCell padding="checkbox">
													<Checkbox
														onChange={(e) => handleSelectElder(elder.id, e.target.checked)}
														checked={selectedElders.some(({ id }) => id === elder.id)}
														inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${i}` }}
													/>
												</TableCell>
												<TableCell>{/* date */}</TableCell>
												<TableCell>
													{elder.firstName} {elder.lastName}
												</TableCell>
												<TableCell>{elder.comment}</TableCell>
												<TableCell>{/* note */}</TableCell>
												<TableCell>
													<Box display="flex" justifyContent="space-evenly">
														<Box></Box>
														<DeleteButton
															mini
															request={() =>
																post(routes.missions.users.remove({ id: missionId }), {
																	userId: elder.id,
																})
															}
															confirmationMessage="Êtes-vous sûr de vouloir enlever cet ancien de la sélection?"
															successMessage="L'ancien a bien été enlevé de la sélection !"
															returnLabel="Retour à la mission"
															onSuccess={triggerReload}
														/>
													</Box>
												</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

type InterviewTableProps = {
	associationId?: number;
	missionStatus: string;
};

const InterviewTable = ({ associationId, missionStatus }: InterviewTableProps) => {
	const [, missions] = useFetch<MissionWithAssociationDto[]>(
		routes.missions.all({}, { associationId, status: missionStatus }),
		[associationId, missionStatus],
	);

	return (
		<TableContainer component={Paper}>
			<Table aria-label="collapsible table">
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell>Code mission</TableCell>
						<TableCell>Nombre d'entretiens</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{missions?.map((mission) => (
						<MissionRow
							key={mission.id}
							tricoCode={mission.tricoCode}
							missionId={mission.id}
							missionStartDate={mission.startDate}
							missionEndDate={mission.endDate}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default InterviewTable;
