import React from 'react';
import DropDown from './DropDown';
import moment from 'moment';

const FilterWeeks = ({ setPeriodStart, setPeriodEnd, startDate, endDate }) => {
	const filterWeeks = ['Toute la période'];

	if (startDate && endDate) {
		for (let i = 0; moment(startDate).add(i, 'week').isBefore(moment(endDate)) === true; i++) {
			filterWeeks.push(`Semaine ${i + 1}`);
		}
	}

	const handlePeriodChange = (e: string) => {
		const index = filterWeeks.findIndex((el) => el === e);
		if (index === 0) {
			setPeriodStart(moment(startDate).format('YYYY-MM-DD 00:00:00'));
			setPeriodEnd(moment(endDate).format('YYYY-MM-DD 23:59:59'));
		} else {
			setPeriodStart(
				moment(startDate)
					.add(index - 1, 'week')
					.format('YYYY-MM-DD 00:00:00'),
			);
			setPeriodEnd(
				moment(startDate)
					.add(index - 1, 'week')
					.add(6, 'day')
					.format('YYYY-MM-DD 23:59:59'),
			);
		}
	};

	return (
		<DropDown
			values={filterWeeks}
			label="Semaines"
			margin={'0px 0px 8px 0px'}
			onChange={(e) => handlePeriodChange(e)}
			width="50%"
		/>
	);
};

export default FilterWeeks;
