import React from 'react';
import useFetch from '../../hooks/useFetch';
import Select, { SelectProps } from '../form/Select';
import routes from '../../api/routes';

const MissionSelect = ({ associationId, ...props }: { associationId: number } & SelectProps) => {
	const [, codes] = useFetch<{ id: string; tricoCode: string }[]>(
		routes.missions.codesForAssociation({ associationId }),
		[associationId],
	);
	const missionCodes = codes && Array.isArray(codes) ? codes : [];
	let missionOptions = missionCodes.map(({ id, tricoCode }) => ({ value: id, label: tricoCode }));
	return <Select values={missionOptions} variant="outlined" label="Mission" {...props} />;
};

export default MissionSelect;
