import React, { useState } from 'react';
import SubmitButton from '../form/SubmitButton';
import { DonationDto } from '../../dto/donation';
import { post } from '../../api';
import routes from '../../api/routes';

const SendAgainWelcomeEmail = ({ donation }: { donation: DonationDto }) => {
	const [confirm, setConfirm] = useState(false);
	const [success, setSuccess] = useState(false);

	console.log('SendAgainWelcomeEmail', donation);
	const sendAgain = async () => {
		const ret = await post(routes.donations.sendWelcomeEmailAgain({ id: donation.id }));
		if (ret.success) setSuccess(true);
	};

	return (
		<div
			style={{
				justifyContent: 'center',
				textAlign: 'center',
				marginBottom: 10,
				marginTop: -30,
				minWidth: 400,
			}}
		>
			<SubmitButton
				size="small"
				variant="contained"
				color="primary"
				onClick={() => {
					if (!confirm) setConfirm(true);
					else sendAgain();
				}}
				icon={false}
				success={success}
			>
				{confirm ? 'Confirmer le renvoi' : "Renvoyer l'email de bienvenue"}
			</SubmitButton>
		</div>
	);
};
export default SendAgainWelcomeEmail;
