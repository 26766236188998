import { MissionDto } from './mission';
import { MissionExpensesDto } from './missionExpenses';
import { UserDto } from './user';

export class FileDto {
	id: number;
	type: string = '';
	category: string = '';
	path: string = '';
	name: string = '';
	timestamp: string;
	expense: MissionExpensesDto;
	mission?: MissionDto;
	missionIdInFiles?: number;
	user?: UserDto;
	userIdInFiles?: number;
}
