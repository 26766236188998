import {
	Box,
	Breadcrumbs,
	Button,
	Chip,
	CircularProgress,
	FormControlLabel,
	Radio,
	RadioGroup,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Add } from '@material-ui/icons';
import { Pagination, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { post } from '../../api';
import routes from '../../api/routes';
import { Card, CardContainer } from '../../components/Cards';
import DeleteButton from '../../components/form/DeleteButton';
import Title from '../../components/Title';
import UserSelect from '../../components/user/UserSelect';
import { rowsPerPage, TabletFiltersDto, TabletsListRowDto } from '../../dto/tablets';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';

const serialize = (data) => encodeURIComponent(JSON.stringify(data));

const Tablets = () => {
	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));
	const [reloadToken, forceDataReload] = useUniqueToken();

	const [page, setPage] = useState(1);
	const [tabletType, setTabletType] = useState<'RD' | 'RE'>('RD');

	const form = useForm({
		init: new TabletFiltersDto('any'),
	});

	let filters = form.values;
	filters.page = page;
	filters.type = tabletType;

	const [, RDCount] = useFetch<{ count: number }>(
		routes.logistics.tablets.count({}, { filters: serialize({ ...filters, type: 'RD' }) }),
		[filters.type, filters.status, reloadToken],
	);
	const [, RECount] = useFetch<{ count: number }>(
		routes.logistics.tablets.count({}, { filters: serialize({ ...filters, type: 'RE' }) }),
		[filters.type, filters.status, reloadToken],
	);

	const [isLoading, tablets] = useFetch<TabletsListRowDto[]>(
		routes.logistics.tablets.all({}, { filters: serialize(filters) }),
		[page, filters.type, filters.status, reloadToken],
	);

	let counts = { RD: RDCount?.count, RE: RECount?.count };

	const classes = useStyles({ border: false });

	const tabs = [
		{ type: 'RD', label: 'RD' },
		{ type: 'RE', label: 'RE' },
	] as const;

	const statusLabels = {
		any: 'Disponibles et assignées',
		available: 'Disponibles',
		assigned: 'Assignées',
	};
	const statusLabel = statusLabels[filters.status];

	return (
		<Box p={3}>
			<Box display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center">
				<Breadcrumbs aria-label="breadcrumb">
					<Typography color="textPrimary">Gestion des tablettes</Typography>
				</Breadcrumbs>
				<Box>
					<Link to={`/logistique/tablettes/creation`}>
						<Button size="small" variant="contained" color="primary">
							<Add /> Créer
						</Button>
					</Link>
				</Box>
			</Box>

			<Box py={2}>
				<RadioGroup
					row
					value={form.values.status}
					onChange={(e, value) => form.field('status').onChange(value as any)}
				>
					{Object.entries(statusLabels).map(([value, label]) => (
						<FormControlLabel value={value} control={<Radio color="primary" />} label={label} />
					))}
				</RadioGroup>
			</Box>

			<Title>Stock {statusLabel.replace('ées', 'é').replace('es', 'e')}</Title>
			<CardContainer>
				<Card title={`tablettes re ${statusLabel}`}>{counts?.RE ?? '?'}</Card>
				<Card title={`tablettes rd ${statusLabel}`}>{counts?.RD ?? '?'}</Card>
			</CardContainer>

			<Title mb={2}>Tablettes {statusLabel}</Title>
			<Box mt={2} className={classes.tabs}>
				<ToggleButtonGroup
					style={{ display: 'flex', flexWrap: 'wrap' }}
					exclusive
					value={tabletType}
					onChange={(e, v) => {
						setTabletType(v);
						setPage(1);
					}}
				>
					{tabs.map(({ type: status, label }) => (
						<ToggleButton key={status} value={status}>
							<Box mr={1}>{label}</Box>
							<Chip size="small" color="primary" label={counts?.[status] ?? '?'} />
						</ToggleButton>
					))}
				</ToggleButtonGroup>
			</Box>
			<Box
				my={2}
				style={{
					overflow: 'auto',
					maxWidth: isBigScreen ? 'calc(100vw - 346px)' : 'calc(100vw - 20px)',
					border: '1px solid #CCC',
					borderRadius: '3px',
					borderTopLeftRadius: 0,
					marginTop: 0,
				}}
			>
				{isLoading ? (
					<Box display="flex" justifyContent="center" my={8}>
						<CircularProgress />
					</Box>
				) : (
					<Table size="medium" classes={{ root: classes.tableRoot }}>
						<TableHead>
							<TableRow>
								<TableCell style={{ textAlign: 'center', maxWidth: 60 }}>ID</TableCell>
								<TableCell>N°série</TableCell>
								<TableCell>Assigné à</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tablets &&
								tablets.length &&
								tablets.map((row, i) => (
									<Row forceDataReload={forceDataReload} key={row.id} row={row} />
								))}
						</TableBody>
					</Table>
				)}
				<Box p={1} display="flex" justifyContent="end">
					<Pagination
						page={page}
						onChange={(_, page) => setPage(page)}
						count={Math.ceil((counts[tabletType] || 0) / rowsPerPage)}
					/>
				</Box>
			</Box>
		</Box>
	);
};

const Row = ({ row, forceDataReload }: { row: TabletsListRowDto; forceDataReload: () => any }) => {
	let [user, setUser] = useState(
		row.userId
			? {
					id: row.userId,
					firstName: row.firstName,
					lastName: row.lastName,
					path: row.avatarPath,
			  }
			: null,
	);
	return (
		<TableRow key={row.id}>
			<TableCell style={{ maxWidth: 60, textAlign: 'center' }}>{row.customId}</TableCell>
			<TableCell>{row.serialNumber}</TableCell>
			<TableCell>
				<UserSelect
					value={user}
					onChange={(user) => {
						post(
							routes.logistics.tablets.assignUser({ tabletId: row.id }, { userId: user?.id || null }),
						);
						setUser(user);
					}}
				/>
			</TableCell>
			<TableCell style={{ width: 60 }}>
				<DeleteButton
					mini
					round
					url={routes.logistics.tablets.delete({ tabletId: row.id })}
					onSuccess={() => forceDataReload()}
				/>
			</TableCell>
		</TableRow>
	);
};

const useStyles = makeStyles(({ isBigScreen }: { isBigScreen?: boolean }) => ({
	tabs: {
		marginBottom: -1,
		'& button': {
			borderColor: '#CCC',
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
			fontSize: 12,
			padding: '10px 15px',
		},
	},
	tableRoot: {
		whiteSpace: 'nowrap',
		'& thead th': {
			textTransform: 'uppercase',
			color: '#444',
			fontSize: 11.5,
		},
		'& tbody tr.noBottomBorder > td': {
			borderBottom: 'none',
		},
		'& tbody tr:not(.comment):hover': {
			backgroundColor: '#FAFAFA',
			boxShadow: 'inset 3px 0px 0px 0px #0093EE',
		},
	},
}));

const useUniqueToken = () => {
	let [token, setToken] = useState(0);
	return ['t' + token, () => setToken(token + 1)] as const;
};

export default Tablets;
