export const TabletTypes = {
	"RD": "Tablette RD",
	"RE": "Tablette RE",
	"ROT": "Tablette ROT"
};

export const rowsPerPage = 20;

export type TabletType = keyof typeof TabletTypes;

export class TabletDto {
	id: string;
	customId: string;
	serialNumber: string;
	userId: string;
	type: TabletType;
	simCard: boolean;
};

export class TabletFiltersDto {
	constructor(status: 'any' | 'assigned' | 'available') {
		this.status = status;
	}
	page: number;
	type: TabletType;
	status: 'assigned' | 'available' | 'any';
	clientId?: number | null;
}

export class TabletsListRowDto extends TabletDto {
	lastName: string;
	firstName: string;
	avatarPath: string;
}