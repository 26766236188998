import React, { useState } from 'react';
import Modal from '../../components/Modal';
import { Link as RouterLink } from 'react-router-dom';
import { Box, CircularProgress, Button, ButtonProps } from '@material-ui/core';
import { del } from '../../api';
import { Delete } from '@material-ui/icons';
import PrimaryButton from '../PrimaryButton';
import { TimerOff } from '@mui/icons-material';

type DeleteButtonProps = {
	url?: string;
	request?: () => Promise<any>;
	confirmationMessage?: any;
	successMessage?: any;
	returnUrl?: string;
	returnLabel?: any;
	children?: any;
	onSuccess?: () => void;
	size?: ButtonProps['size'];
	mini?: boolean;
	absent?: boolean;
	round?: boolean;
	marginLeft?: string;
	colorInherit?: boolean;
};

// Button also used to report someone absent
const DeleteButton = ({
	url,
	request,
	round,
	mini,
	absent,
	size,
	onSuccess,
	children,
	confirmationMessage,
	successMessage,
	returnUrl,
	returnLabel,
	marginLeft,
	colorInherit,
}: DeleteButtonProps) => {
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	let [deleteStatus, setDeleteStatus] = useState<'confirm' | 'deleting' | 'deleted' | 'error'>('confirm');

	const confirmDelete = () => {
		setDeleteStatus('deleting');
		const deleteRequest = request ? request() : del(url);
		deleteRequest.then((response) => {
			if (response.errors) {
				setDeleteStatus('error');
			} else setDeleteStatus('deleted');
		});
	};

	return (
		<div>
			<PrimaryButton
				round={round}
				mini={mini}
				size={size}
				variant="contained"
				color={!absent && !colorInherit ? 'secondary' : 'inherit'}
				marginLeft={marginLeft}
				onClick={() => setShowDeleteConfirm(true)}
			>
				{!absent ? (
					<Delete style={{ fontSize: mini ? 14 : undefined }} />
				) : (
					<TimerOff style={{ fontSize: mini ? 14 : undefined }} />
				)}
				<Box ml={children ? 1 : 0}>{children}</Box>
			</PrimaryButton>
			<Modal open={showDeleteConfirm} onClose={() => setShowDeleteConfirm(false)}>
				{(() => {
					switch (deleteStatus) {
						case 'confirm':
							return (
								<>
									<p>
										{confirmationMessage ??
											'Êtes vous sur de vouloir effectuer cette suppression ?'}
									</p>
									<Box display="flex" justifyContent="space-between">
										<Button onClick={() => setShowDeleteConfirm(false)}>Annuler</Button>
										<Button variant="contained" color="primary" onClick={confirmDelete}>
											Confirmer {!absent && 'la suppression'}
										</Button>
									</Box>
								</>
							);
						case 'deleting':
							return (
								<>
									<Box mx={20} my={5}>
										<CircularProgress />
									</Box>
								</>
							);
						case 'deleted':
							return (
								<>
									<p>{successMessage ?? 'La suppression a bien été effectuée.'}</p>
									<RouterLink
										to={returnUrl}
										onClick={
											returnUrl
												? undefined
												: () => {
														setShowDeleteConfirm(false);
														setDeleteStatus('confirm');
														onSuccess?.();
												  }
										}
									>
										<Button variant="contained" color="primary">
											{returnLabel ?? 'Retour'}
										</Button>
									</RouterLink>
								</>
							);
						case 'error':
							return (
								<>
									<p>Oups... Une erreur inattendue s'est produite !</p>
									<Box display="flex" justifyContent="end">
										<Button
											onClick={() => {
												setShowDeleteConfirm(false);
												setDeleteStatus('confirm');
											}}
										>
											Annuler
										</Button>
									</Box>
								</>
							);
						default:
							return <p></p>;
					}
				})()}
			</Modal>
		</div>
	);
};

export default DeleteButton;
