import React, { useEffect, useState } from 'react';
import {
	Box,
	CircularProgress,
	TableBody,
	TableCell,
	TableRow,
	TableHead,
	Table,
	useTheme,
	useMediaQuery,
	Tooltip,
	Button,
	Link,
} from '@material-ui/core';
import Title from '../../../components/Title';
import useFetch from '../../../hooks/useFetch';
import routes from '../../../api/routes';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { MissionPayrollDetailsDto } from '../../../dto/payroll';
import { useStyles } from '../../../components/missions/MissionsTable';
import { styled } from '@material-ui/core';
import { AbsenceDisplay, MissionDailyReportOnRecruiterDto } from '../../../dto/missionReport';
import { capitalizeFirstLetter } from '../../../utils';
import PrimaryButton from '../../../components/PrimaryButton';
import { Edit, Add } from '@material-ui/icons';
import Modal from '../../../components/Modal';
import EditDailyReportOnRecruiter from './EditDailyReportOnRecruiter';
import { MissionPayrollExpenseDisplay, MissionPayrollExpenseDto } from '../../../dto/missionPayrollExpense';
import EditMissionPayrollExpense from './EditMissionPayrollExpense';
import DeleteButton from '../../../components/form/DeleteButton';
import Switch from '../../../components/form/Switch';
import { post } from '../../../api';
import ReturnButton from '../../../components/form/ReturnButton';
import DropDown from '../../../components/DropDown';
import { FaFileExcel } from 'react-icons/fa';
import { FileDto } from '../../../dto/file';
import PDFTable from '../../../components/files/PDFTable';

const LongTextCell = ({ text }: { text: string }) => {
	return (
		text && (
			<Tooltip title={text}>
				<div style={{ maxWidth: '110px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{text}</div>
			</Tooltip>
		)
	);
};

type DaysProps = {
	day: MissionPayrollDetailsDto['weeks'][number]['days'][number];
	setReportInEdition: React.Dispatch<React.SetStateAction<Partial<MissionDailyReportOnRecruiterDto>>>;
};

const Day = ({ day: { day, totals, usersReports }, setReportInEdition }: DaysProps) => {
	const [collapsed, setCollapsed] = useState(true);

	return (
		<>
			<TableRow
				style={{
					borderTop: '30px',
					cursor: 'pointer',
					...(!usersReports.length
						? { backgroundColor: 'lightgray', opacity: '50%', cursor: 'default' }
						: {}),
				}}
				onClick={() => setCollapsed(!collapsed)}
			>
				<TableCell>{capitalizeFirstLetter(moment(day).format('dddd DD/MM/YYYY'))}</TableCell>
				<TableCell>{totals.absences}</TableCell>
				<TableCell>{totals.usedTickets}</TableCell>
				<TableCell>{totals.usedPaperTickets}</TableCell>
				<TableCell>{totals.paidHours}</TableCell>
				<TableCell>{totals.exposes}</TableCell>
				<TableCell>{totals.routesDurations}</TableCell>
				<TableCell>{totals.perDiems}</TableCell>
				<TableCell>{/* conducteur aller */}</TableCell>
				<TableCell>{/* conducteur retour */}</TableCell>
				<TableCell>{/* commentaire RH */}</TableCell>
				<TableCell>{/* modifier */}</TableCell>
			</TableRow>
			{!collapsed &&
				usersReports.map((ur) => (
					<TableRow
						key={ur.id}
						style={{
							backgroundColor: ur.recruiter.trialPeriodEndDate ? 'darkgray' : '#f8f8f8',
							visibility: collapsed ? 'collapse' : 'visible',
						}}
					>
						<TableCell align="left" style={{ paddingLeft: '30px' }}>
							<Tooltip title={`${ur.recruiter.firstName} ${ur.recruiter.lastName}`}>
								<div>
									{ur.recruiter.role === 'team_manager' ? 'RE : ' : ''}
									{ur.recruiter.firstName}
								</div>
							</Tooltip>
						</TableCell>
						<TableCell align="left">{AbsenceDisplay[ur.absence]}</TableCell>
						<TableCell align="left">{ur.usedTickets}</TableCell>
						<TableCell align="left">{ur.usedPaperTickets}</TableCell>
						<TableCell align="left">{ur.paidHours}</TableCell>
						<TableCell align="left">
							{ur.expose025 ? 'Exposé 025' : ur.expose050 ? 'Exposé 050' : ''}
						</TableCell>
						<TableCell align="left">{ur.routesDuration}</TableCell>
						<TableCell align="left">{ur.perDiem}</TableCell>
						<TableCell align="left">{ur.driverGoing}</TableCell>
						<TableCell align="left">{ur.driverReturn}</TableCell>
						<TableCell align="left">
							<LongTextCell text={ur.commentHR} />
						</TableCell>
						<TableCell>
							{!collapsed && (
								<PrimaryButton size="small" onClick={() => setReportInEdition(ur)}>
									<Edit />
								</PrimaryButton>
							)}
						</TableCell>
					</TableRow>
				))}
		</>
	);
};

const StyledTable = styled(Table)({
	marginTop: '20px',
	border: '1px solid lightgray',
});

const WeekHeadCells = styled(TableCell)({
	color: 'white !important',
	fontWeight: 'bold',
});

type WeeksProps = {
	weekId: number;
	week: MissionPayrollDetailsDto['weeks'][number];
	setReportInEdition: React.Dispatch<React.SetStateAction<Partial<MissionDailyReportOnRecruiterDto>>>;
};

const Week = ({ weekId, week: { days, totals }, setReportInEdition }: WeeksProps) => {
	const classes = useStyles({ border: false });
	return (
		<StyledTable size="medium" classes={{ root: classes.tableRoot }}>
			<TableHead style={{ backgroundColor: '#00008bc2' }}>
				<TableRow>
					<WeekHeadCells>
						<div>Semaine {weekId}</div>
						<div>Totaux</div>
					</WeekHeadCells>
					<WeekHeadCells>
						<div>Absences</div>
						<div>{totals.absences}</div>
					</WeekHeadCells>
					<WeekHeadCells>
						<div>Swile</div>
						<div>{totals.usedTickets}</div>
					</WeekHeadCells>
					<WeekHeadCells>
						<div>TR Papier</div>
						<div>{totals.usedPaperTickets}</div>
					</WeekHeadCells>
					<WeekHeadCells>
						<div>Heures payées</div>
						<div>{totals.paidHours}</div>
					</WeekHeadCells>
					<WeekHeadCells>
						<div>Exposés</div>
						<div>{totals.exposes}</div>
					</WeekHeadCells>
					<WeekHeadCells>
						<div>Temps de trajet</div>
						<div>{totals.routesDurations}</div>
					</WeekHeadCells>
					<WeekHeadCells>
						<div>Per Diem</div>
						<div>{totals.perDiems}</div>
					</WeekHeadCells>
					<WeekHeadCells>
						<div>Conducteur</div>
						<div>Aller</div>
					</WeekHeadCells>
					<WeekHeadCells>
						<div>Conducteur</div>
						<div>Retour</div>
					</WeekHeadCells>
					<WeekHeadCells>
						<div>Commentaire RH</div>
						<div>&#8203;</div>
					</WeekHeadCells>
					<WeekHeadCells>
						<div>Modifier</div>
						<div>&#8203;</div>
					</WeekHeadCells>
				</TableRow>
			</TableHead>
			<TableBody>
				{Object.entries(days).map(([dayId, day]) => (
					<Day key={dayId} day={day} setReportInEdition={setReportInEdition} />
				))}
			</TableBody>
		</StyledTable>
	);
};

const MissionTotals = ({ totals, mission }: Partial<MissionPayrollDetailsDto>) => {
	return (
		<StyledTable style={{ width: 'auto' }}>
			<TableHead>
				<TableRow>
					<TableCell>Mission</TableCell>
					<TableCell>Absences</TableCell>
					<TableCell>Tickets restaurant initiaux</TableCell>
					<TableCell>Swile utilisés</TableCell>
					<TableCell>Tickets restaurant papier utilisés</TableCell>
					<TableCell>Heures payées</TableCell>
					<TableCell>Exposés</TableCell>
					<TableCell>Temps de trajet</TableCell>
					<TableCell>Per Diem</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow>
					<TableCell>Totaux</TableCell>
					<TableCell>{totals.absences}</TableCell>
					<TableCell>{mission.initialTickets}</TableCell>
					<TableCell>{totals.usedTickets}</TableCell>
					<TableCell>{totals.usedPaperTickets}</TableCell>
					<TableCell>{totals.paidHours}</TableCell>
					<TableCell>{totals.exposes}</TableCell>
					<TableCell>{totals.routesDurations}</TableCell>
					<TableCell>{totals.perDiems}</TableCell>
				</TableRow>
			</TableBody>
		</StyledTable>
	);
};

type ReferralTableProps = {
	sponsorships: MissionPayrollDetailsDto['sponsorships'];
};

const ReferralTable = ({ sponsorships }: ReferralTableProps) => {
	return (
		<Box>
			<Title>Référents</Title>
			{!sponsorships.length ? (
				'Aucun parrainage sur cette mission'
			) : (
				<StyledTable style={{ width: 'auto' }}>
					<TableHead>
						<TableRow>
							<TableCell>Référent</TableCell>
							<TableCell>Filleul</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sponsorships.map(({ sponsor, referred }) => (
							<TableRow key={referred.id}>
								<TableCell>
									{sponsor.firstName} {sponsor.lastName}
								</TableCell>
								<TableCell>
									{referred.firstName} {referred.lastName}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</StyledTable>
			)}
		</Box>
	);
};

type ExpensesTableProps = {
	expenses: MissionPayrollExpenseDto[];
	missionId: number;
	creatorId: number;
};

const ExpensesTable = ({ expenses, missionId, creatorId }: ExpensesTableProps) => {
	const [expenseInEdition, setExpenseInEdition] = useState<Partial<MissionPayrollExpenseDto>>();
	const resetExpenseEdition = () => setExpenseInEdition(undefined);

	return (
		<Box my={2}>
			{expenseInEdition && (
				<Modal open={!!expenseInEdition} onClose={resetExpenseEdition}>
					<EditMissionPayrollExpense
						expense={expenseInEdition}
						update={() => {
							resetExpenseEdition();
							window.location.reload();
						}}
						exit={resetExpenseEdition}
					/>
				</Modal>
			)}
			<Box display="flex">
				<Title>Dépenses</Title>
				<PrimaryButton
					marginLeft="20px"
					size="small"
					onClick={() =>
						setExpenseInEdition({
							missionId,
							creatorId,
						})
					}
				>
					<Add />
				</PrimaryButton>
			</Box>
			{!expenses?.length ? (
				'Aucune dépense sur cette mission'
			) : (
				<StyledTable style={{ width: 'auto' }}>
					<TableHead>
						<TableRow>
							<TableCell>Nom</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>Montant</TableCell>
							<TableCell>Date</TableCell>
							<TableCell>Commentaire</TableCell>
							<TableCell>{/* modifier */}</TableCell>
							<TableCell>{/* supprimer */}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{expenses.map((e) => (
							<TableRow key={e.id}>
								<TableCell>
									{e.creator?.firstName} {e.creator?.lastName}
								</TableCell>
								<TableCell>{MissionPayrollExpenseDisplay[e.type]}</TableCell>
								<TableCell>{e.amount}</TableCell>
								<TableCell>{moment(e.date).format('DD/MM/YYYY')}</TableCell>
								<TableCell>
									<LongTextCell text={e.text} />
								</TableCell>
								<TableCell>
									<PrimaryButton mini onClick={() => setExpenseInEdition(e)}>
										<Edit />
									</PrimaryButton>
								</TableCell>
								<TableCell>
									<DeleteButton
										mini
										url={routes.missions.payrollExpenses.delete({ id: e.id + '' })}
										onSuccess={() => window.location.reload()}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</StyledTable>
			)}
		</Box>
	);
};

const PayrollMission = () => {
	const { id = '0' } = useParams<{ id: string }>();
	const [selectedMissionUser, setSelectedMissionUser] = useState<number>(0);
	// this useFetch is used to get the mission users
	const [isLoading, missionPayrollDetails] = useFetch<MissionPayrollDetailsDto>(
		routes.missions.getMissionPayrollDetails({ id, selectedMissionUserId: 0 }),
	);

	const [refresh, setRefresh] = useState(0);
	const refreshData = () => setRefresh(refresh + 1);
	let [isFilesLoading, files] = useFetch<FileDto[]>(
		routes.files.all(
			{},
			{
				missionId: +id,
				categories: ['REQUESTABSENCE', 'REQUESTPAIDLEAVE', 'REQUESTUNPAIDLEAVE', 'REQUESTDEPOSIT'],
			},
		),
		[refresh],
	);

	// this one is used for data
	const [isLoadingFiltered, missionPayrollDetailsFiltered] = useFetch<MissionPayrollDetailsDto>(
		routes.missions.getMissionPayrollDetails({ id, selectedMissionUserId: selectedMissionUser }),
		[selectedMissionUser],
	);
	const [missionUsers, setMissionUsers] = useState<{ id: number; label: string }[]>([]);
	const { weeks, usersWarning } = missionPayrollDetails || {};
	const { mission, totals, weeks: weeksFiltered } = missionPayrollDetailsFiltered || {};

	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));

	const [reportInEdition, setReportInEdition] = useState<Partial<MissionDailyReportOnRecruiterDto>>();
	const resetReportEdition = () => setReportInEdition(undefined);

	const [bonusInEdition, setBonusInEdition] = useState<{
		name: 'maintenanceBonus' | 'trainingBonus';
		newValue: boolean;
	}>();
	const resetBonusInEdition = () => setBonusInEdition(undefined);

	const updateMaintenanceBonus = (newValue: boolean) => {
		setBonusInEdition({ name: 'maintenanceBonus', newValue });
	};

	const updateTrainingBonus = (newValue: boolean) => {
		setBonusInEdition({ name: 'trainingBonus', newValue });
	};

	const confirmBonusUpdate = async () => {
		post(routes.missions.partialEdit, {
			id: mission.id,
			[bonusInEdition.name]: bonusInEdition.newValue,
		}).then(() => {
			setBonusInEdition(undefined);
			window.location.reload();
		});
	};

	useEffect(() => {
		if (weeks) {
			const usersId = [];
			const users = [{ id: 0, label: 'Tous' }];

			Object.values(weeks).forEach((week) => {
				Object.values(week.days).forEach((day) => {
					day.usersReports.forEach((report) => {
						if (!usersId.includes(report.recruiterId)) {
							usersId.push(report.recruiterId);
							users.push({
								id: report.recruiterId,
								label: report.recruiter.firstName + ' ' + report.recruiter.lastName,
							});
						}
					});
				});
			});
			setMissionUsers(users);
		}
	}, [weeks]);

	return (
		<Box px={4} pb={4} pt={1}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<ReturnButton to={`/paie`}>Retour aux missions</ReturnButton>
				<Link href={`${routes.missions.excelPayroll}?missionId=${id}`}>
					<PrimaryButton size="small" style={{ padding: '6px 0px', marginTop: 5 }}>
						<FaFileExcel fontSize={20} />
					</PrimaryButton>
				</Link>
			</div>
			{isLoading || isLoadingFiltered ? (
				<CircularProgress />
			) : (
				<div style={{ display: 'block' }}>
					<Title>
						Mission <b>{mission.tricoCode}</b> du{' '}
						<b>{moment(mission.startDate).format('DD/MM/YYYY')}</b> au{' '}
						<b>{moment(mission.endDate).format('DD/MM/YYYY')}</b>
					</Title>

					<MissionTotals totals={totals} mission={mission} />

					{reportInEdition && (
						<Modal open={!!reportInEdition} onClose={resetReportEdition}>
							<EditDailyReportOnRecruiter
								userReport={reportInEdition}
								update={() => {
									resetReportEdition();
									window.location.reload();
								}}
								exit={resetReportEdition}
							/>
						</Modal>
					)}

					{bonusInEdition && (
						<Modal open={!!bonusInEdition} onClose={resetBonusInEdition}>
							<Box>
								<Box marginBottom={2}>
									Êtes-vous sûr de vouloir {bonusInEdition.newValue ? 'attribuer' : 'supprimer'} la
									prime {bonusInEdition.name === 'trainingBonus' ? 'de formation' : "d'entretien"} ?
								</Box>
								<Box display="flex" justifyContent="center">
									<Button variant="outlined" color="primary" onClick={resetBonusInEdition}>
										Annuler
									</Button>
									<Box mx={1}></Box>
									<Button variant="contained" color="primary" onClick={confirmBonusUpdate}>
										Confirmer
									</Button>
								</Box>
							</Box>
						</Modal>
					)}

					<Box display="flex" my={2}>
						<ReferralTable sponsorships={missionPayrollDetails.sponsorships} />
						<Box mx={4}>
							<Title>Prime d'entretien</Title>
							<Switch value={mission.maintenanceBonus} onChange={updateMaintenanceBonus} />
						</Box>
						<Box mx={2}>
							<Title>Prime de formation</Title>
							<Switch value={mission.trainingBonus} onChange={updateTrainingBonus} />
						</Box>
						<Box mx={2}>
							<Title>Filtrer par tricoteur</Title>
							<DropDown
								values={missionUsers.map((e) => e.label)}
								onChange={(e) => {
									missionUsers.forEach((user) => {
										if (user.label === e) setSelectedMissionUser(user.id);
									});
								}}
								margin={8}
								width={150}
								label="Tricoteur"
								value={missionUsers.find((e) => e.id === selectedMissionUser)?.label}
							/>
						</Box>
					</Box>

					{usersWarning.length > 0 && (
						<Box style={{ color: 'red' }}>
							Attention, changement de grade récent :{' '}
							{usersWarning.map((u, index) => (
								<span key={u.id}>
									{u?.firstName} {u?.lastName}
									{index < usersWarning.length - 1 ? ', ' : ''}
								</span>
							))}
						</Box>
					)}

					<ExpensesTable
						expenses={missionPayrollDetails.mission.missionPayrollExpenses}
						creatorId={0}
						missionId={mission.id}
					/>

					{/* PDF TABLE */}
					{!isFilesLoading && files.length ? (
						<PDFTable files={files} mb={3} refreshData={refreshData} />
					) : null}

					{weeksFiltered &&
						Object.entries(weeksFiltered).map(([weekId, week]) => (
							<div
								key={weekId}
								style={{
									overflow: 'auto',
									maxWidth: isBigScreen ? 'calc(100vw - 364px)' : 'calc(100vw - 20px)',
								}}
							>
								<Week weekId={+weekId} week={week} setReportInEdition={setReportInEdition} />
							</div>
						))}
				</div>
			)}
		</Box>
	);
};

export default PayrollMission;
