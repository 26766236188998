import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import { MissionWithAssociationDto } from '../../dto/mission';
import routes from '../../api/routes';
import { sortUsersMission } from '../missions/MissionDetails';
import { MissionUserDto } from '../../dto/user';
import { Box, Typography } from '@material-ui/core';
import LightUsersMissionTable from './LightUsersMissionTable';
import { StockEntry } from '../../dto/stock';
import LightStocksMissionTable from './LightStocksMissionTable';

export type SmartStock = {
	id: number;
	name: string;
	stockEntry: StockEntry[];
};

const StocksMission = () => {
	const { id = '0' } = useParams<{ id: string }>();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	let [isLoading, mission] = useFetch<MissionWithAssociationDto>(routes.missions.get({ id: +id }));
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	let [isLogoLoading, logo] = useFetch<{ logo: string; badgeText: string }>(
		routes.missions.logo({ id: +id }),
	);
	const [users, setUsers] = useState<MissionUserDto[]>([]);
	const [smartStock, setSmartStock] = useState<SmartStock[]>([]);
	const [areUsersLoading] = useFetch<MissionUserDto[]>(routes.missions.users.all({ id }), [], (users) =>
		setUsers(sortUsersMission(users)),
	);
	const [areStocksLoading, stocksMission] = useFetch<StockEntry[]>(
		routes.logistics.stocks.getMissionStocksTable({ missionId: id }),
		[],
	);
	useEffect(() => {
		if (stocksMission && stocksMission?.length !== 0) {
			const smartStockMap: Map<string, SmartStock> = new Map();

			stocksMission.forEach((stockEntry) => {
				// Check if a SmartStock object with the same type exists in the map
				let smartStock = smartStockMap.get(stockEntry.type);

				// If not, create a new SmartStock object and add it to the map
				if (!smartStock) {
					smartStock = {
						id: stockEntry.id,
						name: stockEntry.type,
						stockEntry: [],
					};
					smartStockMap.set(stockEntry.type, smartStock);
				}

				// Add the stockEntry to the SmartStock object
				smartStock.stockEntry.push(stockEntry);
			});
			console.log(Array.from(smartStockMap.values()));
			setSmartStock(Array.from(smartStockMap.values()));
		}
	}, [stocksMission]);

	console.log('mission', mission);
	console.log('users', users);
	console.log('stocksMission', stocksMission);
	console.log('smartStock', smartStock);

	return (
		<Box px={4} pb={4} pt={1}>
			{/* Header */}
			<Box py={2}>
				<Typography color="textPrimary">
					Stocks par Mission - {mission?.tricoCode} - {mission?.association?.name}
				</Typography>
				<Typography color="textPrimary">
					{users?.filter((u) => u.clothingSize === 'xs').length}XS -{' '}
					{users?.filter((u) => u.clothingSize === 's').length}S -{' '}
					{users?.filter((u) => u.clothingSize === 'm').length}M -{' '}
					{users?.filter((u) => u.clothingSize === 'l').length}L -{' '}
					{users?.filter((u) => u.clothingSize === 'xl').length}XL -{' '}
					{users?.filter((u) => u.clothingSize === '2xl').length}2XL -{' '}
					{users?.filter((u) => u.clothingSize === '3xl').length}3XL
					{users?.filter((u) => u.clothingSize === '').length > 0 && (
						<b> - {users?.filter((u) => u.clothingSize === '').length}NA</b>
					)}
				</Typography>
			</Box>

			{/* Récapitulatif */}

			{/* Objects list */}
			<LightStocksMissionTable smartStock={smartStock} areStocksLoading={areStocksLoading} />
			<div style={{ height: 20 }} />

			{/* Users list */}
			<LightUsersMissionTable users={users} areUsersLoading={areUsersLoading} />
		</Box>
	);
};

export default StocksMission;
