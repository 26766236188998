import { Box, Link } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import React from 'react';
import { Helmet } from 'react-helmet';
import { HashRouter as Router, Link as RouterLink, Route, useParams } from 'react-router-dom';
import ChangePasswordForm from '../components/user/ChangePasswordForm';
import ForgotPasswordForm from '../components/user/ForgotPasswordForm';
import LoginForm from '../components/user/LoginForm';

const BackToLogin = () => {
	return (
		<Box py={2} style={{ position: 'absolute', top: 0 }}>
			<Link component={(props) => <RouterLink to="/" {...props} />}>
				<Box display="flex" alignItems="center">
					<ChevronLeft fontSize="large" />
					<span>Écran de connexion</span>
				</Box>
			</Link>
		</Box>
	);
};

const LoginPage = () => {
	return (
		<Box px={2} display="flex" justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
			<Helmet>
				<title>Leto</title>
			</Helmet>
			<Router>
				<Route exact path="/">
					<Box display="flex" flexDirection="column">
						<LoginForm style={{ width: '100%', maxWidth: 450 }} />
						<Box py={1}>
							<Link component={(props) => <RouterLink to="/mot-de-passe-oublie" {...props} />}>
								Configurer ou réinitialiser mon mot de passe
							</Link>
						</Box>
					</Box>
				</Route>

				<Route path="/mot-de-passe-oublie">
					<Box display="flex" flexDirection="column" style={{ width: '100%', maxWidth: 450 }}>
						<BackToLogin />
						<ForgotPasswordForm />
					</Box>
				</Route>

				<Route path="/nouveau-mot-de-passe/:token">
					<Box display="flex" flexDirection="column" style={{ width: '100%', maxWidth: 450 }}>
						<BackToLogin />
						<ChangePassword />
					</Box>
				</Route>
			</Router>
		</Box>
	);
};

const ChangePassword = () => {
	let { token } = useParams<{ token: string }>();
	return <ChangePasswordForm token={token || ''} />;
};

export default LoginPage;
