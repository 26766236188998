import React, { useState } from "react";
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import routes from "../../api/routes";
import TextInput from "../form/TextInput";
import useForm from "../../hooks/useForm";
import ErrorMessage from "../form/ErrorMessage";

type ResetPasswordFormProps = {
  style?: React.CSSProperties;
  token: string;
};

const ChangePasswordForm = ({ style, token }: ResetPasswordFormProps) => {
  let [showSuccessMsg, setShowSuccessMsg] = useState(false);
  let classes = useStyles({ border: false });
  let form = useForm({
    url: routes.auth.validPasswordChange,
    init: { password: "", confirmation: "", token },
    validation: ({ password, confirmation }, addError) => {
      if (password.length < 6) addError('password', 'Mot de passe trop court. Il doit faire au moins 6 caractères de long.')
      if (password !== confirmation) addError("confirmation", 'La confirmation ne correspond pas au mot de passe')
    },
    onSuccess: (response) => {
      setShowSuccessMsg(true);
    }
  });

  return <form noValidate onSubmit={form.submit} style={style}>
    <ErrorMessage>{form.errors?.global || ' '}</ErrorMessage>
    {
      showSuccessMsg && (<Box className={classes.successMessage} py={2}>
        Votre mot de passe a bien été changé.
      </Box>)
    }
    <Typography align="justify" color="primary">
      CHANGEMENT DU MOT DE PASSE
    </Typography>
    <Box py={1}>
      <TextInput {...form.field('password')} fullWidth label="Nouveau mot de passe" type="password" variant="outlined" />
    </Box>
    <Box py={1} pb={2}>
      <TextInput {...form.field('confirmation')} fullWidth label="Confirmation" type="password" variant="outlined" />
    </Box>
    <Button
      fullWidth
      disabled={!form.values.password || !form.values.confirmation || showSuccessMsg}
      type="submit" size="large" variant="contained" color="primary">
      Changer mon mot de passe
    </Button>
  </form>;
};

const useStyles = makeStyles({
  successMessage: {
    color: "#448866"
  }
});

export default ChangePasswordForm;