import { CircularProgress, Fade } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { PictureAsPdf } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { default as ErrorIcon } from '@material-ui/icons/Error';
import React, { useEffect, useState } from 'react';
import { FileDto } from '../../dto/file';

export type FilePreviewProps = {
	progress?: number;
	style?: React.CSSProperties;
	selected?: boolean;
	onClick?: () => any;
	failed?: boolean;
	file: FileDto;
	local?: boolean;
	width?: number;
	height?: number;
};

const FilePreview = ({
	file,
	width,
	height,
	progress,
	style,
	selected,
	onClick,
	failed,
}: FilePreviewProps) => {
	let theme = useTheme();
	let [done, setDone] = useState(false);
	let src = file?.type === 'image' ? (file as any).localPath ?? file.path : '';

	useEffect(() => {
		if (progress === 1 && !failed) {
			setDone(true);
			setTimeout(() => setDone(false), 2000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [progress]);

	return (
		<div
			style={{
				...style,
				width: width ?? 100,
				height: height ?? 100,
				position: 'relative',
				boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.2)',
				transition: 'outline 0.1s',
				outline: selected
					? `3px solid ${theme.palette.primary.light}`
					: `0px solid ${theme.palette.primary.light}`,
			}}
			onClick={onClick}
			title={file.name}
		>
			<div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%',
					height: '100%',
					backgroundColor: file.type === 'pdf' ? '#E14045' : undefined,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundImage: `url(${src || ''})`,
				}}
			>
				{file?.type === 'pdf' && <PictureAsPdf style={{ color: 'white', fontSize: 50 }} />}
			</div>
			<div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%',
					height: '100%',
				}}
			>
				<Fade in={failed} timeout={500} style={{ position: 'absolute', top: '40', left: '40' }}>
					<ErrorIcon color="error" fontSize="large" />
				</Fade>
				<Fade in={done && !failed} timeout={500} style={{ position: 'absolute', top: '40', left: '40' }}>
					<CheckCircleIcon color="primary" fontSize="large" />
				</Fade>
				{(progress ?? 1) !== 1 && <CircularProgress variant="static" value={(progress ?? 1) * 100} />}
				{file.type !== 'image' && (
					<div
						style={{
							position: 'absolute',
							left: 0,
							bottom: 0,
							height: 40,
							width: '100%',
							boxSizing: 'border-box',
							padding: 5,
							background: 'rgba(0,0,0,0.5)',
							color: 'white',
							fontSize: 12,
							textOverflow: 'ellipsis',
							overflow: 'hidden',
						}}
					>
						{file?.name}
					</div>
				)}
			</div>
		</div>
	);
};

export default FilePreview;
