import { Box, Button, InputLabel, MenuItem } from '@material-ui/core';
import EuroIcon from '@material-ui/icons/Euro';
import ChipInput from 'material-ui-chip-input';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import routes from '../../api/routes';
import { AssociationDto } from '../../dto/association';
import { MissionDto, MissionTypes, Rhythms } from '../../dto/mission';
import { ProgramDto } from '../../dto/program';
import { Position, UserDto } from '../../dto/user';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import AssociationSelect from '../associations/AssocationSelect';
import { DatePickerProps, default as DefaultDatePicker } from '../form/DatePicker';
import ErrorMessage from '../form/ErrorMessage';
import { default as DefaultNumberInput, NumberInputProps } from '../form/NumberInput';
import ReturnButton from '../form/ReturnButton';
import { default as DefaultSelect, SelectProps } from '../form/Select';
import SubmitButton from '../form/SubmitButton';
import Switch from '../form/Switch';
import { default as DefaultTextInput, TextInputProps } from '../form/TextInput';
import Modal from '../Modal';
import Title from '../Title';
import { useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { MissionReportsDto } from '../../dto/missionReport';

type MissionFormProps = {
	url: string;
	init: MissionDto;
	edit?: boolean;
	children?: any;
	reports?: MissionReportsDto;
};

const MissionForm = ({ edit, init, url, children, reports }: MissionFormProps) => {
	let { user } = useAuth();
	const { id = '0' } = useParams<{ id: string }>();
	let form = useForm({ url, init });
	const commentUrl = routes.missions.reports.createOrUpdate;
	const midForm = useForm({
		url: commentUrl,
		init: {
			id: reports?.mid?.id,
			type: 'mid',
			missionId: id,
			date: moment().format('YYYY-MM-DD'),
			comment: reports?.mid?.comment,
			creatorId: user.id,
		},
	});
	const endForm = useForm({
		url: commentUrl,
		init: {
			id: reports?.end?.id,
			type: 'end',
			missionId: id,
			date: moment().format('YYYY-MM-DD'),
			comment: reports?.end?.comment,
			creatorId: user.id,
		},
	});

	const [isLoading, associations] = useFetch<AssociationDto[]>(routes.associations.all);
	const associationHashmap = associations
		? Object.fromEntries(associations.map((asso) => [asso.id, asso]))
		: {};

	useEffect(() => {
		const currentAsso = associationHashmap[form.field('associationId').value];
		if (!edit) {
			form
				.field('allowDonationsExportByDefault')
				.onChange(currentAsso?.allowDonationsExportByDefault || false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, form.field('associationId').value]);

	return (
		<form
			noValidate
			onSubmit={(e) => {
				e.preventDefault();
				form.submit();
				edit && midForm.submit();
				edit && endForm.submit();
			}}
			style={{ maxWidth: 1000, width: '100%', position: 'relative' }}
		>
			<Box display="flex" justifyContent="space-between" flexWrap="wrap">
				<ReturnButton to={`/missions`} askConfirmation>
					Retour aux missions
				</ReturnButton>
				{children}
			</Box>

			<Title big>{edit ? 'Modification' : 'Création'} d'une mission</Title>

			{form.errors && <ErrorMessage>{form?.errors?.global}</ErrorMessage>}
			<Box display="flex" flexWrap="wrap" mx={-0.5} mb={2}>
				<DatePicker {...form.field('startDate')} label="Date de début" required />
				<DatePicker {...form.field('endDate')} label="Date de fin" required />
				<Select {...form.field('rhythm')} label="Format de la mission" required>
					{Object.entries(Rhythms).map(([value, label]) => (
						<MenuItem key={value} value={value}>
							{label}
						</MenuItem>
					))}
				</Select>
				<Select {...form.field('missionType')} label="Type de mission" required>
					{Object.entries(MissionTypes).map(([value, label]) => (
						<MenuItem key={value} value={value}>
							{label}
						</MenuItem>
					))}
				</Select>
				<TextInput {...form.field('tricoCode')} label="Code Trico" />
				<AssociationSelect
					{...form.field('associationId')}
					label="Association"
					required
					width={350}
					flexGrow={1}
					margin={8}
					variant="outlined"
					onChange={(v) => {
						form.field('programId').onChange(undefined);
						form.field('associationId').onChange(v);
					}}
				/>
				<ProgramSelect
					{...form.field('programId')}
					associationId={form.field('associationId').value}
					label="Programme de l'association"
					required
				/>
				<TextInput
					margin="8px"
					{...form.field('ongCode')}
					onChange={(v) => form.field('ongCode').onChange((v || '').replace(/\s/g, ''))}
					label="Code ONG"
				/>
				<TextInput {...form.field('mainCity')} label="Ville principale" />
				<TextInput {...form.field('secondaryCity')} label="Ville secondaire" />
				<SpotInput
					label="Spots ville principale (séparés par ',')"
					defaultValue={form.values.spots ? form.values.spots.split(',') : []}
					onChange={(chips) => form.field('spots').onChange(chips.map((chip) => chip.trim()).join(','))}
				/>
				<SpotInput
					label="Spots ville secondaire (séparés par ',')"
					defaultValue={form.values.secondaryCitySpots ? form.values.secondaryCitySpots.split(',') : []}
					onChange={(chips) =>
						form.field('secondaryCitySpots').onChange(chips.map((chip) => chip.trim()).join(','))
					}
				/>
				<NumberInput
					{...form.field('minimumDonation')}
					label="Don minimum"
					icon={<EuroIcon />}
					iconPosition="end"
				/>
				<NumberInput {...form.field('initialTickets')} label="Tickets restaurants initiaux" />
				<UserSelect
					{...form.field('teamManagerId')}
					positions={['RE']}
					label="Responsable de l'équipe"
					required
				/>
				<UserSelect
					{...form.field('programManagerId')}
					positions={['ROT']}
					label="Responsable opérationnel terrain"
					required
				/>
				{edit && (
					<Box width="100%">
						<Title mt={4} small>
							Commentaire de mi mission
						</Title>
						<Box m={-0.5} my={0.05} display="flex" width="100%">
							<TextInput {...midForm.field('comment')} />
						</Box>

						<Title mt={4} small>
							Commentaire de fin de mission
						</Title>
						<Box m={-0.5} my={0.05} display="flex">
							<TextInput {...endForm.field('comment')} />
						</Box>
					</Box>
				)}
			</Box>

			<Title>Permission d'envoi des bulletins</Title>
			<InputLabel>
				Si cette option est activé, par défaut les bulletins de cette mission seront envoyés sans
				validation manuelle
			</InputLabel>
			<InputLabel>
				Si elle n'est pas activé, il faudra manuellement cocher tout les bulletins dont on veut autoriser
				l'envoi
			</InputLabel>
			<Switch {...form.field('allowDonationsExportByDefault')} color="primary" />

			<Box display="flex" justifyContent="end">
				<SubmitButton
					submitting={form.isSubmitting || midForm.isSubmitting || endForm.isSubmitting}
					success={edit ? form.success && midForm.success && endForm.success : form.success}
				>
					{edit ? 'Enregistrer' : 'Créer'} la mission
				</SubmitButton>
			</Box>

			<Modal open={form.success} noCloseButton>
				<p>La mission a bien été {edit ? 'modifiée' : 'créée'} !</p>
				<RouterLink to={`/missions`}>
					<Button variant="contained" color="primary">
						Retour aux missions
					</Button>
				</RouterLink>
			</Modal>
		</form>
	);
};

const ProgramSelect = function <T>(props: { associationId: number | null } & SelectProps<T>) {
	const [isLoading, programs] = useFetch<ProgramDto[]>(
		routes.associations.programs({ id: props.associationId }),
		[props.associationId],
	);

	return (
		<Select label="Association" {...props}>
			{!isLoading &&
				programs.map((program) => (
					<MenuItem key={program.id} value={program.id}>
						{moment(program.startDate).format('DD/MM/YYYY')} - {program.programType}
					</MenuItem>
				))}
		</Select>
	);
};

const UserSelect = function <T>(props: SelectProps<T> & { positions?: Position[] }) {
	const [isLoading, users] = useFetch<UserDto[]>(
		routes.users.search(
			{},
			{
				positions: JSON.stringify(props.positions || []),
			},
		),
	);

	return (
		<Select label="Selectionner un utilisateur" {...props}>
			{!isLoading &&
				users.filter((user) => user.state === 'Actif').map((user) => (
					<MenuItem key={user.id} value={user.id}>
						{user.firstName} {user.lastName}
					</MenuItem>
				))}
		</Select>
	);
};

const DatePicker = (props: DatePickerProps) => {
	return <DefaultDatePicker {...props} format="DD/MM/YYYY" width={350} flexGrow={1} margin={8} />;
};

const TextInput = (props: TextInputProps) => {
	return (
		<DefaultTextInput
			fullWidth
			{...(props as any)}
			width={350}
			flexGrow={1}
			margin={8}
			variant="outlined"
		/>
	);
};

const Select = function <T>(props: SelectProps<T>) {
	return (
		<DefaultSelect fullWidth {...(props as any)} width={350} flexGrow={1} margin={8} variant="outlined" />
	);
};

const NumberInput = (props: NumberInputProps) => {
	return (
		<DefaultNumberInput
			fullWidth
			{...(props as any)}
			width={350}
			flexGrow={1}
			margin={8}
			variant="outlined"
		/>
	);
};

const SpotInput: typeof ChipInput = (props) => {
	return (
		<ChipInput
			label="Sports"
			style={{
				width: 350,
				flexGrow: 1,
				margin: 8,
			}}
			variant={'outlined' as any}
			newChipKeys={['Enter', ',']}
			{...props}
		/>
	);
};

export default MissionForm;
