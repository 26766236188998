import { Box, Button, Chip, FormLabel, InputLabel, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link as RouterLink } from 'react-router-dom';
import routes from '../../api/routes';
import { GradeDto } from '../../dto/grade';
import { Nationalities, NationalitiesList } from '../../dto/nationalities';
import { Roles } from '../../dto/role';
import {
	ApplicantSourceTypeDisplay,
	Categories,
	ClothingSizes,
	Contracts,
	ETAMCategoryOptions,
	Genders,
	ICCategoryOptions,
	MutuelleOptions,
	Positions,
	Titles,
	UserDto,
} from '../../dto/user';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import FilePicker from '../files/FilePicker';
import { DatePickerProps, default as DefaultDatePicker } from '../form/DatePicker';
import ErrorMessage from '../form/ErrorMessage';
import { default as DefaultNumberInput, NumberInputProps } from '../form/NumberInput';
import PhoneNumberInput from '../form/PhoneNumberInput';
import ReturnButton from '../form/ReturnButton';
import { default as DefaultSelect, SelectProps } from '../form/Select';
import SubmitButton from '../form/SubmitButton';
import Switch from '../form/Switch';
import { default as DefaultTextInput, TextInputProps } from '../form/TextInput';
import Modal from '../Modal';
import Title from '../Title';
import UserSelect, { UserSuggestion } from '../user/UserSelect';

type UserFormProps = {
	url: string;
	init: UserDto;
	edit?: boolean;
	children?: any;
};

const UserForm = ({ edit, init, url, children }: UserFormProps) => {
	const addressForm = useForm({ init: init.address });
	const form = useForm({ url, init });
	form.addSubform('address', addressForm);
	const isInEU = Nationalities[form.field('nationalityPassport').value]?.isInEU ?? true;
	const [sponsor, setSponsor] = useState<UserSuggestion>(
		init.sponsor
			? {
					id: init.sponsor.id + '',
					firstName: init.sponsor.firstName,
					lastName: init.sponsor.lastName,
					path: '',
			  }
			: null,
	);

	const [isSwileAnonymous, setIsSwileAnonymous] = useState(!!init.swileNumber);

	console.log('form', form.field('mutuelle').value);

	return (
		<form noValidate onSubmit={form.submit} style={{ maxWidth: 1000, width: '100%', position: 'relative' }}>
			<Box display="flex" justifyContent="space-between" flexWrap="wrap">
				<ReturnButton to={`/ressources-humaines`} askConfirmation>
					Retour à la page "ressources humaines"
				</ReturnButton>
				{children}
			</Box>

			<Title big>{edit ? 'Modification' : 'Création'} d'un utilisateur</Title>

			{form.errors && <ErrorMessage>{form?.errors?.global}</ErrorMessage>}

			<Title small>Information Tricoteur</Title>
			<Box display="flex" flexWrap="wrap" mx={-0.5} mb={2}>
				<Select values={Titles} {...form.field('title')} label="Civilité" required />
				<Select values={Genders} {...form.field('gender')} label="Sexe" required />
				<TextInput {...form.field('lastName')} label="Nom" required />
				<TextInput {...form.field('firstName')} label="Prénom" required />
				<Select values={ClothingSizes} {...form.field('clothingSize')} label="Taille de vêtements" />
				<TextInput {...form.field('tricoId')} label="Matricule" required />
				<DatePicker {...form.field('birthdate')} label="Date de naissance" />
				{/* Nationality is currently considered as Pays de naissance */}
				<NationalitySelect {...form.field('nationality')} required={false} />
				<TextInput {...form.field('birthCity')} label="Ville de naissance" />
				<TextInput {...form.field('birthZipCode')} label="Code postal de naissance" />
				<NationalitySelect {...form.field('nationalityPassport')} required passport />
				<TextInput
					{...form.field('residencePermit')}
					label="Titre de séjour"
					disabled={isInEU}
					required={!isInEU}
				/>
				<DatePicker
					{...form.field('residencePermetExpiryDate')}
					label="Fin de validité"
					disabled={isInEU}
					required={!isInEU}
				/>
				<TextInput
					{...form.field('residencePermitInstitution')}
					label="Organisme de délivrance"
					disabled={isInEU}
					required={!isInEU}
				/>
				<TextInput
					{...form.field('residencePermitQuotation')}
					label="Mention travail"
					disabled={isInEU}
					required={!isInEU}
				/>
				{/* <SelectFor values={Nationalities} {...form.field('nationality')} label="Nationalité" /> */}

				<TextInput {...addressForm.field('address')} label="Adresse postale" />
				<TextInput {...addressForm.field('zipCode')} label="Code postal" />
				<TextInput {...addressForm.field('city')} label="Ville" />
				<PhoneNumberInput
					{...form.field('phoneNumber')}
					label="Téléphone"
					type="tel"
					width={350}
					flexGrow={1}
					margin={8}
				/>
				<TextInput {...form.field('email')} label="Email" type="email" required />
				<TextInput {...form.field('emailPerso')} label="Email secondaire" type="email" />
				<TextInput {...form.field('rib')} label="IBAN" />
				<TextInput {...form.field('bic')} label="BIC" />
				<TextInput {...form.field('goodies')} label="Goodies" />
				<DatePicker {...form.field('updateHRInfosDate')} label="Mise à jour infos RH" />
				<Box p={2} mr={2.8} width={450}>
					<FormLabel component="legend">Carte Swile Anonyme</FormLabel>
					<Switch
						value={isSwileAnonymous}
						color="primary"
						onChange={(v) => {
							setIsSwileAnonymous(v);
							if (!v) form.field('swileNumber').onChange('');
						}}
					/>
				</Box>
				<TextInput
					style={{ visibility: isSwileAnonymous ? 'visible' : 'hidden' }}
					{...form.field('swileNumber')}
					label="Numéro de carte swile"
				/>
				<Box width="100%"></Box>
				<Box p={2} mr={2.8} width={90}>
					<FormLabel component="legend">Permis</FormLabel>
					<Switch {...form.field('isDriver')} color="primary" />
				</Box>
				<Box p={2} mr={2.8} width={305}>
					<FormLabel component="legend">Attestation de conduite</FormLabel>
					<Switch {...form.field('drivingCertificate')} color="primary" />
				</Box>
				<DatePicker {...form.field('drivingLicenseDate')} label="Date d'obtention du permis" />
				<TextInput {...form.field('socialSecurity')} label="Sécurité sociale" />
			</Box>
			<>
				<Title small>Contact d'urgence</Title>
				<Box display="flex" flexWrap="wrap" mx={-0.5} mb={2}>
					<TextInput {...form.field('emergencyContactRelationType')} label="Lien de parenté" />
					<Box width="100%"></Box>
					<TextInput {...form.field('emergencyContactLastName')} label="Nom" />
					<TextInput {...form.field('emergencyContactFirstName')} label="Prénom" />
					<PhoneNumberInput
						{...form.field('emergencyContactPhoneNumber')}
						label="Téléphone"
						type="tel"
						width={350}
						flexGrow={1}
						margin={8}
					/>
					<PhoneNumberInput
						{...form.field('emergencyContactPhoneNumber2')}
						label="Deuxième téléphone"
						type="tel"
						width={350}
						flexGrow={1}
						margin={8}
					/>
				</Box>

				<Title small>Informations contractuelles</Title>
				<Box display="flex" flexWrap="wrap" mx={-0.5} mb={2}>
					<Select values={Positions} {...form.field('position')} label="Rôle" />
					<GradeSelect {...form.field('gradeId')} label="Poste" />

					<Select values={Roles} {...form.field('role')} label="Privilèges" />

					<Box
						display={form.values.role === 'back_office' ? 'flex' : 'none'}
						gridGap={16}
						ml={2}
						width="100%"
					>
						<Box>
							<Box>
								<FormLabel component="legend">Tableau de bord</FormLabel>
								<Switch {...form.field('hasAccessToBODashboard')} color="primary" />
							</Box>
							<Box>
								<FormLabel component="legend">Gestion des bulletins</FormLabel>
								<Switch {...form.field('hasAccessToBODonations')} color="primary" />
							</Box>
						</Box>
						<Box>
							<Box>
								<FormLabel component="legend">Logistique et Comptabilité</FormLabel>
								<Switch {...form.field('hasAccessToBOLogistic')} color="primary" />
							</Box>
							<Box>
								<FormLabel component="legend">Opérationnel</FormLabel>
								<Switch {...form.field('hasAccessToBOMissions')} color="primary" />
							</Box>
						</Box>
						<Box>
							<Box>
								<FormLabel component="legend">Ressources humaines</FormLabel>
								<Switch {...form.field('hasAccessToBOHR')} color="primary" />
							</Box>
							<Box>
								<FormLabel component="legend">Entretiens</FormLabel>
								<Switch {...form.field('hasAccessToBOInterviews')} color="primary" />
							</Box>
						</Box>
					</Box>

					<Select values={Contracts} {...form.field('contract')} label="Type de contrat" />
					<NumberInput {...form.field('contractWeeklyHours')} label="Durée de travail hebdomadaire" />
					<TextInput
						{...form.field('contractWeekDays')}
						label="Jours de la semaines"
						disabled={parseInt(form.values.contractWeeklyHours + '') > 35}
					/>
					<Box width="100%"></Box>
					<DatePicker {...form.field('contractStartDate')} label="Date de début de contrat" />
					<DatePicker {...form.field('contractEndDate')} label="Date de fin de contrat" />
					<Select values={Categories} {...form.field('category')} label="Catégorie" />
					<Select
						values={
							form.values.category === 'ETAM'
								? ETAMCategoryOptions
								: form.values.category === 'IC'
								? ICCategoryOptions
								: {}
						}
						{...form.field('categoryOption')}
						label="Option catégorie"
					/>
				</Box>

				{/* <NumberInput {...form.field('hourlyRate')} label="Taux horaire" icon={<EuroIcon />} iconPosition="end" /> */}

				<Box display="flex" flexWrap="wrap" mx={-0.5} mb={2}>
					<Box width={150}>
						<FormLabel component="legend">Droit à l'image</FormLabel>
						<Switch {...form.field('pictureRights')} color="primary" />
					</Box>
					<Box width={150}>
						<FormLabel component="legend">Cons. RGPD</FormLabel>
						<Switch {...form.field('rgpd')} color="primary" />
					</Box>
					<Box width={150}>
						<FormLabel component="legend">Mutuelle</FormLabel>
						<Switch {...form.field('mutuelle')} color="primary" />
					</Box>
					{form.field('mutuelle').value === true && (
						<Box width={170}>
							<FormLabel component="legend">Mutuelle Option</FormLabel>
							<Select
								values={MutuelleOptions}
								{...form.field('mutuelleOption')}
								label="Options"
								width={100}
							/>
						</Box>
					)}
					<Box width={150}>
						<FormLabel component="legend">Prévoyance</FormLabel>
						<Switch {...form.field('prevoyance')} color="primary" />
					</Box>
					<Box width={150}>
						<FormLabel component="legend">RQTH</FormLabel>
						<Switch {...form.field('rqth')} color="primary" />
					</Box>
				</Box>

				<Box my={2}>
					<InputLabel>Photo de l'utilisateur</InputLabel>
					<FilePicker {...form.field('avatar')} label="Télécharger une photo" category="avatar" />
				</Box>

				<Box display="flex" justifyContent="flex-start" alignItems="center">
					<Box width={350} marginRight={3}>
						<Select
							values={ApplicantSourceTypeDisplay}
							{...form.field('applicantSource')}
							onChange={(applicantSource) => {
								form.setMultipleValues({ sponsorId: null, applicantSource });
								setSponsor(null);
							}}
							label="Origine du candidat"
						/>
					</Box>
					{form.field('applicantSource').value !== 'OTHER' ? (
						<Box
							width={350}
							style={{
								visibility: ['SPONSORSHIP', 'PRR'].includes(form.field('applicantSource').value)
									? 'visible'
									: 'hidden',
							}}
						>
							<UserSelect
								value={sponsor}
								positions={form.field('applicantSource').value === 'PRR' ? ['PRR'] : []}
								onChange={(user) => {
									form.field('sponsorId').onChange(user?.id ? +user.id : null);
									setSponsor(user);
								}}
							/>
						</Box>
					) : (
						<Box
							width={350}
							style={{
								visibility: ['OTHER'].includes(form.field('applicantSource').value)
									? 'visible'
									: 'hidden',
							}}
						>
							<TextInput
								marginLeft={8}
								{...form.field('applicantSourceOther')}
								label="Précision sur l'origine"
							/>
						</Box>
					)}
				</Box>

				<Title mt={4} small>
					Commentaire
				</Title>
				<Box m={-0.5} my={0.05} display="flex">
					<TextInput {...form.field('comment')} fullWidth multiline />
				</Box>

				<Title mt={4} small>
					Commentaire de troisième mission
				</Title>
				<Box m={-0.5} my={0.05} display="flex">
					<TextInput {...form.field('thirdMissionComment')} fullWidth multiline />
				</Box>
			</>
			<Box display="flex" justifyContent="end" mt={2}>
				<SubmitButton submitting={form.isSubmitting} success={form.success} edit={edit}>
					{edit ? 'Modifier' : 'Créer'} l'utilisateur
				</SubmitButton>
			</Box>

			<Modal open={form.success} noCloseButton>
				<p>L'utilisateur a bien été {edit ? 'modifié' : 'créé'} !</p>
				<RouterLink to={`/ressources-humaines`}>
					<Button variant="contained" color="primary">
						Retour à la page "ressources humaines"
					</Button>
				</RouterLink>
			</Modal>
		</form>
	);
};

export const GradeSelect = (props: SelectProps<string>) => {
	let [, grades] = useFetch<GradeDto[]>(routes.users.grades.all);
	let Grades = {};
	if (props?.selectAll) Grades[''] = 'Tous les postes';
	if (grades)
		grades.forEach(
			(grade) =>
				(+props?.value === grade.id || !grade?.archived) &&
				(Grades[grade.id] = `${grade?.archived ? 'ARCHIVÉ ' : ''}${grade.name} - ${grade.description} - ${
					grade.hourlyRate
				}`),
		);
	return <Select values={Grades} {...props} />;
};

export const DatePicker = (props: DatePickerProps) => {
	return <DefaultDatePicker {...props} format="DD/MM/YYYY" width={350} flexGrow={1} margin={8} />;
};

export const TextInput = (props: TextInputProps) => {
	return (
		<DefaultTextInput
			fullWidth
			width={350}
			flexGrow={1}
			margin={8}
			variant="outlined"
			{...(props as any)}
		/>
	);
};

export const Select = function <T>(props: SelectProps<T>) {
	return (
		<DefaultSelect fullWidth width={350} flexGrow={1} margin={8} variant="outlined" {...(props as any)} />
	);
};

export const NumberInput = (props: NumberInputProps) => {
	return <DefaultNumberInput fullWidth width={350} flexGrow={1} margin={8} variant="outlined" {...props} />;
};

const useNationalitySelectStyles = makeStyles({
	option: {
		fontSize: 15,
		'& > span': {
			marginRight: 10,
			fontSize: 18,
		},
	},
});

type NationalitySelectProps = {
	value: string;
	onChange: (value: string) => any;
	required: boolean;
	inputRef?: any;
	error?: string;
	passport?: boolean;
};
export const NationalitySelect = ({
	value,
	onChange,
	required,
	passport,
	...props
}: NationalitySelectProps) => {
	let classes = useNationalitySelectStyles();
	let ref = useRef();

	props.inputRef?.({
		checkValidity: () => !required || value,
		validationMessage:
			required && !value ? 'Ce champ est obligatoire, veuillez sélectionner une valeur' : '',
		scrollIntoView: (params: any) => (ref.current as any)?.scrollIntoView?.(params),
	});

	return (
		<Box width={350} flexGrow={1} margin={1}>
			<Autocomplete
				innerRef={ref}
				options={NationalitiesList}
				classes={{
					option: classes.option,
				}}
				value={Nationalities[value]}
				onChange={(e: any, val: { code: string }) => onChange(val?.code ?? null)}
				autoHighlight
				getOptionLabel={(option) => (passport ? option.nationality : option.name)}
				renderOption={(option) => (
					<React.Fragment>
						<Box mr={1}>
							<LazyLoadImage src={option.flag} style={{ width: 20 }} alt={'(' + option.code + ')'} />
						</Box>
						{option.isInEU && (
							<Box mr={1}>
								<Chip style={{ fontSize: 10, padding: 2 }} color="primary" size="small" label="UE" />
							</Box>
						)}
						{passport ? option.nationality : option.name}
					</React.Fragment>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						fullWidth
						error={!!props.error}
						helperText={props.error}
						label={passport ? 'Nationalité' : 'Pays de naissance'}
						variant="outlined"
						required={required}
						inputProps={{
							...params.inputProps,
							autoComplete: 'new-password', // disable autocomplete and autofill
						}}
					/>
				)}
			/>
		</Box>
	);
};

export default UserForm;
