import React from "react";
import Associations from "./Associations";
import CreateAssociation from "./CreateAssociation";
import EditAssociation from "./EditAssociation";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ViewAssociation from "./ViewAssociation";
import CreateProgram from "./CreateProgram";
import EditProgram from "./EditProgram";

const AssociationsPages = ({ match }: { match: { path: string } }) => {
	const { path } = useRouteMatch();

	return <Switch>
		<Route exact path={path} component={Associations} />
		<Route path={`${path}/creation`} component={CreateAssociation} />
		<Route path={`${path}/modifier/:id`} component={EditAssociation} />
		<Route exact path={`${path}/:id`} component={ViewAssociation} />
		<Route path={`${path}/:id/programmes/creation`} component={CreateProgram} />
		<Route path={`${path}/:id/programmes/modifier/:programId`} component={EditProgram} />
	</Switch>;
}

export default AssociationsPages;