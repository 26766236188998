import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { get, post } from '../../api';
import routes from '../../api/routes';
import { FileDto } from '../../dto/file';
import useFetch from '../../hooks/useFetch';
import Modal from '../Modal';
import FilePreview from './FilePreview';

let count = 0;

export type FilePickerModalProps = {
	open: boolean;
	category?: string;
	multiple?: boolean;
	onClose: () => any;
	onChange?: ((file: FileDto) => any) | ((file: FileDto[]) => any);
	accept?: string;
};

const FileModalPicker = ({
	open,
	onClose: onCloseProp,
	category,
	onChange,
	accept,
	multiple,
}: FilePickerModalProps) => {
	let uploadedFiles = useRef([] as any[]);
	let [, setEmtpy] = useState({});
	let forceUpdate = () => setEmtpy({});

	useFetch(routes.files.category({ category: category || '' }), [], (data: any) => {
		uploadedFiles.current = [...uploadedFiles.current, ...data];
	});

	const onClose = () => {
		onCloseProp?.();
		uploadedFiles.current.forEach((file) => (file.selected = false));
	};

	const createResizedImage = async (file, maxSize) => {
		return new Promise((resolve) => {
			const img = new Image();
			img.src = URL.createObjectURL(file);

			img.onload = () => {
				let width = img.width;
				let height = img.height;

				if (width > maxSize || height > maxSize) {
					const scale = maxSize / Math.max(width, height);
					width *= scale;
					height *= scale;
				}

				const canvas = document.createElement('canvas');
				canvas.width = width;
				canvas.height = height;
				const ctx = canvas.getContext('2d');
				ctx.drawImage(img, 0, 0, width, height);

				canvas.toBlob((blob) => {
					const resizedFile = new File([blob], file.name, { type: file.type });
					resolve(resizedFile);
				}, file.type);
			};
		});
	};

	const checkImageSize = async (file) => {
		return new Promise((resolve) => {
			const img = new Image();
			img.src = URL.createObjectURL(file);

			img.onload = () => {
				const width = img.width;
				const height = img.height;
				resolve({ width, height });
			};
		});
	};

	const onDrop = useCallback(async (acceptedFiles) => {
		for (let file of acceptedFiles) {
			let type = file.type.toLowerCase();
			type = type === 'application/pdf' ? 'pdf' : type.split('/')[0] === 'image' ? 'image' : type;

			let preview = {
				id: '',
				key: 'u' + count++,
				path: '',
				name: file.name,
				type,
				progress: 0,
				localPath: '',
			};

			uploadedFiles.current = [preview, ...uploadedFiles.current];

			if (type === 'image') {
				const imageSize: any = await checkImageSize(file);
				if (imageSize.width > 900 || imageSize.height > 900) {
					file = await createResizedImage(file, 900);
				}
				const reader = new FileReader();
				reader.addEventListener(
					'load',
					function () {
						preview.localPath = reader.result as string;
						forceUpdate();
					},
					false,
				);
				reader.readAsDataURL(file);
			}

			let { url: uploadUrl } = await get(routes.files.uploadToken);
			let data = await axios.request({
				method: 'put',
				url: uploadUrl,
				headers: { 'Content-Type': file.type },
				data: file,
				onUploadProgress: (p) => {
					preview.progress = p.loaded / p.total;
					forceUpdate();
				},
			});

			if (200 <= data.status && data.status < 400) {
				let path = uploadUrl.split('?')[0];
				let result = await post(routes.files.confirmUpload, { name: file.name, type, category, path });
				preview.progress = 1;
				preview.id = result.id;
				preview.path = result.path;
				preview.key = 'id' + (preview as any).id;
			} else {
				(preview as any).failed = true;
			}

			forceUpdate();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
		onDrop,
		accept: accept || undefined,
	});
	const classes = useStyles({ isDragAccept, isDragReject });
	const selection = uploadedFiles.current.filter((f) => f.selected);
	const isSelectionEmpty = selection.length < 1;

	return (
		<Modal open={open} onClose={onClose}>
			<div {...getRootProps()} className={classes.dropzone}>
				<input {...getInputProps()} />
				{isDragAccept ? (
					<p>Déposer le fichier ici...</p>
				) : isDragReject ? (
					<p style={{ color: '#FF1744' }}>Ce type de fichier n'est pas accepté</p>
				) : (
					<p>Glisser-déposer un fichier ici ou bien cliquez.</p>
				)}
			</div>

			<Box display="flex" flexWrap="wrap" className={classes.previewContainer}>
				{uploadedFiles.current.map((preview) => (
					<FilePreview
						key={'id' + (preview.id || '') || 'k' + (preview.key || '')}
						file={preview}
						local={preview.local || false}
						progress={preview.progress}
						selected={preview.selected}
						failed={preview.failed}
						onClick={() => {
							if ((preview.progress ?? 1) !== 1) return;
							if (preview.selected) {
								preview.selected = false;
							} else {
								if (!multiple) {
									uploadedFiles.current.forEach((file) => (file.selected = false));
								}
								preview.selected = true;
							}
							forceUpdate();
						}}
						style={{ margin: 8, cursor: (preview.progress ?? 1) === 1 ? 'pointer' : 'normal' }}
					/>
				))}
			</Box>

			<Box display="flex" justifyContent="end">
				<Box mr={1}>
					<Button onClick={onClose}>Fermer</Button>
				</Box>
				<Button
					disabled={isSelectionEmpty}
					variant="contained"
					color="primary"
					onClick={() => {
						onChange?.(multiple ? selection : selection[0]);
						onClose?.();
					}}
				>
					{isSelectionEmpty ? 'Sélectionnez un fichier' : 'Valider'}
				</Button>
			</Box>
		</Modal>
	);
};

let useStyles = makeStyles({
	dropzone: {
		display: 'flex',
		cursor: 'pointer',
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: 'calc(100vw - 30px)',
		height: 100,
		width: 800,
		textAlign: 'center',
		outline: 'none',
		borderWidth: 2,
		borderRadius: 2,
		borderColor: ({ isDragAccept, isDragReject }: { isDragAccept: boolean; isDragReject: boolean }) =>
			isDragAccept ? '#00e676' : isDragReject ? '#ff1744' : '#eeeeee',
		borderStyle: 'dashed',
	},
	previewContainer: {
		margin: '10px -8px',
		width: 815,
		maxWidth: 'calc(100vw - 30px)',
		border: '1px solid #EEE',
		height: 400,
		maxHeight: 'calc(100vh - 120px)',
		overflow: 'auto',
	},
});

export default FileModalPicker;
