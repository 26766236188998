import React, { useState } from 'react';
import SubmitButton from '../form/SubmitButton';
import { DonationDto } from '../../dto/donation';
import { post } from '../../api';
import routes from '../../api/routes';

const SendAgainAsso = ({ donation }: { donation: DonationDto }) => {
	const [confirm, setConfirm] = useState(false);
	const [success, setSuccess] = useState(null);

	console.log('sendAgainAsso', donation);
	const sendAgain = async () => {
		if (success !== null) return;
		const ret = await post(routes.donations.sendAgainAsso({ id: donation.id }));
		if (ret.msg === 'ok') setSuccess(true);
		else setSuccess(false);
	};

	const btnText = () => {
		if (success !== null) {
			return success === true ? 'Le Bulletin a été renvoyé' : `Erreur lors de l'envoie`;
		}
		return confirm  ? 'Confirmer le renvoi' : "Renvoyer le bulletin à l'association"
	}

	return (
		<div
			style={{
				justifyContent: 'center',
				textAlign: 'center',
				marginBottom: 10,
				minWidth: 400,
			}}
		>
			<SubmitButton
				size="small"
				variant="contained"
				color="primary"
				onClick={() => {
					if (!confirm) setConfirm(true);
					else sendAgain();
				}}
				icon={false}
				success={success}
			>
				{btnText()}
			</SubmitButton>
		</div>
	);
};
export default SendAgainAsso;
