import { Box } from '@material-ui/core';
import React from 'react';
import { default as DefaultTextInput, TextInputProps } from '../../components/form/TextInput';
import { ContactDto } from '../../dto/contact';
import useForm, { Form } from '../../hooks/useForm';
import PhoneNumberInput from '../form/PhoneNumberInput';

type ContactFormProps = {
	values: ContactDto;
	onChange: (value: ContactDto) => any;
	formRef: (form: Form<ContactDto>) => any;
};

const ContactForm = ({ values, onChange, formRef }: ContactFormProps) => {
	let form = useForm({ values, onChange });
	formRef?.(form);

	return (
		<Box display="flex" flexWrap="wrap" mx={-0.5}>
			<TextInput {...form.field('firstName')} label="Prénom" />
			<TextInput {...form.field('lastName')} label="Nom" />
			<TextInput {...form.field('position')} label="Poste" />
			<TextInput {...form.field('email')} label="Email" type="email" />
			<PhoneNumberInput
				{...form.field('phone')}
				width={350}
				flexGrow={1}
				margin={8}
				label="Téléphone"
				type="tel"
			/>
			<PhoneNumberInput
				{...form.field('secondPhone')}
				width={350}
				flexGrow={1}
				margin={8}
				label="Autre téléphone"
				type="tel"
			/>
			<TextInput {...form.field('dpo')} label="DPO (Email)" type="email" />
		</Box>
	);
};

const TextInput = (props: TextInputProps) => {
	return <DefaultTextInput {...(props as any)} width={350} flexGrow={1} margin={8} variant="outlined" />;
};

export default ContactForm;
