// You need to have the same roles/category/categoryOptions in grades and user tables

export const Roles = {
	"team_manager": "Responsable d'équipe",
	// "program_manager": "Responsable de programme",
	"operational_field_manager": "Responsable Opérationnel Terrain",
	"donor_recruiter": "Recruteur de Donateurs",
	"back_office": "Back Office"
};
export const RolesAbbreviations = {
	"team_manager": "RE",
	// "program_manager": "RP", 
	"operational_field_manager": "ROT",
	"donor_recruiter": "RD",
	"back_office": "BO"
};
export type Role = keyof typeof Roles;