export class ContactDto {
	id: number;
	firstName: string = '';
	lastName: string = '';
	position: string = '';
	phone: string = '';
	secondPhone: string = '';
	email: string = '';
	dpo: string = '';
}
