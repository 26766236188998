import { Box, Chip } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { useState } from 'react';
import routes from '../../api/routes';
import AssociationSelect from '../../components/associations/AssocationSelect';
import InterviewTable from '../../components/interviews/InterviewTable';
import Title from '../../components/Title';
import useFetch from '../../hooks/useFetch';

const Interviews = () => {
	const [associationId, setAssociationId] = useState<number>();
	const [missionStatus, setMissionStatus] = useState('future');
	const [, counts] = useFetch<{ current: number; future: number; past: number }>(
		routes.missions.counts({}, { associationId }),
		[associationId],
	);

	const tabs = [
		{ status: 'current', label: 'En cours' },
		{ status: 'future', label: 'À venir' },
		{ status: 'past', label: 'Terminés' },
	];

	return (
		<Box px={3} py={3}>
			<Box py={2}>
				<Box display="flex" mx={-1} flexWrap="wrap">
					<AssociationSelect
						value={associationId}
						onChange={(value) => setAssociationId(value)}
						label="Association"
						required
						width={350}
						flexGrow={1}
						margin={8}
						variant="outlined"
					/>
				</Box>
			</Box>
			<Box display="flex" justifyContent="space-between" marginBottom={2}>
				<Title>Liste des missions</Title>
			</Box>
			<Box mt={2}>
				<ToggleButtonGroup
					style={{ display: 'flex', flexWrap: 'wrap' }}
					exclusive
					value={missionStatus}
					onChange={(e, v) => v && setMissionStatus(v)}
				>
					{tabs.map(({ status, label }) => (
						<ToggleButton key={status} value={status}>
							<Box mr={1}>{label}</Box>
							{status !== 'custom' && (
								<Chip size="small" color="primary" label={(counts as any)?.[status as any] ?? '?'} />
							)}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
			</Box>
			<InterviewTable associationId={associationId} missionStatus={missionStatus} />
		</Box>
	);
};

export default Interviews;
