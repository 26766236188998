import React, { useRef } from 'react';
import {
	Select as MUSelect,
	FormControl,
	FormHelperText,
	InputLabel,
	makeStyles,
	Chip,
	MenuItem,
} from '@material-ui/core';

export type SelectProps<T = any> = {
	value?: T;
	type?: string;
	variant?: 'standard' | 'outlined' | 'filled';
	onChange?: (value: T) => any;
	label?: string;
	fullWidth?: boolean;
	error?: string;
	inputRef?: any;
	width?: number | string;
	flexGrow?: number | 'inherit' | 'initial' | '-moz-initial' | 'revert' | 'unset' | undefined;
	classes?: {
		formControl?: string;
		select?: string;
	};
	margin?: number | string;
	children?: any;
	required?: boolean;
	multiple?: boolean;
	disabled?: boolean;
	values?: Record<string, any> | Array<{ value: string; label: string }>;
	style?: React.CSSProperties;
	selectAll?: boolean;
};

const Select = function <T>(props: SelectProps<T>) {
	let ref = useRef();
	let classes = useStyles({ border: false });
	let children = props.children;
	if (!children && props.values) {
		children = [];
		let values: Array<{ value: string; label: string }> = [];
		if (Array.isArray(props.values)) {
			values = props.values;
		} else if (typeof props.values === 'object') {
			values = Object.entries(props.values).map(([k, v]) => ({ value: k, label: v }));
		}

		for (let { value, label } of values) {
			children.push(
				<MenuItem key={value} value={value}>
					{label}
				</MenuItem>,
			);
		}
	}

	const render = (selected: any) => {
		let labels = computeLabels(children);
		return (
			<div className={classes.chips}>
				{selected.map((value: any) => (
					<Chip key={value} label={labels[value]} className={classes.chip} />
				))}
			</div>
		);
	};

	props.inputRef?.({
		checkValidity: () => !props.required || props.value,
		validationMessage:
			props.required && !props.value ? 'Ce champ est obligatoire, veuillez sélectionner une valeur' : '',
		scrollIntoView: (params: any) => (ref.current as any)?.scrollIntoView?.(params),
	});

	return (
		<FormControl
			className={props.classes?.formControl}
			variant={props.variant ?? 'outlined'}
			fullWidth={props.fullWidth}
			error={!!props.error}
			style={{ width: props.width, flexGrow: props.flexGrow ?? 1, margin: props.margin, ...props.style }}
		>
			<InputLabel>{props.label}</InputLabel>
			<MUSelect
				ref={ref}
				label={props.label}
				value={props.value}
				onChange={
					props.onChange
						? (event: any) => {
								props.onChange?.(event.target.value);
						  }
						: undefined
				}
				fullWidth={props.fullWidth ?? true}
				type={props.type}
				error={!!props.error}
				multiple={props.multiple}
				disabled={props.disabled}
				renderValue={props.multiple ? render : undefined}
				MenuProps={MenuProps}
			>
				{children}
			</MUSelect>
			{props.error && <FormHelperText>{props.error}</FormHelperText>}
		</FormControl>
	);
};

function computeLabels(children: any[]) {
	if (!Array.isArray(children)) return {};
	let vl = React.Children.map(children, (e) => ({ [e?.props?.value]: elementText(e) }));
	return Object.assign({}, ...vl);
}

function elementText(e: any): string {
	if (typeof e === 'string' || typeof e === 'number') {
		return String(e);
	} else if (React.isValidElement(e) && (e?.props as any)?.children) {
		return React.Children.map((e.props as any).children, elementText).join('');
	} else {
		return '';
	}
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
}));

export default React.memo(Select);
