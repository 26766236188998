import { Box, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import routes from '../../api/routes';
import AssociationSelect from '../../components/associations/AssocationSelect';
import ReturnButton from '../../components/form/ReturnButton';
import StocksForm from '../../components/logistics/StocksForm';
import { AssociationStocks, StocksEditDto, TricoStocks } from '../../dto/stock';
import useFetch from '../../hooks/useFetch';

const CreateTablet = () => {
	const [associationId, setAssociationId] = useState<number>();
	const [isAssociationStockLoading, associationStocks] = useFetch<AssociationStocks>(
		routes.logistics.stocks.forAssociation({ associationId }),
		[associationId],
	);
	const [isTricoStockLoading, tricoStocks] = useFetch<TricoStocks>(routes.logistics.stocks.forTrico);

	const init: StocksEditDto = {
		association: { ...associationStocks, associationId },
		trico: tricoStocks,
	};

	return (
		<Box px={4} py={0} position="relative" display="flex" flexDirection="column" alignItems="center" mb={8}>
			<Box style={{ maxWidth: 1000, width: '100%', position: 'relative' }}>
				<Box display="flex" justifyContent="space-between" flexWrap="wrap">
					<ReturnButton to={`/logistique/stocks`} askConfirmation>
						Retour aux stocks
					</ReturnButton>
				</Box>

				<AssociationSelect
					value={associationId}
					onChange={(value) => setAssociationId(value)}
					fullWidth
					width="min(400px, 80vw)"
				/>

				{isAssociationStockLoading || isTricoStockLoading ? (
					<Box display="flex" justifyContent="center">
						<CircularProgress />
					</Box>
				) : (
					<StocksForm init={init} associationId={associationId} />
				)}
			</Box>
		</Box>
	);
};

export default CreateTablet;
