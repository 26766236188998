import React, { useState } from "react";
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import routes from "../../api/routes";
import TextInput from "../form/TextInput";
import useForm from "../../hooks/useForm";
import ErrorMessage from "../form/ErrorMessage";

type ResetPasswordFormProps = {
  style?: React.CSSProperties
};

const ForgotPasswordForm = ({ style }: ResetPasswordFormProps) => {
  let [showSuccessMsg, setShowSuccessMsg] = useState(false);
  let classes = useStyles({ border: false });
  let form = useForm({
    url: routes.auth.requestPasswordReset,
    init: { email: "" },
    onSuccess: (response) => {
      setShowSuccessMsg(true);
    }
  });

  return <form noValidate onSubmit={form.submit} style={style}>
    <ErrorMessage>{form.errors?.global || ' '}</ErrorMessage>
    {
      showSuccessMsg && (<Box className={classes.successMessage} py={2}>
        Nous avons bien pris on compte votre demande.
        Vous allez bientôt recevoir un email pour réinitialiser votre mot de passe.
      </Box>)
    }
    <Typography align="justify" color="primary">
      RÉINITIALISATION DU MOT DE PASSE
    </Typography>
    <Box py={2}>
      <TextInput {...form.field('email')} fullWidth label="Adresse email" type="email" variant="outlined" />
    </Box>
    <Button fullWidth disabled={!form.values.email || showSuccessMsg} type="submit" size="large" variant="contained" color="primary">
      Réinitialiser
    </Button>
  </form>;
};

const useStyles = makeStyles({
  successMessage: {
    color: "#448866"
  }
});

export default ForgotPasswordForm;