import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import UserProvider from './components/user/UserProvider';

import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(...registerables, ChartDataLabels);

ReactDOM.render(
	<UserProvider>
		<App />
	</UserProvider>,
	document.getElementById('root'),
);

/*
__COMMENT__
	ça pourrait être cool de mettre le favicon dans le index.html; si on se sert pas du service worker, vu qu'on va pas faire de PWA, je pense qu'on peut le virer limite, t'en penses quoi ?
__COMMENT__
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
