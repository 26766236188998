import { Box } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { CardContainer } from '../Cards';
import Dashboard1 from './MissionDashboard1';
import Dashboard2 from './MissionDashboard2';
import Title from '../Title';
import CommentDailyReport from './CommentDailyReport';
import AgeStats from './AgeStats';

const MissionDatas = ({ mission, user, userId, dayFilters, filterDate, previousDaysCount = 0 }) => {
	const show = (userId && user && mission) || (!userId && mission);

	console.log('MISSION DATA', mission);

	const firstDay = moment(dayFilters.periodStart);
	const days: moment.Moment[] = [firstDay, ...getPreviousDays(mission.rhythm, firstDay, previousDaysCount)];

	return (
		<Box display="flex" flexDirection="column">
			{show &&
				days.map((day, i) => {
					const filters = {
						...dayFilters,
						periodStart: moment(day).format('YYYY-MM-DD 00:00:00'),
						periodEnd: moment(day).format('YYYY-MM-DD 23:59:59'),
					};

					const isFirstDay = i === 0;

					return (
						<div key={`${day.format('YYYY-MM-DD')}-datas`} style={{ width: '100%' }}>
							<Title marginLeft={'16px'} marginTop={'16px'}>
								Données du {moment(day).format('DD/MM/YYYY')}
							</Title>
							<CardContainer display="flex" mt={1} mx={2}>
								<Dashboard1 filters={filters} hideDetails={!isFirstDay} />
								<Dashboard2 filters={filters} user={null} hideDetails={!isFirstDay} />
								<Box width="100%">
									<CommentDailyReport
										filterDate={moment(day).format('YYYY-MM-DD')}
										userId={userId}
										missionId={mission.id}
									/>
									{isFirstDay && (
										<Box display="flex" alignItems="center" justifyContent="center">
											<Box width="70vw" maxWidth={400} padding={1}>
												<AgeStats
													missionId={mission.id}
													recruiterId={userId}
													periodStart={moment(filterDate).format('YYYY-MM-DD 00:00:00')}
													periodEnd={moment(filterDate).format('YYYY-MM-DD 23:59:59')}
												/>
											</Box>
										</Box>
									)}
								</Box>
							</CardContainer>
						</div>
					);
				})}
		</Box>
	);
};

export default MissionDatas;

const getPreviousDays = (rhythm: 'lv' | 'ms', date: moment.Moment, previousDaysCount = 0) => {
	const previousDays: moment.Moment[] = [];
	let i = -1;
	while (previousDaysCount > previousDays.length) {
		const day = moment(date).add(i, 'day');
		// rythm == ms (mardi to samedi) => we skip sunday (0) and monday (1)
		// rythm == lv (lundi to vendredi) => we skip saturday(6) and sunday(0)
		if (
			(rhythm === 'ms' && day.day() !== 0 && day.day() !== 1) ||
			(rhythm === 'lv' && day.day() !== 0 && day.day() !== 6)
		) {
			previousDays.push(day);
		}
		i--;
		// security
		if (i < -1000) break;
	}
	return previousDays;
};
