import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import CreateMission from "./CreateMission";
import EditMission from "./EditMission";
import Missions from "./Missions";
import MissionDetails from "./MissionDetails";
import UserMissionDetails from "./UserMissionDetails";

const MissionsPages = ({ match }: { match: { path: string } }) => {
	const { path } = useRouteMatch();

	return <Switch>
		<Route exact path={`${path}`} component={Missions} />
		<Route exact path={`${path}/creation`} component={CreateMission} />
		<Route exact path={`${path}/modifier/:id`} component={EditMission} />
		<Route exact path={`${path}/:id`} component={MissionDetails} />
		<Route exact path={`${path}/:id/recruteur/:userId`} component={UserMissionDetails} />
	</Switch>;
}

export default MissionsPages;