import React from 'react';
import { Button, ButtonProps, CircularProgress, Box } from '@material-ui/core';
import { CheckCircle, Edit, Add } from '@material-ui/icons';

type SubmitButtonProps = ButtonProps & {
	edit?: boolean;
	submitting?: boolean;
	success?: boolean;
	icon?: boolean;
	children?: any;
};

const SubmitButton = ({ edit, submitting, success, children, icon = true, ...props }: SubmitButtonProps) => {
	return (
		<Button type="submit" color="primary" variant="contained" disabled={submitting || success} {...props}>
			{success ? (
				<CheckCircle color="primary" />
			) : submitting ? (
				<CircularProgress size={24} />
			) : (
				<>
					{icon && (edit ? <Edit /> : <Add />)}
					<Box ml={icon ? 1 : 0} display="flex" alignItems="center">
						{children}
					</Box>
				</>
			)}
		</Button>
	);
};

export default SubmitButton;
