import React, { useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import Title from '../Title';
import { Card } from '../Cards';
import NumberInput from '../form/NumberInput';
import useAuth from '../../hooks/useAuth';

const Previsions = () => {
	const hoursPerDayPerPerson = 6;
	const [hourlyRate, setHourlyRate] = useState(0);
	const [remaningDays, setRemainingDays] = useState(0);
	const [employees, setEmployees] = useState(0);

	const hoursPrevision = remaningDays * employees * hoursPerDayPerPerson || 0;
	const donationsPrevision = hoursPrevision * hourlyRate || 0;

	const { user } = useAuth();

	return user.client.id === 1 ? (
		<Paper variant="outlined">
			<Box p={2}>
				<Title>volume prévisionnel</Title>
				<Box display="flex" flexDirection="column" alignItems="center">
					<Box display="flex" flexWrap="wrap" width="100%" p={2}>
						<NumberInput
							width={150}
							flexGrow={1}
							value={hourlyRate}
							label="Entrez un taux"
							style={{ margin: 5 }}
							onChange={setHourlyRate}
							isFloat
						/>
						<NumberInput
							width={150}
							flexGrow={1}
							value={remaningDays}
							style={{ margin: 5 }}
							onChange={setRemainingDays}
							label="Entrez jours restant"
							isFloat
						/>
					</Box>
					<Box display="flex" flexWrap="wrap" width="100%" p={2}>
						<NumberInput
							width={150}
							flexGrow={1}
							value={employees}
							style={{ margin: 5 }}
							onChange={setEmployees}
							label="Entrez un effectif"
							isFloat
						/>
						<Box width={150} flexGrow={1}></Box>
					</Box>
					<Box display="flex" flexWrap="wrap" width="100%" p={2}>
						<Card border={false} title="Nombre d'heures">
							{hoursPrevision.toFixed(1)}
						</Card>
						<Card border={false} title="Nombre de bulletins">
							{donationsPrevision.toFixed(1)}
						</Card>
					</Box>
				</Box>
			</Box>
		</Paper>
	) : null;
};

export default Previsions;
