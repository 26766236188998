import {
	Box,
	Button,
	Checkbox as MUICheckbox,
	CircularProgress,
	FormControlLabel,
	InputLabel,
	Link,
	MenuItem,
} from '@material-ui/core';
import { Add, AddCircle, CheckCircle, Edit } from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import routes from '../../api/routes';
import ColorPicker from '../../components/form/ColorPicker';
import ErrorMessage from '../../components/form/ErrorMessage';
import { default as DefaultSelect, SelectProps } from '../../components/form/Select';
import Switch from '../../components/form/Switch';
import { default as DefaultTextInput, TextInputProps } from '../../components/form/TextInput';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import { AssociationDto, WeekDay } from '../../dto/association';
import { ContactDto } from '../../dto/contact';
import { FileDto } from '../../dto/file';
import useFetch from '../../hooks/useFetch';
import useForm, { Form } from '../../hooks/useForm';
import { CauseType } from '../../dto/cause';
import FileModalPicker from '../files/FilePickerModal';
import FilePreview from '../files/FilePreview';
import ReturnButton from '../form/ReturnButton';
import ContactForm from './ContactForm';

type AssociationFormProps = {
	url: string;
	init: AssociationDto;
	edit?: boolean;
	children?: any;
};

const AssociationForm = ({ edit, init, url, children }: AssociationFormProps) => {
	let contactFormsRefs = useRef<Form<ContactDto>[]>([]);
	const validation = () => {
		let areFormsValid = true;
		contactFormsRefs.current.forEach((cform) => {
			let [isValid] = cform.validate();
			areFormsValid = areFormsValid && isValid;
		});
		return areFormsValid;
	};

	let [causesAreLoading, causes] = useFetch<CauseType[]>(routes.associations.causes);
	let addressForm = useForm({ init: init.address });
	let ftpForm = useForm({ init: init.ftpSettings });
	let form = useForm({ url, init, validation });

	form.addSubform('address', addressForm);
	form.addSubform('ftpSettings', ftpForm);

	let [imagePickerIsOpen, setImagePickerIsOpen] = useState(false);
	let image: FileDto | null = form.field('image').value;
	image = image && image.id ? image : null;

	const toggleTransferDay = (day: WeekDay) => (include: boolean) => {
		let daysField = form.field('transferDays');
		let days = daysField.value.filter((d) => d !== day);
		if (include) days.push(day);
		daysField.onChange(days);
	};

	const daysOfWeek: WeekDay[] = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];
	const daysOfMonth: Record<string, string> = {};
	for (let i = 1; i < 29; i++) {
		daysOfMonth[String(i)] = (i === 1 ? '1er' : i + 'ème') + ' du mois';
	}

	return (
		<form noValidate onSubmit={form.submit} style={{ maxWidth: 1000, position: 'relative' }}>
			<Box display="flex" justifyContent="space-between" flexWrap="wrap">
				<ReturnButton to="/associations" askConfirmation>
					Retour aux associations
				</ReturnButton>
				{children}
			</Box>

			<Modal open={form.success} noCloseButton>
				<p>L'association a bien été {edit ? 'modifiée' : 'créée'} !</p>
				<RouterLink to="/associations">
					<Button variant="contained" color="primary">
						Retour aux associations
					</Button>
				</RouterLink>
			</Modal>

			<Title big>{edit ? 'Modification' : 'Création'} d'une association</Title>

			{form.errors?.global && <ErrorMessage>{form.errors?.global}</ErrorMessage>}
			<Title>Description de l'association</Title>
			<Box display="flex" flexWrap="wrap" mx={-0.5}>
				<TextInput {...form.field('name')} required label="Nom ONG" />
				<TextInput {...addressForm.field('address')} label="Adresse postale" />
				<TextInput {...addressForm.field('zipCode')} label="Code postal" />
				<TextInput {...addressForm.field('city')} label="Ville" />
				<Select
					{...form.field('causes')}
					value={form.field('causes').value[0]}
					onChange={(cause) => form.field('causes').onChange([cause])}
					label="Cause défendue"
				>
					{!causesAreLoading && causes.map(({ id, name }) => <MenuItem value={id}>{name}</MenuItem>)}
				</Select>
			</Box>
			<Box display="flex" flexWrap="wrap" mx={-0.5} my={0.5}>
				<TextInput {...form.field('badgeText')} label="Texte du badge" multiline rows={4} />
			</Box>
			<Box width={350} flexGrow={1} my={2} mx={0.5}>
				<Box my={2}>
					<InputLabel>Logo de l'ONG</InputLabel>
					<Link onClick={() => setImagePickerIsOpen(true)}>
						{image ? (
							<Box my={2}>
								<FilePreview file={form.field('image').value} style={{ cursor: 'pointer' }} />
							</Box>
						) : (
							<Box display="flex" alignItems="center" my={2} mt={1}>
								<AddCircle />
								<Box mx={1}>Télécharger un logo</Box>
							</Box>
						)}
					</Link>
					<FileModalPicker
						accept="image/*"
						category="logo"
						open={imagePickerIsOpen}
						onClose={() => setImagePickerIsOpen(false)}
						onChange={form.field('image').onChange}
					/>
				</Box>
				<Box my={3} mb={0}>
					<InputLabel>RGPD</InputLabel>
					<Switch {...form.field('rgpd')} name="rgpd" color="primary" />
				</Box>
				<Box display="flex">
					<Box my={2} flexGrow={1}>
						<InputLabel>Couleur de fond</InputLabel>
						<ColorPicker {...form.field('backgroundColor')} />
					</Box>

					<Box my={2} flexGrow={1}>
						<InputLabel>Couleur du texte</InputLabel>
						<ColorPicker {...form.field('textColor')} />
					</Box>
				</Box>
			</Box>

			<Title>Contacts de l'association</Title>
			{form.field('contacts').value.map((contact, index) => (
				<Box py={2} style={{ borderTop: index !== 0 ? '1px solid #AAA' : '' }}>
					<ContactForm
						key={contact.id + 'i' + index}
						values={contact}
						formRef={(form) => (contactFormsRefs.current[index] = form)}
						onChange={(contact) => {
							let contacts = [...form.field('contacts').value];
							contacts[index] = contact;
							form.field('contacts').onChange(contacts);
						}}
					/>
				</Box>
			))}
			<Link
				onClick={() => {
					form.field('contacts').onChange([...form.field('contacts').value, new ContactDto()]);
				}}
				style={{ cursor: 'pointer' }}
			>
				<Box display="inline-flex" alignItems="center" my={2} mt={1}>
					<AddCircle />
					<Box mx={1}>Ajouter un contact</Box>
				</Box>
			</Link>

			<Title>Communication mail</Title>
			<Box my={2} mb={0}>
				<InputLabel>Activer l'envoi des emails</InputLabel>
				<Switch {...form.field('sendEmails')} color="primary" />
			</Box>
			<Box display="flex" flexWrap="wrap" mx={-0.5}>
				<TextInput
					{...form.field('templateId')}
					disabled={!form.field('sendEmails').value}
					label="Template mailjet"
					width={350}
				/>
			</Box>

			<Box my={2}>
				<Title>Communication SMS</Title>
				<InputLabel>Activer l'envoi des SMS</InputLabel>
				<Switch {...form.field('sendSms')} color="primary" />

				<Box display="flex" flexWrap="wrap" mx={-0.5}>
					<TextInput
						{...form.field('smsSender')}
						disabled={!form.field('sendSms').value}
						label="Nom de l'expéditeur"
						width={350}
					/>
				</Box>
				<Box display="flex" flexWrap="wrap" mx={-0.5} my={0.5}>
					<TextInput
						{...form.field('smsContent')}
						disabled={!form.field('sendSms').value}
						label="Contenu du message"
						width={350}
						multiline
						rows={4}
					/>
				</Box>
			</Box>

			<Title>Formulaire Bulletins</Title>
			<Box display="flex" flexWrap="wrap" mx={-2}>
				<Box my={2} mb={0} mx={2}>
					<InputLabel>Contacter veille qualitative</InputLabel>
					<Switch {...form.field('commQualitative')} color="primary" />
				</Box>
				<Box my={2} mb={0} mx={2}>
					<InputLabel>Contacter par mail</InputLabel>
					<Switch {...form.field('commEmail')} color="primary" />
				</Box>
				<Box my={2} mb={0} mx={2}>
					<InputLabel>Contacter par sms</InputLabel>
					<Switch {...form.field('commSms')} color="primary" />
				</Box>
				<Box my={2} mb={0} mx={2}>
					<InputLabel>Contacter par courrier</InputLabel>
					<Switch {...form.field('commMail')} color="primary" />
				</Box>
				<Box my={2} mb={0} mx={2}>
					<InputLabel>Contacter par tel</InputLabel>
					<Switch {...form.field('commPhone')} color="primary" />
				</Box>
				<Box my={2} mb={0} mx={2}>
					<InputLabel>Propositions d'autres organismes</InputLabel>
					<Switch {...form.field('advertisement')} color="primary" />
				</Box>
				<Box my={2} mb={0} mx={2}>
					<InputLabel>Journal par courrier</InputLabel>
					<Switch {...form.field('receivesMagazine')} color="primary" />
				</Box>
				<Box my={2} mb={0} mx={2}>
					<InputLabel>Journal par mail</InputLabel>
					<Switch {...form.field('receivesNewsletters')} color="primary" />
				</Box>
				<Box my={2} mb={0} mx={2}>
					<InputLabel>Adhérer à l'association</InputLabel>
					<Switch {...form.field('subscribe')} color="primary" />
				</Box>
				<Box my={2} mb={0} mx={2}>
					<InputLabel>Reçu fiscal par mail</InputLabel>
					<Switch {...form.field('receivesReceiptByEmail')} color="primary" />
				</Box>
				<Box my={2} mb={0} mx={2}>
					<InputLabel>Reçu fiscal par courrier</InputLabel>
					<Switch {...form.field('receivesReceiptByLetter')} color="primary" />
				</Box>
			</Box>

			<Box display="flex" justifyContent="space-between" flexWrap="wrap">
				<Title>Dépôt des bulletins</Title>
				<Switch {...form.field('transferEnabled')} color="primary" />
			</Box>

			<Box style={{ transition: 'opacity 0.3s', opacity: form.values.transferEnabled ? 1 : 0.4 }}>
				<Box display="flex" flexWrap="wrap" mx={-0.5} my={0.5}>
					<TextInput
						{...form.field('rumFormat')}
						label="Format du RUM"
						width={350}
						helperText="Ex: CAE-{CODE_MISSION}-{ANNEE}{MOIS}{JOUR}-{NUM=10}"
					/>
					<Select {...form.field('transferType')} label="Type de transfert">
						<MenuItem value="API">Transfert par FTP</MenuItem>
						<MenuItem value="FTP">Transfert par API</MenuItem>
					</Select>
				</Box>
				<Box>
					<InputLabel>Jours de transmission</InputLabel>
					<Box display="flex" flexWrap="wrap" mb={2} mx={0.5}>
						{daysOfWeek.map((day) => (
							<Checkbox
								key={day}
								value={form.field('transferDays').value.includes(day)}
								onChange={toggleTransferDay(day)}
								label={day.charAt(0).toUpperCase() + day.substring(1)}
							/>
						))}
					</Box>
				</Box>
				<Box display="flex" flexWrap="wrap" my={1} mx={-0.5}>
					<Select {...form.field('transferTime')} label="Horaire de transmission">
						<MenuItem value="07:00:00">À 7h du matin</MenuItem>
						<MenuItem value="23:00:00">À 23h le soir</MenuItem>
					</Select>
				</Box>
			</Box>

			<Title>Paramètres FTP</Title>
			<Box display="flex" flexWrap="wrap" mx={-0.5} mb={2}>
				<TextInput {...ftpForm.field('host')} label="Hôté" />
				<TextInput {...ftpForm.field('username')} label="Identifiant" />
				<TextInput {...ftpForm.field('donationsFilesFolder')} label="Dossier distant / Fichier dons" />
				<TextInput {...ftpForm.field('port')} label="Port" />
				<TextInput {...ftpForm.field('password')} label="Mot de passe" />
				<TextInput {...ftpForm.field('pledgesFolder')} label="Dossier distant / Bulletins" />
			</Box>

			<Title>Jours de prélèvements</Title>
			<Box display="flex" flexWrap="wrap" my={1} mx={-0.5}>
				<Select {...form.field('debitDates')} multiple label="Date de prélèvement">
					{Object.entries(daysOfMonth).map(([value, label]) => (
						<MenuItem key={value} value={value}>
							{label}
						</MenuItem>
					))}
				</Select>
			</Box>

			<Title>Permission d'envoi des bulletins</Title>
			<InputLabel>
				Si cette option est activé, par défaut les bulletins de cette association seront envoyés sans
				validation manuelle
			</InputLabel>
			<InputLabel>
				Si elle n'est pas activé, il faudra manuellement cocher tout les bulletins dont on veut autoriser
				l'envoi
			</InputLabel>
			<Switch {...form.field('allowDonationsExportByDefault')} color="primary" />

			<Box my={2} display="flex" justifyContent="flex-end">
				<Button
					type="submit"
					color="primary"
					variant="contained"
					disabled={form.isSubmitting || form.success}
				>
					{form.success ? (
						<CheckCircle color="primary" />
					) : form.isSubmitting ? (
						<CircularProgress size={20} />
					) : (
						<>
							{edit ? <Edit /> : <Add />} {edit ? 'Modifier' : 'Créer'} l'association
						</>
					)}
				</Button>
			</Box>
		</form>
	);
};

type CheckboxProps = {
	name?: string;
	width?: number;
	value?: boolean;
	label?: string;
	onChange?: (value: boolean) => any;
};

const Checkbox = ({ name, value, onChange, label, width = 240 }: CheckboxProps) => {
	return (
		<Box style={{ width }}>
			<FormControlLabel
				name={name}
				label={label}
				control={<MUICheckbox checked={value} color="primary" onChange={(e, c) => onChange?.(c)} />}
			/>
		</Box>
	);
};

const TextInput = (props: TextInputProps) => {
	return <DefaultTextInput {...(props as any)} width={350} flexGrow={1} margin={8} variant="outlined" />;
};

const Select = function <T>(props: SelectProps<T>) {
	return <DefaultSelect {...(props as any)} width={350} flexGrow={1} margin={8} variant="outlined" />;
};

export default AssociationForm;
