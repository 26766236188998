import React, { useState } from 'react';
import { Box, Paper } from "@material-ui/core";
import Title from "../Title";
import AssociationSelect from '../associations/AssocationSelect';
import routes from '../../api/routes';
import PrimaryButton from '../PrimaryButton';
import SendIcon from '@material-ui/icons/Send';
import TextInput from '../form/TextInput';
import useForm from '../../hooks/useForm';
import { MessageDto } from "../../dto/message";
import MissionSelect from './MissionSelect';
import SubmitButton from '../form/SubmitButton';
import ErrorMessage from '../form/ErrorMessage';
import UserSelect, { UserSuggestion } from '../user/UserSelect';

const MessageForm = ({ onClose, missionId }: { onClose: () => any, missionId?: string }) => {
	const form = useForm({
		init: new MessageDto(missionId),
		url: routes.missions.sendMessage,
	});
	const [recipient, setRecipient] = useState<UserSuggestion>(null);
	const [associationId, setAssociationId] = useState(null);

	return <form noValidate onSubmit={e => form.submit(e, { recipientId: recipient?.id })} style={{ width: "min(450px, 80vw)" }}>
		<Title mb={2}>Envoyer un message</Title>
		{form.errors?.global && <ErrorMessage>{form.errors?.global}</ErrorMessage>}
		{form.success ? <Box>
			<Box mb={2}>
				<Title small color="#44BB44">Le message a bien été envoyé !</Title>
				<Paper variant="outlined">
					<Box p={2} whiteSpace="pre">
						{form.values.message}
					</Box>
				</Paper>
			</Box>
			<Box mt={1} display="flex" justifyContent="end">
				<PrimaryButton onClick={onClose}>Fermer</PrimaryButton>
			</Box>
		</Box> :
			<Box>
				{!missionId && <>
					<Box mb={2}>
						<AssociationSelect fullWidth label="Selectionner une association" value={associationId} onChange={setAssociationId} />
					</Box>
					<Box mb={2}>
						<MissionSelect fullWidth associationId={associationId} {...form.field('missionId')} />
					</Box>
				</>}
				<Box mb={2}>
					<UserSelect value={recipient} onChange={setRecipient} />
				</Box>
				<Box mb={2}>
					<TextInput required fullWidth label="Message" multiline rows={5} {...form.field('message')} />
				</Box>
				<Box mt={1} display="flex" justifyContent="end">
					<SubmitButton icon={false} submitting={form.isSubmitting} success={form.success}>
						<Box mr={1.5} mt={1}> <SendIcon /> </Box>
						Envoyer
					</SubmitButton>
				</Box>
			</Box>}
	</form>
}

export default MessageForm;