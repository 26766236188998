import { AddressDto } from './address';
import { ContactDto } from './contact';
import { FtpSettingsDto } from './ftpSettings';
import { FileDto } from './file';
import { ProgramType, ProgramDto } from './program';
import { CauseType } from './cause';

export enum TransferType {
	API = 'API',
	FTP = 'FTP',
}

export const WeekDays = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'] as const;
export type WeekDay = typeof WeekDays[number];

export class AssociationDto {
	id: number;
	name: string = '';
	address: AddressDto;
	contacts: ContactDto[];
	ftpSettings: FtpSettingsDto;
	image: FileDto;
	causes: CauseType[] = [];
	rgpd: boolean = false;
	backgroundColor: string = '';
	textColor: string = '';
	sendEmails: boolean = false;
	templateId: string = '';
	sendSms: boolean = false;
	smsSender: string = '';
	smsContent: string = '';
	rumFormat: string = '';
	transferType: TransferType;
	transferDays: WeekDay[] = [];
	transferTime: string = '';
	transferEnabled: boolean = true;
	debitDates: string[] = [];
	allowDonationsExportByDefault = false;
	serviceName: string;
	programs?: ProgramDto[];
	badgeText?: string = '';

	constructor() {
		this.address = new AddressDto();
		this.contacts = [new ContactDto()];
		this.ftpSettings = new FtpSettingsDto();
		this.image = new FileDto();
	}

	commQualitative: boolean;
	commEmail: boolean;
	commSms: boolean;
	commMail: boolean;
	commPhone: boolean;
	advertisement: boolean;
	receivesMagazine: boolean;
	receivesNewsletters: boolean;
	subscribe: boolean;
	receivesReceiptByEmail: boolean;
	receivesReceiptByLetter: boolean;
}

export class AssociationFiltersDto {
	associationId: number;
	periodStart: string = '';
	periodEnd: string = '';
	missionId: string = '';
	missionType: string = '';
	minimumDonation: number;
	recruiterId: string = '';
	runningMissionsOnly: boolean = false;
	programTypes: ProgramType[] = ['rue', 'CC', 'mixte', 'PAP'];
	clientId: number | null = null;
}
