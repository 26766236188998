import { Box, Button, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { post } from '../../api';
import routes from '../../api/routes';
import { StocksEditDto, StockSizes } from '../../dto/stock';
import ErrorMessage from '../form/ErrorMessage';
import NumberInput from '../form/NumberInput';
import SubmitButton from '../form/SubmitButton';
import Modal from '../Modal';
import Title from '../Title';

type StocksFormProps = {
	init: StocksEditDto;
	associationId: number;
};
const StocksForm = ({ init, associationId }: StocksFormProps) => {
	const [form, setForm] = useState({ errors: null, isSubmitting: false, success: false });
	const [pauloStocks, setPauloStocks] = useState(init.association.paulos);
	const [kwayStocks, setkwayStocks] = useState(init.association.kWays);
	const [quechuaStocks, setQuechuaStocks] = useState(init.trico.quechuas);
	const [umbrellaStocks, setUmbrellaStocks] = useState(init.trico.umbrellas);

	const submit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const values: StocksEditDto = {
			association: { associationId, paulos: pauloStocks, kWays: kwayStocks },
			trico: { quechuas: quechuaStocks, umbrellas: umbrellaStocks },
		};

		post(routes.logistics.stocks.edit, values).then((response) => {
			if (response.errors) setForm({ ...form, errors: response.errors });
			else setForm({ errors: null, isSubmitting: false, success: true });
		});

		setForm({ ...form, isSubmitting: true });
	};

	const disabled = !associationId;

	return (
		<form noValidate onSubmit={submit} style={{ maxWidth: 1000, width: '100%', position: 'relative' }}>
			{form.errors && <ErrorMessage>{form.errors?.global}</ErrorMessage>}

			<Title my={1} mt={2}>
				Stocks disponible pour l'association
			</Title>
			<Paper variant="outlined">
				<Box p={2}>
					<Title small>polo</Title>
					<Box display="flex" flexWrap="wrap">
						{StockSizes.map((size) => (
							<Box key={size} display="flex" alignItems="center" mt={1} mr={2}>
								<NumberInput
									value={pauloStocks[size]}
									onChange={(value) => setPauloStocks({ ...pauloStocks, [size]: value })}
									size="small"
									width={60}
									disabled={disabled}
								/>
								<Box m={1}>{size}</Box>
							</Box>
						))}
					</Box>

					<Title small mt={2}>
						kway
					</Title>
					<Box display="flex" flexWrap="wrap">
						{StockSizes.map((size) => (
							<Box key={size} display="flex" alignItems="center" mt={1} mr={2}>
								<NumberInput
									value={kwayStocks[size]}
									onChange={(value) => setkwayStocks({ ...kwayStocks, [size]: value })}
									size="small"
									width={60}
									disabled={disabled}
								/>
								<Box m={1}>{size}</Box>
							</Box>
						))}
					</Box>
				</Box>
			</Paper>

			<Title my={1} mt={2}>
				Stock trico
			</Title>
			<Paper variant="outlined">
				<Box p={2}>
					<Title small mt={2}>
						Quechua
					</Title>
					<Box display="flex" flexWrap="wrap">
						{StockSizes.map((size) => (
							<Box key={size} display="flex" alignItems="center" mt={1} mr={2}>
								<NumberInput
									value={quechuaStocks[size]}
									onChange={(value) => setQuechuaStocks({ ...quechuaStocks, [size]: value })}
									size="small"
									width={60}
								/>
								<Box m={1}>{size}</Box>
							</Box>
						))}
					</Box>

					<Title small mt={2}>
						Parapluies
					</Title>
					<Box display="flex" flexWrap="wrap">
						<NumberInput value={umbrellaStocks} onChange={setUmbrellaStocks} size="small" width={60} />
					</Box>
				</Box>
			</Paper>

			<Box display="flex" justifyContent="end" my={1}>
				<SubmitButton submitting={form.isSubmitting} success={form.success}>
					Enregistrer
				</SubmitButton>
			</Box>

			<Modal open={form.success} noCloseButton>
				<p>Les ont bien été mis à jours !</p>
				<RouterLink to={`/logistique/stocks`}>
					<Button variant="contained" color="primary">
						Retour aux stocks
					</Button>
				</RouterLink>
			</Modal>
		</form>
	);
};

export default StocksForm;
