import React, { useState } from 'react';
import { Button, Box } from '@material-ui/core';
import Modal from '../Modal';
import PrimaryButton from '../PrimaryButton';

type Props = {
	children?: any;
	confirmationMessage?: string;
	onClick: () => void;
};
const ConfirmationButton = ({ children, confirmationMessage, onClick }: Props) => {
	const [showModal, setShowModal] = useState(false);
	return (
		<>
			<Button onClick={() => setShowModal(true)} size="small" variant="contained" color="primary">
				{children}
			</Button>
			<Modal open={showModal} onClose={() => setShowModal(false)}>
				<p>{confirmationMessage || 'Êtes-vous sûr ?'}</p>
				<Box display="flex" justifyContent="space-between">
					<Button onClick={() => setShowModal(false)}>Annuler</Button>
					<PrimaryButton
						onClick={() => {
							onClick();
							setShowModal(false);
						}}
					>
						Confirmer
					</PrimaryButton>
				</Box>
			</Modal>
		</>
	);
};

export default ConfirmationButton;
