import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import routes from '../../api/routes';
import ProgramForm from '../../components/associations/ProgramForm';
import { AssociationDto } from '../../dto/association';
import { ProgramDto } from '../../dto/program';
import useFetch from '../../hooks/useFetch';

const CreateProgram = () => {
	const { id } = useParams<{ id: string }>();
	const [isAssociationLoading, association] = useFetch<AssociationDto>(routes.associations.get({ id: +id }));

	const url = routes.programs.create;
	return (
		<Box px={4} py={0} position="relative" display="flex" flexDirection="column" alignItems="center" mb={8}>
			<ProgramForm
				init={new ProgramDto(+id)}
				url={url}
				associationId={+id}
				icon={
					<Box display="flex" justifyContent="center" alignItems="center" py={4}>
						{isAssociationLoading ? (
							<CircularProgress />
						) : (
							<img src={`${routes.root}/${association?.image?.path}`} alt={association.name} />
						)}
					</Box>
				}
			/>
		</Box>
	);
};

export default CreateProgram;
