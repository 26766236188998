import React from 'react';
import routes from '../../api/routes';
import { AssociationFiltersDto } from '../../dto/association';
import { AssociationStats, calculateStats } from '../../dto/associationUtils';
import useFetch from '../../hooks/useFetch';
import { UserDto } from '../../dto/user';
import moment from 'moment';
import { formatNumber } from '../../utils';
import { Card } from '../Cards';

const Dashboard2 = ({
	filters,
	user,
	hideDetails,
}: {
	filters: AssociationFiltersDto;
	user: UserDto;
	hideDetails?: boolean;
}) => {
	const [, dayPartialDashboardStats] = useFetch<AssociationStats>(
		routes.associations.dashboardStats({}, filters),
		[JSON.stringify(filters)],
	);

	let dashboardStats = calculateStats((dayPartialDashboardStats || {}) as any);

	return (
		<>
			{!hideDetails && user && (
				<Card title="Date de fin de contrat">
					{user.contractEndDate && moment(user.contractEndDate).format('DD/MM/YYYY')}
				</Card>
			)}
			{!hideDetails && (
				<Card title="h rue/h payées">{formatNumber(dashboardStats.streetPaidHoursRatio, 2)} %</Card>
			)}
			<Card title="absences" color={dashboardStats.unjustifiedAbsence ? '#FF0000' : undefined}>
				{dashboardStats.absence}
			</Card>
			{!hideDetails && (
				<>
					<Card title="heures payées">{formatNumber(dashboardStats.paidHours, 2)}h</Card>
					<Card title="retards">{dashboardStats.delay}</Card>
					<Card title="swile">{formatNumber(dashboardStats.usedTickets, 2)}</Card>
					<Card title="TR papier">{formatNumber(dashboardStats.usedPaperTickets, 2)}</Card>
					<Card title="Per diem">{formatNumber(dashboardStats.perDiem, 2)}</Card>
					<Card title="conducteur">{dashboardStats.routes}</Card>{' '}
					<Card title="temps de trajet">{dashboardStats.routesDuration}</Card>
				</>
			)}

			{filters.recruiterId &&
				filters.periodStart !== '' &&
				moment(filters.periodStart).format('YYYY-MM-DD') ===
					moment(filters.periodEnd).format('YYYY-MM-DD') && (
					<Card title="Exposé">
						{dashboardStats.exposeType
							? `${dashboardStats.exposeType} : ${dashboardStats.exposeName}`
							: "Pas d'exposé"}
					</Card>
				)}
		</>
	);
};

export default Dashboard2;
