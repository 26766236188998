import React from "react";
import { Button, ButtonProps } from "@material-ui/core";


const PrimaryButton = ({ style, mini, round, marginLeft, ...props }: ButtonProps & { round?: boolean; mini?: boolean; marginLeft?: string; }) => {
	return <Button
		style={{
			...(mini ? { padding: "6px 6px", minWidth: 0, maxWidth: 100 } : {}),
			...(round ? { borderRadius: 100 } : {}),
			...(marginLeft ? { marginLeft: marginLeft } : {}),
			...style
		}}
		{...props}
		variant={props.variant ?? "contained"}
		color={props.color ?? "primary"}
	/>
}

export default PrimaryButton;