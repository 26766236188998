import {
	Box,
	Chip,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Error } from '@mui/icons-material';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../../api/routes';
import { MissionWithAssociationDto } from '../../dto/mission';
import useFetch from '../../hooks/useFetch';
import { roundDecimal } from '../../dto/associationUtils';

type MissionsTableProps = {
	userId?: string;
	periodStart?: string;
	periodEnd?: string;
	associationId?: number;
	clearPeriod?: () => any;
	missionType?: string;
};

type CleanedMission = {
	[K in keyof MissionWithAssociationDto]: number;
};

function clean(o: MissionWithAssociationDto): CleanedMission {
	let cleaned: any = {};

	for (let [k, v] of Object.entries(o)) {
		if (v === 'Infinity') cleaned[k] = Infinity;
		else if (isNaN(Number(v))) cleaned[k] = 0;
		else if (!isNaN(Number(v)) && typeof v === 'string') cleaned[k] = +v;
		else cleaned[k] = v;
	}

	return cleaned as CleanedMission;
}

export const calculateMissionStats = (missions: MissionWithAssociationDto[]) => {
	return missions.map((mission) => {
		const cleanedMission = clean(mission);
		const stopPA =
			cleanedMission.completedDonationsCount === 0
				? 0
				: (100 * cleanedMission.donationsTotalStopPA) /
				  (cleanedMission.completedDonationsCount + cleanedMission.donationsTotalStopPA);

		let realRate = 0;
		if (cleanedMission.streetHours !== 0) {
			realRate = cleanedMission.completedDonationsCount / cleanedMission.streetHours;
		}

		return {
			realRate: realRate.toFixed(2),
			averageAge: Math.round(cleanedMission.averageAge),
			streetHours: roundDecimal(cleanedMission.streetHours, 2),
			averageDonation: roundDecimal(cleanedMission.averageDonation, 2),
			completedDonationsCount: cleanedMission.completedDonationsCount || 0,
			stopPA: stopPA.toFixed(2),
		};
	});
};

const MissionsTable = ({
	periodStart,
	periodEnd,
	associationId,
	clearPeriod,
	userId,
	missionType,
}: MissionsTableProps) => {
	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));
	const [missionStatus, setMissionStatus] = useState('current');
	const status = periodStart || periodEnd ? 'custom' : missionStatus;
	const [, counts] = useFetch<{ current: number; future: number; past: number }>(
		routes.missions.counts({}, { associationId, userId }),
		[associationId, userId],
	);

	let periodStartString = periodStart ? moment(periodStart).format('YYYY-MM-DD') : '';
	let periodEndString = periodEnd ? moment(periodEnd).format('YYYY-MM-DD') : '';

	let [isLoading, missions] = useFetch<MissionWithAssociationDto[]>(
		routes.missions.allWithStats(
			{},
			{
				userId,
				associationId,
				status,
				periodStart: periodStartString,
				periodEnd: periodEndString,
			},
		),
		[associationId, status, periodEndString, periodEndString, userId],
	);

	if (missionType && missionType !== 'Tout sélectionner') {
		missions = missions?.filter((mission) => mission.missionType === missionType);
	}

	const classes = useStyles({ border: false });
	const history = useHistory();

	const tabs = [
		{ status: 'current', label: 'En cours' },
		{ status: 'future', label: 'À venir' },
		{ status: 'past', label: 'Terminées' },
		{ status: 'custom', label: 'Période personnalisée' },
	];

	const stats = calculateMissionStats(missions ?? []);

	return (
		<>
			<Box mt={2} className={classes.tabs}>
				<ToggleButtonGroup
					style={{ display: 'flex', flexWrap: 'wrap' }}
					exclusive
					value={status}
					onChange={(e, v) => {
						if (v !== 'custom') {
							setMissionStatus(v);
							clearPeriod?.();
						}
					}}
				>
					{tabs.map(({ status, label }) => (
						<ToggleButton key={status} value={status}>
							<Box mr={1}>{label}</Box>
							{status !== 'custom' && (
								<Chip size="small" color="primary" label={(counts as any)?.[status as any] ?? '?'} />
							)}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
			</Box>
			<Box
				my={2}
				style={{
					overflow: 'auto',
					maxWidth: isBigScreen ? 'calc(100vw - 356px)' : 'calc(100vw - 20px)',
					border: '1px solid #CCC',
					borderRadius: '3px',
					borderTopLeftRadius: 0,
					marginTop: 0,
				}}
			>
				{isLoading ? (
					<Box display="flex" justifyContent="center" my={8}>
						<CircularProgress />
					</Box>
				) : (
					<Table size="medium" classes={{ root: classes.tableRoot }}>
						<TableHead>
							<TableRow>
								<TableCell>N°Trico</TableCell>
								<TableCell>Début</TableCell>
								<TableCell>Fin</TableCell>
								<TableCell>Semaine</TableCell>
								<TableCell>Rythme</TableCell>
								<TableCell>LIEU</TableCell>
								<TableCell style={{ color: '#3f51b5' }}>HEURES RUE</TableCell>
								<TableCell style={{ color: '#61BE7E' }}>BS RÉEL</TableCell>
								<TableCell style={{ color: '#9ACD33' }}>TAUX RÉEL</TableCell>
								<TableCell style={{ color: '#ba5ae2' }}>DON MOYEN</TableCell>
								<TableCell style={{ color: '#EE814F' }}>AGE MOYEN</TableCell>
								<TableCell style={{ color: '#C05757' }}>STOP PA</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{missions?.map((row, i) => (
								<TableRow
									key={row.id}
									style={{ cursor: 'pointer' }}
									onClick={() =>
										window.location.href.includes('ressources-humaines')
											? history.push(`/missions/${row.id}/recruteur/${userId}`)
											: history.push(`/missions/${row.id}`)
									}
								>
									<TableCell align="left">
										{row.ongCode === '' ? (
											<>
												<Error fontSize="small" style={{ position: 'absolute' }} />
												<div style={{ paddingLeft: 22 }}>{row.tricoCode}‎</div>
											</>
										) : (
											row.tricoCode
										)}
									</TableCell>
									<TableCell align="left">{moment(row.startDate).format('DD/MM/YYYY')}</TableCell>
									<TableCell align="left">{moment(row.endDate).format('DD/MM/YYYY')}</TableCell>
									<TableCell align="left">
										{moment().diff(moment(row.startDate), 'week') +
											(missionStatus === 'future' ? -1 : 1)}
									</TableCell>
									<TableCell align="left">{row.rhythm.toUpperCase()}</TableCell>
									<TableCell align="left">{row.mainCity}</TableCell>
									<TableCell align="left" style={{ color: '#3f51b5' }}>
										{stats[i].streetHours}
									</TableCell>
									<TableCell align="left" style={{ color: '#61BE7E' }}>
										{stats[i].completedDonationsCount}
									</TableCell>
									<TableCell align="left" style={{ color: '#9ACD33' }}>
										{stats[i].realRate}
									</TableCell>
									<TableCell align="left" style={{ color: '#ba5ae2' }}>
										{stats[i].averageDonation}
									</TableCell>
									<TableCell align="left" style={{ color: '#EE814F' }}>
										{stats[i].averageAge}
									</TableCell>
									<TableCell align="left" style={{ color: '#C05757' }}>
										{stats[i].stopPA}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				)}
			</Box>
		</>
	);
};

export const useStyles = makeStyles(({ isBigScreen }: { isBigScreen?: boolean }) => ({
	tabs: {
		marginBottom: -1,
		'& button': {
			borderColor: '#CCC',
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
			fontSize: 12,
			padding: '10px 15px',
		},
	},
	tableRoot: {
		whiteSpace: 'nowrap',
		'& thead th': {
			textTransform: 'uppercase',
			color: '#444',
			fontSize: 11.5,
		},
		'& tbody td': {},
		'& tbody tr:hover': {
			backgroundColor: '#FAFAFA',
			boxShadow: 'inset 3px 0px 0px 0px #0093EE',
		},
	},
}));

export default MissionsTable;
